import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/lab/Alert';

import moment from 'moment';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AuthContext from 'src/components/AuthContext';

import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import GoogleDriveUpload from '../../../components/googleComponents/GoogleDriveUpload';
import GoogleDriveDeleteIcon, {
  GoogleDeleteFile
} from '../../../components/googleComponents/GoogleDriveDeleteIcon';

// Queries
const addJobContainerSubDocs = gql`
  mutation addJobContainerSubDocs(
    $ref: ID!
    $amount: Float!
    $attachment: String!
    $description: String!
    $invoice_by_reference: String!
    $invoice_by_name: String!
    $invoice_number: String!
  ) {
    addJobContainerSubDocs(
      ref: $ref
      subdoc: "invoice"
      data: {
        containerInvoiceInput: {
          amount: $amount
          attachment: $attachment
          description: $description
          invoice_by_reference: $invoice_by_reference
          invoice_by_name: $invoice_by_name
          invoice_number: $invoice_number
        }
      }
    ) {
      _id
    }
  }
`;

const updateContainerInvoice = gql`
  mutation updateContainerInvoice(
    $ref: ID!
    $amount: Float!
    $attachment: String
    $description: String
    $invoice_by_reference: String
    $invoice_by_name: String
    $invoice_number: String
  ) {
    updateContainerInvoice(
      ref: $ref
      data: {
        amount: $amount
        attachment: $attachment
        description: $description
        invoice_by_reference: $invoice_by_reference
        invoice_by_name: $invoice_by_name
        invoice_number: $invoice_number
      }
    ) {
      invoices {
        _id
      }
    }
  }
`;

const removeContainerInvoice = gql`
  mutation removeContainerInvoice($ref: ID!) {
    removeContainerInvoice(ref: $ref) {
      invoices {
        _id
      }
    }
  }
`;

const Invoices = gql`
  query JobsContainers($id: ID) {
    JobsContainers(_id: $id) {
      invoices {
        _id
        invoice_number
        amount
        description
        invoice_by_reference
        invoice_by_name
        created_date
        attachment
      }
    }
  }
`;

// Styles
const useStyles = makeStyles()(theme => {
  return {
    deleteUpload: {
      float: 'left'
    },
    uploadFile: {
      float: 'left',
      lineHeight: '2.2em',
      marginLeft: '10px'
    },
    uploadedInvoice: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'auto',
      maxWidth: '15vw',
      display: 'inherit',
      textDecoration: 'underline'
    }
  };
});

// Component
const JobInvoices = ({ className, job, ...rest }) => {
  const { classes } = useStyles();
  const { userData } = useContext(AuthContext);

  const [file, setFile] = useState();
  const [fileMissing, setFileMissing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [amountError, setAmountError] = useState('');
  const [editInvoice, setEditInvoice] = useState(false);
  const [delInvoice, setDelInvoice] = useState(false);

  const [opendialog, setOpendialog] = useState(false);
  const [openDel, setOpenDel] = useState(false);

  const [InvoicesList, setInvoicesList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.JobInvoices === false ? false : true
  );

  // Currency formatter for consistent display
  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  });

  const defaultFormState = {
    invoice_number: '',
    amount: '',
    description: '',
    invoice_by_name: '',
    attachment: ''
  };

  const [formState, setFormState] = useState(defaultFormState);

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const InvoicesResults = useQuery(Invoices, {
    variables: { id: job._id }
  });

  useEffect(() => {
    if (InvoicesResults.data && InvoicesResults.data.JobsContainers) {
      const invoiceList = InvoicesResults.data.JobsContainers[0].invoices;
      setInvoicesList(invoiceList);

      // Calculate total amount
      if (invoiceList && invoiceList.length > 0) {
        const total = invoiceList.reduce(
          (sum, invoice) => sum + parseFloat(invoice.amount || 0),
          0
        );
        setTotalAmount(total);
      } else {
        setTotalAmount(0);
      }
    }
  }, [InvoicesResults]);

  const handleClickOpendialog = invoice => {
    setAmountError('');
    if (invoice._id) {
      setEditInvoice(true);
      setFormState({
        invoice_number: invoice.invoice_number,
        amount: invoice.amount,
        description: invoice.description,
        _id: invoice._id
      });
      try {
        setFile(JSON.parse(invoice.attachment));
      } catch (error) {
        console.error('Error parsing attachment:', error);
        setFile(null);
      }
    } else {
      setFormState(defaultFormState);
      setEditInvoice(false);
      setFile(null);
    }
    setOpendialog(true);
  };

  const handleClosedialog = () => {
    setOpendialog(false);
    setFileMissing(false);
    setErrorMsg('');
    setAmountError('');
  };

  const handleClickOpenDel = id => {
    setDelInvoice(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setDelInvoice(false);
    setOpenDel(false);
  };

  const handleYesDel = () => {
    if (delInvoice.attachment) {
      try {
        const fileID = JSON.parse(delInvoice.attachment).id;
        GoogleDeleteFile(fileID);
      } catch (error) {
        console.error('Error parsing attachment for deletion:', error);
      }
    }
    delete_JobContainerInvoice({
      variables: {
        ref: delInvoice._id
      }
    });
    setOpenDel(false);
  };

  const [delete_JobContainerInvoice] = useMutation(removeContainerInvoice, {
    onCompleted() {
      handleCloseDel();
      InvoicesResults.refetch();
      setFileMissing(false);
      setErrorMsg('');
    },
    onError: err => {
      const gqlerr = String(err).replace('Error: GraphQL error: ', '');
      console.log(gqlerr);
      setFileMissing(true);
      setErrorMsg(gqlerr);
    }
  });

  const uploadCallbackFunction = file => {
    const fileOb = {
      id: file.id,
      name: file.name,
      link: file.webViewLink
    };
    setFile(fileOb);
    setFileMissing(false);
    setErrorMsg('');

    setFormState({ ...formState, attachment: JSON.stringify(fileOb) });
  };

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;

    // Validate amount if it's being changed
    if (nam === 'amount') {
      const numVal = parseFloat(val);
      // Clear previous error
      setAmountError('');

      if (!Number.isNaN(numVal)) {
        // Calculate what the new total would be
        let newTotal = totalAmount;

        // If editing an existing invoice, subtract its current amount first
        if (editInvoice) {
          const currentInvoice = InvoicesList.find(
            inv => inv._id === formState._id
          );
          if (currentInvoice) {
            newTotal -= parseFloat(currentInvoice.amount || 0);
          }
        }

        // Add the new amount
        newTotal += numVal;

        // Check if total invoices would exceed budget
        if (newTotal > job.total_budget) {
          setAmountError(
            `Total invoice amount (${currencyFormatter.format(
              newTotal
            )}) would exceed total budget (${currencyFormatter.format(
              job.total_budget
            )})`
          );
        }
      }
    }

    setFormState({ ...formState, [nam]: val });
  };

  const updateInvoice = e => {
    e.preventDefault();

    // Don't proceed if there's an amount error
    if (amountError) {
      return;
    }

    if (formState.description && formState.amount) {
      // Double check budget constraint before update
      const newAmount = parseFloat(formState.amount);
      const currentInvoice = InvoicesList.find(
        inv => inv._id === formState._id
      );
      const currentAmount = currentInvoice
        ? parseFloat(currentInvoice.amount || 0)
        : 0;
      const otherInvoicesTotal = totalAmount - currentAmount;

      if (otherInvoicesTotal + newAmount > job.total_budget) {
        setAmountError(
          `Total invoice amount would exceed total budget (${currencyFormatter.format(
            job.total_budget
          )})`
        );
        return;
      }

      update_JobContainerInvoice({
        variables: {
          ref: formState._id,
          amount: newAmount,
          attachment: formState.attachment,
          description: formState.description,
          invoice_by_name: userData.first_name + ' ' + userData.last_name,
          invoice_number: formState.invoice_number
        }
      });
    } else {
      setFileMissing(true);
      setErrorMsg('Please fill all required fields');
    }
  };

  const [update_JobContainerInvoice] = useMutation(updateContainerInvoice, {
    onCompleted() {
      InvoicesResults.refetch();
      setOpendialog(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setFileMissing(true);
      setErrorMsg(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const submitInvoice = e => {
    e.preventDefault();

    // Don't proceed if there's an amount error
    if (amountError) {
      return;
    }

    if (formState.attachment && formState.description && formState.amount) {
      // Double check budget constraint before submission
      const newAmount = parseFloat(formState.amount);
      if (totalAmount + newAmount > job.total_budget) {
        setAmountError(
          `Total invoice amount would exceed total budget (${currencyFormatter.format(
            job.total_budget
          )})`
        );
        return;
      }

      add_JobContainerInvoice({
        variables: {
          ref: job._id,
          amount: newAmount,
          attachment: formState.attachment,
          description: formState.description,
          invoice_by_reference: userData._id,
          invoice_by_name: userData.first_name + ' ' + userData.last_name,
          invoice_number: formState.invoice_number
        }
      });
    } else {
      setFileMissing(true);
      if (!formState.attachment) {
        setErrorMsg('Please add the invoice file');
      } else {
        setErrorMsg('Required Fields');
      }
    }
  };

  const [add_JobContainerInvoice] = useMutation(addJobContainerSubDocs, {
    onCompleted() {
      InvoicesResults.refetch();
      setOpendialog(false);
    },
    onError: err => {
      setFileMissing(true);
      setErrorMsg(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const removeFile = () => {
    setFile('');
    setFormState({ ...formState, attachment: null });
  };

  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.JobInvoices = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Invoices"
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickOpendialog}
              >
                Add new invoice
              </Button>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </>
          }
        ></CardHeader>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Number</TableCell>
                  <TableCell>Ex VAT Amount</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Uploaded by</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell colSpan={2}>Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {InvoicesList &&
                  InvoicesList.map(invoice => (
                    <TableRow hover key={invoice._id}>
                      <TableCell>{invoice.invoice_number}</TableCell>
                      <TableCell>
                        {currencyFormatter.format(invoice.amount)}
                      </TableCell>
                      <TableCell>
                        {invoice.attachment
                          ? (() => {
                              try {
                                const attachmentData = JSON.parse(
                                  invoice.attachment
                                );
                                return (
                                  <a
                                    href={attachmentData.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.uploadedInvoice}
                                  >
                                    {attachmentData.name}
                                  </a>
                                );
                              } catch (error) {
                                return 'Invalid attachment data';
                              }
                            })()
                          : 'No file attached'}
                      </TableCell>
                      <TableCell>{invoice.description}</TableCell>
                      <TableCell>{invoice.invoice_by_name}</TableCell>
                      <TableCell>
                        {moment
                          .unix(invoice.created_date * 0.001)
                          .format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={() => handleClickOpendialog(invoice)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell>
                        <DeleteIcon
                          onClick={() => handleClickOpenDel(invoice)}
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Total Amount</TableCell>
                  <TableCell colSpan={7}>
                    {currencyFormatter.format(totalAmount)}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </CardContent>
        </Collapse>
      </Card>

      <form autoComplete="off">
        <Dialog
          open={opendialog}
          onClose={handleClosedialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle id="alert-dialog-title">
            {editInvoice ? 'Edit Invoice' : 'Add Invoice'}
          </DialogTitle>
          <DialogContent>
            {fileMissing && <Alert severity="error">{errorMsg}</Alert>}
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    {!file ? (
                      <GoogleDriveUpload
                        uploadCallback={file => uploadCallbackFunction(file)}
                        buttonName="Upload&nbsp;Invoice"
                        folderName={`Invoices/${job.job_number}`}
                      />
                    ) : (
                      <div className={classes.deleteUpload}>
                        <GoogleDriveDeleteIcon
                          file={file}
                          response={removeFile}
                        />
                      </div>
                    )}
                    {file && (
                      <div className={classes.uploadFile}>
                        <a href={file.link} target="_blank" rel="noreferrer">
                          {file.name}
                        </a>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      label="Invoice No"
                      name="invoice_number"
                      variant="outlined"
                      value={formState.invoice_number}
                      onChange={changeHandler}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      type="number"
                      value={formState.amount}
                      onChange={changeHandler}
                      error={!!amountError}
                      helperText={amountError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <TextField
                      fullWidth
                      required
                      label="Description"
                      name="description"
                      variant="outlined"
                      value={formState.description}
                      onChange={changeHandler}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosedialog} color="primary">
              Close
            </Button>
            <Button
              onClick={editInvoice ? updateInvoice : submitInvoice}
              color="primary"
              autoFocus
              variant="contained"
              disabled={!!amountError}
            >
              {editInvoice ? 'Update' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <Dialog
        open={openDel}
        onClose={handleCloseDel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete invoice No{' '}
            <strong>{delInvoice.invoice_number}</strong>
            {delInvoice.attachment && (
              <>
                <br />
                and{' '}
                <strong>
                  {(() => {
                    try {
                      return JSON.parse(delInvoice.attachment).name;
                    } catch (error) {
                      return 'attachment';
                    }
                  })()}
                </strong>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDel} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleYesDel} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobInvoices;
