import React, { useState, useEffect, useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { updateJob } from 'src/queries/query';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import currencyformatter from 'src/utils/currencyformatter';
import Autocomplete from '@mui/material/Autocomplete';
// import Alert from '@mui/lab/Alert';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputAdornment from '@mui/material/InputAdornment';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SettingsIcon from '@mui/icons-material/Settings';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import TextEditor from 'src/components/TextEditor';

import Notes from 'src/views/notes/Notes';
import Tasks from 'src/views/tasks/Tasks';
import JUBudgetDetails from 'src/components/JUBudgetDetails';

const updateTimeSheetSwitch = gql`
  mutation TimeSheetSwitch($ref: ID!, $switch: Boolean!) {
    TimeSheetSwitch(ref: $ref, switch: $switch) {
      _id
    }
  }
`;

const updateTimeEstimateSwitch = gql`
  mutation TimeEstimateSwitch($ref: ID!, $switch: Boolean!) {
    TimeEstimateSwitch(ref: $ref, switch: $switch) {
      _id
    }
  }
`;

const updatespoSwitch = gql`
  mutation SupplierPoSwitch($ref: ID!, $switch: Boolean!) {
    SupplierPoSwitch(ref: $ref, switch: $switch) {
      _id
    }
  }
`;

const updateptcSwitch = gql`
  mutation PTCSwitch($ref: ID!, $switch: Boolean!) {
    PTCSwitch(ref: $ref, switch: $switch) {
      _id
    }
  }
`;

const updatesporestriction = gql`
  mutation SupplierPoRestriction($ref: ID!, $value: String!) {
    SupplierPoRestriction(ref: $ref, value: $value) {
      _id
    }
  }
`;

const updateJobBudget = gql`
  mutation updateJobBudget($ref: ID!, $budget: Float!) {
    updateJobBudget(ref: $ref, budget: $budget) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    date: {
      width: '100%'
    },
    marginList: {
      marginRight: '10px'
    },
    widthProgress: {
      width: '200px'
    },
    box: {
      padding: '5px 10px 15px 10px',
      border: 'solid 2px #c4c4c4',
      borderRadius: '5px'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({
  className,
  element,
  stage,
  fromProfileDetails,
  from_c2p_timesheet,
  from_c2p_timeestimate,
  from_c2p_sposwitch,
  from_c2p_ptcswitch,
  from_c2p_sporestriction,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();
  const timer3 = React.useRef();
  // const isPitch = element[0].job_type === 'Internal-Pitch' ? true : false;
  const [isPitch, setIsPitch] = useState(
    element[0].job_type === 'Internal-Pitch' ? true : false
  );
  const [pendingBudgetChange, setPendingBudgetChange] = useState(null);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const [state, setState] = useState({
    department_reference: '',
    ratecard_reference: '',
    allotted_budget: element[0].allotted_budget,
    budget_form: stage === 'complete' ? false : true,
    timesheetswitch: element[0].timesheet_switch,
    timeestimateswitch: element[0].time_estimate_switch,
    spolimit: element[0].spo_limit,
    sposwitch: element[0].spo_switch,
    ptcswitch: element[0].is_pass_through,
    sporestriction_checked: element[0].spo_restriction
    // contact_email : '',
    // client_ref : element[0].client_ref ? element[0].client_ref : '5fd40586d2522906b70ef208',
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [opendialog, setOpendialog] = React.useState(false);
  const [opensettingsdialog, setOpensettingsdialog] = React.useState(false);

  const handleClickOpendialog = () => {
    setOpendialog(true);
  };

  const handleClosedialog = () => {
    setOpendialog(false);
  };

  const handleClickOpensettingsdialog = () => {
    setOpensettingsdialog(true);
  };

  const handleCloseSettingsdialog = () => {
    setOpensettingsdialog(false);
  };

  const [update_TimeSheetSwitch] = useMutation(updateTimeSheetSwitch, {
    onCompleted() {
      console.log('timesheetswitch updated');
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handletimesheetswitch = () => {
    const newval = !state.timesheetswitch;
    update_TimeSheetSwitch({
      variables: {
        ref: element[0]._id,
        switch: newval
      }
    });
    setState({
      ...state,
      timesheetswitch: newval
    });
    from_c2p_timesheet(newval);
  };

  const [update_TimeEstimateSwitch] = useMutation(updateTimeEstimateSwitch, {
    onCompleted() {
      console.log('timeEstimateSwitch updated');
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handletimeestimateswitch = () => {
    const newval = !state.timeestimateswitch;
    update_TimeEstimateSwitch({
      variables: {
        ref: element[0]._id,
        switch: newval
      }
    });
    setState({
      ...state,
      timeestimateswitch: newval
    });
    from_c2p_timeestimate(newval);
  };

  // -------------------

  const [update_PTCSwitch] = useMutation(updateptcSwitch, {
    onCompleted() {
      console.log('ptcswitch updated');
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handleptcswitch = () => {
    const newval = !state.ptcswitch;
    update_PTCSwitch({
      variables: {
        ref: element[0]._id,
        switch: newval
      }
    });
    setState({
      ...state,
      ptcswitch: newval
    });
    from_c2p_ptcswitch(newval);
  };

  const [update_SpoSwitch] = useMutation(updatespoSwitch, {
    onCompleted() {
      console.log('sposwitch updated');
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handlesposwitch = () => {
    const newval = !state.sposwitch;
    update_SpoSwitch({
      variables: {
        ref: element[0]._id,
        switch: newval
      }
    });
    setState({
      ...state,
      sposwitch: newval
    });
    from_c2p_sposwitch(newval);
  };

  const [update_sporestriction] = useMutation(updatesporestriction, {
    onCompleted() {
      console.log('sporistriction updated');
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const handlesporestriction = event => {
    const newval = event.target.value;
    update_sporestriction({
      variables: {
        ref: element[0]._id,
        value: newval
      }
    });
    setState({
      ...state,
      sporestriction_checked: newval
    });
    from_c2p_sporestriction(newval);
  };

  const handleChangeSpolimit = event => {
    const newval = event.target.value;
    alert(state.spolimit);
    // update_sporestriction({
    //   variables: {
    //     ref: element[0]._id,
    //     value: newval
    //   }
    // });
    // setState({
    //   ...state,
    //   sporestriction_checked: newval
    // });
    // from_c2p_sporestriction(newval);
  };

  const sendChildToParent = (openModal, newBudget) => {
    console.log('from child', openModal, newBudget);
    setOpendialog(openModal);
    setState({
      ...state,
      allotted_budget: newBudget
    });
    fromProfileDetails(newBudget);
  };

  const [update_JobBudget] = useMutation(updateJobBudget, {
    onCompleted() {
      console.log('Budget updated');
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });
  const [
    update_Job,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateJob, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    },
    onError: err => {
      setError(err);
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const [jobDesc, setJobDesc] = useState(element[0].description);

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  const handleBudgetChange = newValue => {
    setPendingBudgetChange(newValue);
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsloading(true);

    try {
      // Update main job data
      await update_Job({
        variables: {
          ref: element[0]._id,
          data: {
            title: e.target.title.value,
            description: jobDesc,
            start_date: e.target.start_date.value,
            end_date: e.target.end_date.value
          }
        }
      });

      // If there's a pending budget change, update it
      if (pendingBudgetChange !== null) {
        await update_JobBudget({
          variables: {
            ref: element[0]._id,
            budget: pendingBudgetChange
          }
        });

        // Update local state
        setState(prev => ({
          ...prev,
          allotted_budget: pendingBudgetChange
        }));

        // Notify parent component
        fromProfileDetails(pendingBudgetChange);

        setPendingBudgetChange(null);
      }

      // Success handling
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    } catch (error) {
      setError(error);
      console.log(error);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Sub Job Details"
            action={
              stage === 'live' ? (
                <SettingsIcon
                  onClick={handleClickOpensettingsdialog}
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                />
              ) : null
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={isPitch ? 12 : 8} xs={12}>
                <TextField
                  fullWidth
                  // helperText="Please specify the title"
                  label="Job title"
                  name="title"
                  defaultValue={element[0].title}
                  required
                  variant="outlined"
                  InputProps={{
                    readOnly: stage === 'complete' ? true : false
                  }}
                />
              </Grid>

              {!isPitch && (
                <Grid item md={4} xs={12}>
                  {!isPitch && (
                    <JUBudgetDetails
                      budget={state.allotted_budget}
                      estimated_time={element[0].estimated_time}
                      estimated_ftc={element[0].estimated_ftc}
                      estimated_budget_allocation={
                        element[0].total_budget_allocation
                      }
                      available_for_estimate={element[0].available_for_estimate}
                      containerID={element[0].container_ref}
                      elementID={element[0]._id}
                      showForm={stage !== 'complete'}
                      stage={stage}
                      onBudgetChange={handleBudgetChange}
                      time_estimate_switch={state.timeestimateswitch}
                    />
                  )}
                </Grid>
              )}

              <Grid item md={isPitch ? 6 : 3} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Start Date"
                  name="start_date"
                  defaultValue={
                    element[0].start_date
                      ? new Date(parseInt(element[0].start_date))
                          .toISOString()
                          .slice(0, 10)
                      : null
                  }
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete',
                    onFocus: e => {
                      if (stage !== 'complete') {
                        e.target.showPicker?.();
                      }
                    }
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>

              <Grid item md={isPitch ? 6 : 3} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Delivery Date"
                  name="end_date"
                  defaultValue={
                    element[0].end_date
                      ? new Date(parseInt(element[0].end_date))
                          .toISOString()
                          .slice(0, 10)
                      : null
                  }
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete',
                    onFocus: e => {
                      if (stage !== 'complete') {
                        e.target.showPicker?.();
                      }
                    }
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextEditor
                  textEditorOutput={textEditorOutput}
                  textEditorInput={element[0].description}
                  canEdit={stage === 'complete' ? false : true}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Notes type="jobs" reference={element[0]._id} />
            <Tasks
              type="jobs"
              collection_ref={element[0]._id}
              element={element[0]}
            />
            {/* pending: stage with state variable */}
            {stage !== 'complete' &&
              (askareyousure ? (
                <button
                  type="submit"
                  id="button"
                  className={`btn-animate ${isloading ? 'onclic' : null} ${
                    !isloading && completed ? 'validate' : null
                  } ${iserror ? 'novalidate' : null}`}
                ></button>
              ) : (
                <button
                  onClick={ask}
                  id="ask"
                  className={`btn-animate ask`}
                ></button>
              ))}
          </Box>
        </Card>
      </form>

      {/* dialog settings */}
      <form autoComplete="off">
        <Dialog
          open={opensettingsdialog}
          onClose={handleCloseSettingsdialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="div">Sub Job Setting</Typography>
              <IconButton onClick={handleCloseSettingsdialog} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Sub Job Settings</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.timesheetswitch}
                          onChange={handletimesheetswitch}
                        />
                      }
                      label="TimeSheet (On / Off)"
                    />
                    {/* <FormControlLabel
                      control={
                        <Switch
                          checked={state.timeestimateswitch}
                          onChange={handletimeestimateswitch}
                        />
                      }
                      label="Time Estimate (On / Off)"
                    /> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.sposwitch}
                          onChange={handlesposwitch}
                        />
                      }
                      label="Supplier PO (On / Off)"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.ptcswitch}
                          onChange={handleptcswitch}
                        />
                      }
                      label="Is Pass Through (On / Off)"
                    />
                  </FormGroup>
                  <Divider component="li" />
                  <FormLabel id="demo-radio-buttons-group-label">
                    Supplier PO Restriction
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="ftc"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="ftc"
                      control={
                        <Radio
                          checked={state.sporestriction_checked === 'ftc'}
                          onChange={handlesporestriction}
                          value="ftc"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      }
                      label="Bound to Third Party Cost (Default Value)"
                    />
                    <FormControlLabel
                      value="budget"
                      control={
                        <Radio
                          checked={state.sporestriction_checked === 'budget'}
                          onChange={handlesporestriction}
                          value="budget"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      }
                      label="Bound to budget"
                    />
                    <FormControlLabel
                      value="open"
                      control={
                        <Radio
                          checked={state.sporestriction_checked === 'open'}
                          onChange={handlesporestriction}
                          value="open"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      }
                      label="Open (For internal Jobs)"
                    />

                    {/* <FormControlLabel
                      value="oneftc"
                      control={
                        <Radio
                          checked={state.sporestriction_checked === 'oneftc'}
                          onChange={handlesporestriction}
                          value="oneftc"
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      }
                      label="Bound to budget (Pending)"
                    /> */}
                  </RadioGroup>
                  <FormHelperText>Please Be careful</FormHelperText>
                </FormControl>

                <TextField
                  fullWidth
                  label="Single SPO Limit"
                  name="spo_limit"
                  variant="outlined"
                  margin="normal"
                  defaultValue={state.spolimit === -1 ? '' : state.spolimit}
                  onChange={handleChangeSpolimit}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                />
              </>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </form>
    </>
  );
};

export default ProfileDetails;
