import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, CardContent, Button } from '@mui/material';
import GoogleIcon from 'src/icons/Google';

import { gapi, loadAuth2 } from 'gapi-script';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import { CLIENT_ID } from '../../../components/googleComponents/GoogleApiAccess';

const formatEvents = (list, color) => {
  list = list.filter(item => item.status !== 'cancelled');
  return list.map(item => ({
    title: item.summary,
    start: item.start.dateTime || item.start.date,
    end: item.end.dateTime || item.end.date,
    color: color
  }));
};

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: '100%'
    },
    image: {
      width: '100%'
    },
    calendarItem: {
      '& .fc-timegrid-slot-label-cushion, & .fc-timegrid-axis-cushion': {
        fontSize: '0.675rem'
      },
      '& .fc-toolbar.fc-header-toolbar': {
        marginBottom: '0px'
      },
      '& .fc-toolbar-chunk': {
        width: '100%',
        paddingBottom: '15px',
        textTransform: 'uppercase',
        letterSpacing: '0.02857em',
        fontFamily: "'Roboto', sans-serif"
      },
      '& .fc-toolbar-chunk:fist-child .fc-button-primary': {
        fontSize: '0.675rem'
      },
      '& .fc-toolbar-chunk h2': {
        fontSize: '0.875rem',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        margin: '0 auto',
        textAlign: 'center'
      },
      '& .fc-toolbar-chunk:last-child': {
        textAlign: 'right'
      },
      '& .fc .fc-button-primary': {
        padding: '4px 10px'
      },
      '& .fc .fc-toolbar-chunk:last-child .fc-button-primary': {
        padding: '3px 4px'
      },
      '& .fc-daygrid-day.fc-day-today': {
        backgroundColor: '#FFF !important'
      }
    }
  };
});

const Calendar = props => {
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState(null);
  const [calTime, setCalTime] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(gapi, CLIENT_ID, '');
      if (auth2.isSignedIn.get()) {
        gapi.load('client', () => {
          gapi.client.load('calendar', 'v3', () => {
            listCalendars();
          });
        });
      } else {
        console.log('NOT Logged IN - Logout action and go to login page');
      }
    };
    setAuth2();
  }, []);

  async function grantScopes() {
    console.log('user is NOT Granted');
    const GoogleAuth = gapi.auth2.getAuthInstance();
    const user = GoogleAuth.currentUser.get();
    const scopes =
      'profile email https://www.googleapis.com/auth/calendar.readonly';
    user.grant({ scope: scopes }).then(
      function() {
        setShowButton(false);
        listCalendars();
      },
      function(e) {
        console.log('ERROR: ', e);
      }
    );
  }

  // Get the active calendars for user
  async function listCalendars() {
    let cals;

    try {
      await window.gapi.client.calendar.calendarList
        .list()
        .then(function(resp) {
          cals = resp.result.items;
        });
    } catch (err) {
      setShowButton(true);
      return;
    }
    const newCals = cals
      .filter(function(item) {
        return item.selected;
      })
      .map(item => ({
        id: item.id,
        summary: item.summary,
        color: item.backgroundColor
      }));
    setCalendars(newCals);
  }

  // Get the events from single calendar
  async function getCalendarEvents(calendar, calDates) {
    const timeMin = moment(calDates.start)
      .add(-2, 'days')
      .toISOString();
    const timeMax = moment(calDates.end)
      .add(2, 'days')
      .toISOString();
    const calendarId = calendar.id;
    const color = calendar.color;
    let calEvents;

    await window.gapi.client.calendar.events
      .list({
        calendarId: calendarId,
        timeMin: timeMin,
        timeMax: timeMax,
        orderBy: 'startTime',
        showDeleted: true,
        singleEvents: true
      })
      .then(function(response) {
        const events = response.result.items;

        if (events.length > 0) {
          calEvents = formatEvents(events, color);
        }
      });

    return calEvents;
  }

  // Process calendars to get events from them all
  //  eslint-disable-next-line
  async function getCalendarsEvents(calDates) {
    let allCalendarEvents = [];

    if (calendars) {
      for (let i = 0; i < calendars.length; i++) {
        if (calendars[i].id) {
          //  eslint-disable-next-line
          let calEvents = await getCalendarEvents(calendars[i], calDates);
          if (calEvents)
            allCalendarEvents = allCalendarEvents.concat(calEvents);
        }
      }

      setEvents(allCalendarEvents);
      return allCalendarEvents;
    }
  }

  function viewRenderCallback(fetchInfo, successCallback) {
    // Old function on calendar call
    let fdate = new Date().toISOString();
    if (fetchInfo) fdate = new Date(fetchInfo.start).toISOString();
    let cdate = '';
    if (calTime) cdate = new Date(calTime.start).toISOString();
    if (fdate.toString() !== cdate.toString() && calendars) {
      setCalTime(fetchInfo);
      getCalendarsEvents(fetchInfo);
    }
    if (events) successCallback(events);
  }

  return (
    <Card>
      <CardContent className={classes.calendarItem}>
        {showButton && (
          <Button
            color="primary"
            variant="contained"
            //  eslint-disable-next-line
            onClick={grantScopes}
            style={{ marginBottom: '10px' }}
          >
            <GoogleIcon />
            Connect Google Calendar
          </Button>
        )}
        <FullCalendar
          initialView="timeGridDay"
          // initialView="listWeek"
          headerToolbar={{
            left: 'today',
            center: 'title',
            right: 'prev,next'
          }}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false
          }}
          titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          scrollTime={'9:00:00'}
          themeSystem="bootstrap"
          hiddenDays="[ 6, 0 ]"
          eventMinHeight={20}
          locale="en-GB"
          firstDay={1}
          height={800}
          events={(fetchInfo, successCallback) =>
            viewRenderCallback(fetchInfo, successCallback)
          }
        />
      </CardContent>
    </Card>
  );
};

export default Calendar;
