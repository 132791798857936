import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';

import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { makeStyles } from 'tss-react/mui';
import { Box, Container, Grid, Card, CardHeader, Divider } from '@mui/material';
import Page from 'src/components/Page';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Ratecard } from 'src/queries/query';
import RatesDetails from './RatesDetails';
import Toolbar from './Toolbar';

export const CRUD_Rights = gql`
  query CRUD_Rights {
    CRUD_Rights {
      _id
      caller_function
      description
      operation_type
      action_node
      user_level
    }
  }
`;

export const setUser_levelCRUD_Right = gql`
  mutation setUser_levelCRUD_Right(
    $caller_function: String!
    $user_level: Int!
  ) {
    setUser_levelCRUD_Right(
      caller_function: $caller_function
      user_level: $user_level
    ) {
      _id
      caller_function
      description
      operation_type
      action_node
      user_level
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  };
});

const RatecardView = () => {
  const [userRightsData, setUserRightsData] = useState('');
  const { classes } = useStyles();
  const ratecardId = '65a7171c8637741c9f25c706';
  // console.log(ratecardId);
  const [expanded, setExpanded] = useState(false);

  // edit rate cell
  const [isEditMode, setIsEditMode] = useState(false);
  const onToggleEditMode = value => {
    setIsEditMode(value);
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const RightsQuery = useQuery(CRUD_Rights);

  // const CRUD_Rights_data = data.CRUD_Rights;
  // console.log('CRUD_Rights_data', CRUD_Rights_data);

  useEffect(() => {
    if (RightsQuery.data) setUserRightsData(RightsQuery.data.CRUD_Rights);
  }, [RightsQuery]);

  const [setUserlevelCRUD_Right, { error: mutationError }] = useMutation(
    setUser_levelCRUD_Right,
    {
      onCompleted() {
        console.log('User Level Updated');
      },
      onError: err => {
        console.log(err);
      }
    }
  );

  const handleSaveCell = (cell, value) => {
    // console.log(cell.row.original.caller_function);
    // console.log(value);
    // console.log('perform the update here');
    if (!parseInt(value)) {
      alert('not integer');
    }
    setUserlevelCRUD_Right({
      variables: {
        caller_function: cell.row.original.caller_function,
        user_level: parseInt(value)
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'caller_function',
        header: 'Caller Function',
        enableEditing: false
      },
      {
        accessorKey: 'operation_type',
        header: 'Operation Type',
        enableEditing: false
      },
      {
        accessorKey: 'action_node',
        header: 'Action Node',
        enableEditing: false
      },
      {
        accessorKey: 'user_level',
        header: 'User Level',
        enableEditing: 'True',
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.('en-UK', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </>
        )
      }
    ],
    []
  );

  return (
    <Page className={classes.root} title="Ratecard">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={2}>
          {/* https://www.material-react-table.com/docs/guides/editing#cell-editing-mode */}
          <MaterialReactTable
            columns={columns}
            data={userRightsData}
            editingMode="cell"
            enableEditing
            enablePagination={false}
            enableColumnActions={false}
            enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            enableFullScreenToggle={false}
            enableHiding={false}
            muiTableBodyCellEditTextFieldProps={({ cell }) => ({
              //onBlur is more efficient, but could use onChange instead
              onBlur: event => {
                handleSaveCell(cell, event.target.value);
              }
            })}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default RatecardView;
