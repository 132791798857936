import React, { useState, useRef } from 'react';
import {
  TextField,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  LinearProgress,
  InputAdornment,
  ClickAwayListener
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const useStyles = makeStyles()(() => ({
  popper: {
    zIndex: 1300,
    width: 600,
    maxWidth: '90%',
    '& .MuiPaper-root': {
      padding: '16px',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
    }
  },
  paper: {
    padding: 16
  },
  marginList: {
    marginRight: '10px'
  },
  marginListUnits: {
    marginLeft: '10px'
  },
  widthProgress: {
    width: '200px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));

const AllocatedBudgetTooltip = ({
  value,
  onChange,
  container,
  totalBudget,
  allocatedBudget,
  currencyformatter
}) => {
  const { classes } = useStyles();
  const [error, setError] = useState('');
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleBudgetChange = event => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (newValue === '') {
      onChange(event);
      return;
    }

    const numericValue = parseFloat(newValue);
    if (!Number.isNaN(numericValue)) {
      if (numericValue < 0) {
        setError('Budget cannot be negative');
      } else {
        const remainingBudget = totalBudget - allocatedBudget;
        if (numericValue > remainingBudget) {
          setError(
            `Budget cannot exceed remaining amount (${currencyformatter.format(
              remainingBudget
            )})`
          );
        } else {
          setError('');
          onChange({
            target: {
              name: 'allotted_budget',
              value: numericValue
            }
          });
        }
      }
    }
  };

  const handleClickAway = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleFocus = event => {
    setOpen(true);
    setIsEditing(true);
    if (currentValue === 0) {
      setInputValue('');
    } else {
      setInputValue(value);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    setOpen(false);
  };

  const currentValue = parseFloat(value) || 0;
  const remainingBudget = totalBudget - allocatedBudget - currentValue;

  return (
    <div ref={containerRef}>
      <div className={classes.inputContainer}>
        <TextField
          fullWidth
          required
          label="Allocated Budget"
          name="allotted_budget"
          value={
            isEditing ? inputValue : currencyformatter.format(currentValue)
          }
          onChange={handleBudgetChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={!!error}
          helperText={error}
          variant="outlined"
          type={isEditing ? 'number' : 'text'}
          InputProps={
            isEditing
              ? {
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      £
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }
              : {
                  readOnly: true,
                  inputProps: { min: 0 }
                }
          }
        />
      </div>

      <Popper
        open={open}
        anchorEl={containerRef.current}
        placement="bottom-start"
        className={classes.popper}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paper} elevation={4}>
            <List>
              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  Master Job Budget
                </ListItemIcon>
                <ListItemText primary={currencyformatter.format(totalBudget)} />
              </ListItem>

              <Divider component="li" />

              {container.units.length > 0 && (
                <>
                  <ListItem>
                    <ListItemIcon className={classes.marginList}>
                      Allocated Budget for Sub Jobs
                    </ListItemIcon>
                  </ListItem>
                  {container.units
                    .filter(item => item.jobunit_ref[0].job_type === 'Normal')
                    .map((item, index) => (
                      <div key={index} className={classes.marginListUnits}>
                        <ListItem>
                          <ListItemIcon className={classes.marginList}>
                            {item.jobunit_ref[0].title}
                          </ListItemIcon>
                          <ListItemText
                            primary={currencyformatter.format(
                              item.allocated_budget
                            )}
                          />
                          <ListItemSecondaryAction>
                            <LinearProgress
                              className={classes.widthProgress}
                              value={
                                (item.allocated_budget * 100) / totalBudget
                              }
                              variant="determinate"
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    ))}
                  <Divider component="li" />
                </>
              )}

              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  Remaining Budget
                </ListItemIcon>
                <ListItemText
                  primary={currencyformatter.format(remainingBudget)}
                />
                <ListItemSecondaryAction>
                  <LinearProgress
                    className={classes.widthProgress}
                    value={(remainingBudget * 100) / totalBudget}
                    variant="determinate"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default AllocatedBudgetTooltip;
