import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';

const SubscriptionDialog = ({
  open,
  onClose,
  onSubscriptionSelect,
  currentToken,
  subscriptionChoices
}) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscriptionId, setActiveSubscriptionId] = useState(null);

  useEffect(() => {
    if (subscriptionChoices) {
      setSubscriptions(subscriptionChoices);
      localStorage.setItem(
        'subscriptionChoices',
        JSON.stringify(subscriptionChoices)
      );
    }
  }, [subscriptionChoices]);

  useEffect(() => {
    axios
      .get('/getSubscriptions')
      .then(response => {
        setSubscriptions(response.data);
        const activeSubscription = response.data.find(sub => sub.active);
        if (activeSubscription) {
          setActiveSubscriptionId(activeSubscription._id);
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const handleSubscriptionChoice = async subscriptionId => {
    try {
      const response = await fetch(
        process.env.REACT_APP_GQL_BASE_URL + '/api/setsubscription',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': currentToken
          },
          body: JSON.stringify({ _id: subscriptionId })
        }
      );

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('authToken', currentToken);
        onSubscriptionSelect(true);
        onClose();
      } else {
        console.error('Error:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Choose Your Subscription</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {subscriptions.map(subscription => (
            <Grid item xs={12} key={subscription._id}>
              <Button
                fullWidth
                variant={
                  subscription._id === activeSubscriptionId
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => handleSubscriptionChoice(subscription._id)}
                sx={{
                  textTransform: 'none',
                  height: '60px',
                  fontSize: '1.1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 16px'
                }}
              >
                <span>{subscription.company_name}</span>
                {subscription._id === activeSubscriptionId && (
                  <Typography
                    variant="caption"
                    sx={{
                      bgcolor: 'primary.dark',
                      color: 'white',
                      px: 1,
                      py: 0.5,
                      borderRadius: 1
                    }}
                  >
                    Active
                  </Typography>
                )}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionDialog;
