import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Notes from 'src/views/notes/Notes';

import AuthContext from 'src/components/AuthContext';
import { Query } from '@apollo/client/react/components';
import { useMutation } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LinearProgress from '@mui/material/LinearProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, AlertTitle } from '@mui/lab';
import gql from 'graphql-tag';
import moment from 'moment';
import { Suppliers } from 'src/queries/query';
import currencyformatter from 'src/utils/currencyformatter';
import GoogleDriveUpload from '../../../components/googleComponents/GoogleDriveUpload';
import GoogleDriveDeleteIcon from '../../../components/googleComponents/GoogleDriveDeleteIcon';

// Setting Up Queries

const addSupplierPO = gql`
  mutation addSupplierPO(
    $ref: ID!
    $amount: Float!
    $description: String!
    $suppliers_reference: String!
    $company_name: String!
    $supply_date: String!
    $ftc_title: String
    $ftc_number: String
  ) {
    addSupplierPO(
      ref: $ref
      data: {
        amount: $amount
        description: $description
        suppliers_reference: $suppliers_reference
        company_name: $company_name
        supply_date: $supply_date
        ftc_title: $ftc_title
        ftc_number: $ftc_number
      }
    ) {
      _id
      estimated_ftc
      supplier_po_total
      supplier_po {
        _id
        supplier_po
        amount
        description
        suppliers_reference
        company_name
        po_by_reference
        po_by_name
        created_date
        approved
        approved_by_reference
        approved_by_name
        approved_date
        emailed
        email_date
        ftc_title
        ftc_number
        invoices {
          _id
        }
      }
    }
  }
`;
const updateSupplierPO = gql`
  mutation updateSupplierPO(
    $ref: ID!
    $amount: Float!
    $description: String!
    $suppliers_reference: String!
    $company_name: String!
    $supply_date: String
  ) {
    updateSupplierPO(
      ref: $ref
      data: {
        amount: $amount
        description: $description
        suppliers_reference: $suppliers_reference
        company_name: $company_name
        supply_date: $supply_date
      }
    ) {
      _id
      supplier_po_total
      supplier_po {
        _id
        supplier_po
        amount
        description
        suppliers_reference
        company_name
        po_by_reference
        po_by_name
        approved
        approved_by_reference
        approved_by_name
        approved_date
        emailed
        email_date
        spo_status
        supply_date
        completed_date
        ftc_title
        ftc_number
        invoices {
          _id
        }
      }
    }
  }
`;
const addSupplierPOinvoice = gql`
  mutation addSupplierPOInvoice(
    $ref: ID!
    $amount: Float!
    $description: String
    $invoice: String
    $invoice_by_reference: String
    $invoice_by_name: String
    $invoice_date: String
  ) {
    addSupplierPOInvoice(
      ref: $ref
      data: {
        amount: $amount
        description: $description
        invoice: $invoice
        invoice_by_reference: $invoice_by_reference
        invoice_by_name: $invoice_by_name
        invoice_date: $invoice_date
      }
    ) {
      supplier_po {
        _id
        spo_status
        supply_date
        invoices {
          _id
          amount
          description
          invoice
          invoice_by_name
          invoice_date
        }
      }
    }
  }
`;
const removeSupplierPOInvoice = gql`
  mutation removeSupplierPOInvoice($ref: ID!) {
    removeSupplierPOInvoice(ref: $ref) {
      supplier_po {
        _id
        invoices {
          _id
          amount
          description
          invoice
          invoice_by_name
          invoice_date
        }
      }
    }
  }
`;

// Styles
const useStyles = makeStyles()(theme => {
  return {
    deleteUpload: {
      float: 'left'
    },
    uploadFile: {
      float: 'left',
      lineHeight: '2.2em',
      marginLeft: '10px',
      textOverflow: 'ellipsis',
      overflow: 'auto',
      textDecoration: 'underline',
      whiteSpace: 'nowrap'
    },
    uploadedRow: {
      display: 'flex'
    },
    uploadedInvoice: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'auto',
      maxWidth: '15vw',
      display: 'inherit',
      textDecoration: 'underline'
    }
  };
});

// Single Row component
function Row(props) {
  const { row } = props;
  const poref = row._id;

  const { userData } = useContext(AuthContext);
  const [file, setFile] = useState();
  const [opendialog, setOpendialog] = useState(false);
  const [editSPO, setEditSPO] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [open, setOpen] = useState(false);

  const { classes, cx } = useStyles();

  const formDefaults = {
    ftc_title: '',
    ftc_number: '',
    description: '',
    amount: '',
    suppliers_po: '',
    suppliers_reference: '',
    company_name: '',
    poref: poref
  };

  const [formState, setFormState] = useState(formDefaults);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };

  const uploadCallbackFunction = file => {
    const fileOb = {
      id: file.id,
      name: file.name,
      link: file.webViewLink
    };
    setFile(fileOb);

    setFormState({ ...formState, invoice: JSON.stringify(fileOb) });
  };

  const removeFile = () => {
    setFile('');
    console.log('file REMOVED');
  };
  const handleClosedialog = () => {
    setOpendialog(false);
  };

  const handleOpenEditSPO = () => {
    setFormState(row);
    setEditSPO(true);
  };

  const handleCloseEditSPO = () => {
    setEditSPO(false);
  };

  const handleSaveEditSPO = () => {
    updateSPO();
    setEditSPO(false);
  };

  // Form Query Actions start
  const submitPOInvoice = () => {
    if (formState.description && formState.amount) {
      add_SupplierPOinvoice({
        variables: {
          ref: formState.poref,
          amount: parseFloat(formState.amount),
          description: formState.description,
          invoice_by_reference: userData._id,
          invoice_by_name: userData.first_name + ' ' + userData.last_name,
          invoice: formState.invoice,
          invoice_date: new Date().toISOString()
        }
      });
    } else {
      setErrorMsg('Please add the necessary information');
      setOpendialog(true);
    }
  };

  const [
    add_SupplierPOinvoice
    // {loading: mutationLoading, error: mutationError },
  ] = useMutation(addSupplierPOinvoice, {
    onCompleted() {
      setFormState(formDefaults);
      setFile('');
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      console.log(msg);

      setOpendialog(true);
      setErrorMsg(msg);
    }
    // refetchQueries: [{ query: SupplierPOs }]
  });

  const removeInvoiceItem = invoice => {
    delete_SupplierPOInvoice({
      variables: {
        ref: invoice._id
      }
    });
  };

  const [
    delete_SupplierPOInvoice
    // {loading: mutationLoading, error: mutationError },
  ] = useMutation(removeSupplierPOInvoice, {
    onCompleted() {
      // handleCloseDel();
      // console.log('Close');
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');

      setOpendialog(true);
      setErrorMsg(msg);
    }
    // refetchQueries: [{ query: SupplierPOs }]
  });

  const updateSPO = () => {
    // setAskareyousure(false);
    // console.log(formState, 'Suppliear PO details');
    // alert(moment.unix(formState.su * 0.001).format('YYYY-MM-DD'));
    console.log(formState);
    if (formState.description && formState.amount) {
      update_SupplierPO({
        variables: {
          ref: formState._id,
          amount: parseFloat(formState.amount),
          description: formState.description,
          suppliers_reference: formState.suppliers_reference,
          company_name: formState.company_name,
          supply_date: formState.supply_date
        }
      });
      setFormState(formDefaults);
    } else {
      console.log('fail!');
    }
  };

  const [
    update_SupplierPO
    // {loading: mutationLoading, error: mutationError },
  ] = useMutation(updateSupplierPO, {
    onCompleted(data) {
      console.log('DATA', data);

      /*
      pending
      spo_sum = data.addSupplierPO.supplier_po_total;
      setUpdatedSPOtotal(spo_sum);
      */
      // setOpendialog(false);
      // setErrorbudget(false);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');

      setOpendialog(true);
      setErrorMsg(msg);
    }
  });
  // Form Query Actions end

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.supplier_po}
        </TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>£{row.amount}</TableCell>
        <TableCell>{row.invoiced_amount}</TableCell>
        <TableCell>
          {moment.unix(row.created_date * 0.001).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>{row.spo_status}</TableCell>
        <TableCell>{row.company_name}</TableCell>
        <TableCell>{row.po_by_name}</TableCell>
        <TableCell>
          <Notes type="jobs.supplier_po" reference={row._id} />
        </TableCell>
        <TableCell>
          <IconButton aria-label="edit" size="small">
            <EditRoundedIcon onClick={() => handleOpenEditSPO(row)} />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.invoices[0] && (
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Uploaded by</TableCell>
                      <TableCell>Date uploaded</TableCell>
                      <TableCell>File</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.invoices.map(invoice => (
                      <TableRow key={invoice.date}>
                        <TableCell>{invoice.description}</TableCell>
                        <TableCell>{invoice.amount}</TableCell>
                        <TableCell>{invoice.invoice_by_name}</TableCell>
                        <TableCell>
                          {moment
                            .unix(invoice.invoice_date * 0.001)
                            .format('DD/MM/YYYY')}
                        </TableCell>
                        {invoice.invoice ? (
                          <>
                            <TableCell>
                              <a
                                className={classes.uploadedInvoice}
                                href={JSON.parse(invoice.invoice).link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {JSON.parse(invoice.invoice).name}
                              </a>
                            </TableCell>
                            <TableCell>
                              <GoogleDriveDeleteIcon
                                file={JSON.parse(invoice.invoice)}
                                response={() => removeInvoiceItem(invoice)}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
            <Box margin={1}>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    variant="outlined"
                    value={formState.description}
                    onChange={changeHandler}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    label="Amount"
                    name="amount"
                    variant="outlined"
                    value={formState.amount}
                    onChange={changeHandler}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  {!file ? (
                    <GoogleDriveUpload
                      uploadCallback={file => uploadCallbackFunction(file)}
                      buttonName="Upload&nbsp;Invoice"
                      folderName={`SupplierPOInvoices/${props.masterJob}`}
                    />
                  ) : (
                    <div className={classes.uploadedRow}>
                      <div className={classes.deleteUpload}>
                        <GoogleDriveDeleteIcon
                          file={file}
                          response={removeFile}
                        />
                      </div>
                      <div className={classes.uploadFile}>
                        <a
                          href={file.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.name}
                        </a>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item md={1} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={submitPOInvoice}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
              {/* <div>FORM FORM FORM</div> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* Dialog for error message */}
      <Dialog
        open={opendialog}
        onClose={handleClosedialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosedialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for error message End*/}
      {/* Dialog for SPO Edit */}
      <Dialog
        open={editSPO}
        onClose={handleCloseEditSPO}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <strong>Edit Supplier PO</strong>
          </DialogContentText>

          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <strong>Description</strong>
              <br />
              {formState.description}
              {/* <TextField
                fullWidth
                label="Description"
                name="description"
                variant="outlined"
                value={formState.description}
                onChange={changeHandler}
              /> */}
            </Grid>
            <Grid item md={3} xs={12}>
              <strong>Supplier</strong>
              <br />
              {formState.company_name}
              {/* <Query query={Suppliers}>
                {({ loading, data }) =>
                  !loading && (
                    <Autocomplete
                      id="supplierlist"
                      options={data.Suppliers}
                      getOptionLabel={option => option.company_name}
                      onChange={(event, values) => {
                        if (values) {
                          setFormState({
                            ...formState,
                            suppliers_reference: values._id,
                            company_name: values.company_name
                          });
                        } else {
                          setFormState({
                            ...formState,
                            suppliers_reference: ''
                          });
                        }
                      }}
                      // defaultValue={formState}
                      value={formState}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Supplier"
                          name="company_name"
                          variant="outlined"
                          val={formState.company_name}
                        />
                      )}
                    />
                  )
                }
              </Query> */}
            </Grid>
            <Grid item md={3} xs={12}>
              <strong>Date of Supply</strong>
              <br />
              {moment.unix(formState.supply_date * 0.001).format('YYYY-MM-DD')}
              {/* <TextField
                // className={cx(classes.date)}
                id="date"
                required
                fullWidth
                label="Date of Supply"
                name="supply_date"
                // defaultValue={formState.supply_date}
                defaultValue={moment
                  .unix(formState.supply_date * 0.001)
                  .format('YYYY-MM-DD')}
                onChange={changeHandler}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
              /> */}
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Amount"
                name="amount"
                variant="outlined"
                value={formState.amount}
                onChange={changeHandler}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditSPO} color="primary" autoFocus>
            Close
          </Button>
          <Button onClick={handleSaveEditSPO} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for SPO Edit */}
    </>
  );
}

const SupplierPoDetails = ({
  className,
  job,
  invoices,
  jobBudgetstate,
  ftcTotal,
  sporestriction,
  ...rest
}) => {
  // class SupplierPoDetails extends React.Component {

  const useStyles = makeStyles()(theme => {
    return {
      marginList: {
        marginRight: '10px'
      },
      marginLeft: {
        marginLeft: '10px'
      },
      txtRight: {
        marginLeft: '10px',
        textAlign: 'right'
      },
      widthProgress: {
        width: '100%'
      },
      fullProgress: {
        width: '100%',
        margin: '0 auto',
        '& $widthProgress': {
          width: '100%'
        }
      },
      marginListUnits: {
        marginLeft: '10px'
      }
    };
  });

  const { classes, cx } = useStyles();
  const formDefaults = {
    description: '',
    amount: '',
    supplier_po: '',
    suppliers_reference: '',
    company_name: '',
    supply_date: new Date().toISOString().slice(0, 10)
  };
  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const { userData } = useContext(AuthContext);
  const [randkey, setRandkey] = useState(
    moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
  );

  const [jobBudget, setJobBudget] = useState(0);
  const [updatedFTCtotal, setUpdatedFTCtotal] = useState(0);
  const [spolimit, setSpolimit] = useState(0);
  const [updatedSPOtotal, setUpdatedSPOtotal] = useState(job.supplier_po_total);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.SupplierPoDetails === false ? false : true
  );

  const [errorMsg, setErrorMsg] = useState(false);
  const [errorallert, setErrorallert] = useState('');
  //
  let spo_sum = '';
  const jobref = job._id;

  const [formState, setFormState] = useState(formDefaults);

  let spol = 0;

  useEffect(() => {
    spol = job.spo_restriction === 'ftc' ? ftcTotal : jobBudgetstate;
    setSpolimit(spol);
  }, [jobBudgetstate, ftcTotal]);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };

  const getPONumber = () => {
    let number = 'SPO-';
    // var jobref = job._ref;
    number += jobref.substring(0, 4) + '-';
    number += moment().format('MMYY') + '-';
    number += Math.floor(Math.random() * 10000);
    return number;
  };

  const submitPO = () => {
    setAskareyousure(false);

    if (formState.description && formState.amount) {
      add_SupplierPO({
        variables: {
          ref: jobref,
          amount: parseFloat(formState.amount),
          description: formState.description,
          suppliers_reference: formState.suppliers_reference,
          company_name: formState.company_name,
          supply_date: formState.supply_date,
          ftc_title: formState.ftc_title,
          ftc_number: formState.ftc_number
        }
      });
    } else {
      console.log('fail!');
      setErrorallert('fail!');
    }
  };

  const [
    add_SupplierPO
    // {loading: mutationLoading, error: mutationError },
  ] = useMutation(addSupplierPO, {
    onCompleted(data) {
      spo_sum = data.addSupplierPO.supplier_po_total;
      setUpdatedSPOtotal(spo_sum);
      setFormState(formDefaults);
      setRandkey(
        moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
      );
      // setOpendialog(false);
      // setErrorbudget(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setErrorallert(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: SupplierPOs }]
  });

  useEffect(() => {
    if (job.spo_restriction === 'ftc') {
      setSpolimit(job.estimated_ftc);
    }
    setUpdatedSPOtotal(job.supplier_po_total);
  }, [job]);

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const hideError = () => {
    setErrorMsg('');
    setErrorallert(false);
  };
  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.SupplierPoDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  const masterJob = job.job_number.split('-')[0];

  return (
    <Card>
      <CardHeader
        // subheader="The information can be edited"
        title="Supplier Po"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        {job.stage !== 'complete' && (
          <CardContent>
            <CardContent>
              <Grid container spacing={4}>
                <Grid container item md={6} xs={12} spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      id="ftc-select"
                      options={job.fixed_task_cost}
                      getOptionLabel={option =>
                        `${option.ftc_for_supplier} - ${option.title}`
                      }
                      onChange={(event, value) => {
                        if (value) {
                          setFormState({
                            ...formState,
                            ftc_number: value.ftc_number,
                            ftc_title: value.title
                          });
                        } else {
                          setFormState({
                            ...formState,
                            ftc_number: '',
                            ftc_title: ''
                          });
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Supplier Cost"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      variant="outlined"
                      value={formState.description}
                      onChange={changeHandler}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      value={formState.amount}
                      onChange={changeHandler}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Query query={Suppliers}>
                      {({ loading, data }) =>
                        !loading && (
                          <Autocomplete
                            id="supplierlist"
                            options={data.Suppliers}
                            getOptionLabel={option => option.company_name}
                            key={randkey}
                            onChange={(event, values) => {
                              console.log(values);
                              if (values) {
                                setFormState({
                                  ...formState,
                                  suppliers_reference: values._id,
                                  company_name: values.company_name
                                });
                              } else {
                                setFormState({
                                  ...formState,
                                  suppliers_reference: '',
                                  company_name: ''
                                });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                value={formState.company_name}
                                fullWidth
                                label="Supplier"
                                name="company_name"
                                variant="outlined"
                              />
                            )}
                          />
                        )
                      }
                    </Query>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      // className={cx(classes.date)}
                      id="date"
                      required
                      fullWidth
                      label="Date of Supply"
                      name="supply_date"
                      defaultValue={formState.supply_date}
                      onChange={changeHandler}
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ListItem>
                    <ListItemIcon className={classes.marginList}>
                      {sporestriction === 'ftc'
                        ? 'Supplier POs Bound to Third Party Cost'
                        : sporestriction === 'oneftc'
                        ? 'Supplier POs Bound to One FTC'
                        : sporestriction === 'budget'
                        ? 'Supplier POs Bound to budget'
                        : sporestriction === 'open'
                        ? 'Supplier Po'
                        : '...'}
                    </ListItemIcon>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className={classes.fullProgress}>
                      <LinearProgress
                        className={classes.widthProgress}
                        // (updatedtotal_costs * 100) / updatedtotal_allocated
                        value={(updatedSPOtotal * 100) / spolimit}
                        variant="determinate"
                      />
                    </ListItemIcon>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon className={classes.marginList}>
                      {currencyformatter.format(updatedSPOtotal) +
                        ' of ' +
                        currencyformatter.format(spolimit)}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.txtRight}
                      primary={
                        currencyformatter.format(spolimit - updatedSPOtotal) +
                        ' left'
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              {/* <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={submitPO}
        >
          Save
        </Button> */}
              {errorallert && (
                <Alert severity="error" onClose={hideError}>
                  <AlertTitle>{errorallert}</AlertTitle>
                </Alert>
              )}
              {askareyousure ? (
                <button
                  onClick={submitPO}
                  id="button"
                  className={`btn-animate ${isloading ? 'onclic' : null} ${
                    !isloading && completed ? 'validate' : null
                  } ${iserror ? 'novalidate' : null}`}
                ></button>
              ) : (
                <button
                  onClick={ask}
                  id="ask"
                  className={`btn-animate ask`}
                ></button>
              )}
            </Box>
            <Divider />
          </CardContent>
        )}
        {job.supplier_po.length > 0 && (
          <CardContent>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier PO</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Anvoiced Amount</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Supplier</TableCell>
                    <TableCell>PO by</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {job.supplier_po.map(po => (
                    <Row key={po._id} row={po} masterJob={masterJob} />
                  ))}
                  {/* <Query query={SupplierPOs}>
                {({ loading, data }) =>
                  !loading && (
                    <>
                      {data.Jobs[0].supplier_po.map(po => (
                        <Row key={po._id} row={po} />
                      ))}
                    </>
                  )
                }
              </Query> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        )}
      </Collapse>
    </Card>
  );
};

export default SupplierPoDetails;
