import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert as MuiAlert } from '@mui/material';

// Create the Alert Context
const AlertContext = createContext();

let showAlertInstance;

// Hook to use the Alert Context
export const useAlert = () => {
  return useContext(AlertContext);
};

// AlertProvider component to provide alert state and methods
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const showAlert = (message, severity = 'error') => {
    setAlert({ message, severity });
  };

  const clearAlert = () => {
    setAlert(null);
  };

  // Assign the instance to the global variable
  showAlertInstance = showAlert;

  return (
    <AlertContext.Provider value={{ alert, showAlert, clearAlert }}>
      {children}
      <Snackbar
        open={!!alert}
        autoHideDuration={6000}
        onClose={clearAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {alert && (
          <MuiAlert
            onClose={clearAlert}
            severity={alert.severity}
            elevation={6}
            variant="filled"
          >
            {alert.message}
          </MuiAlert>
        )}
      </Snackbar>
    </AlertContext.Provider>
  );
};

// Export the showAlert function for direct use
export const showAlert = (message, severity = 'error') => {
  if (showAlertInstance) {
    showAlertInstance(message, severity);
  } else {
    console.error('AlertProvider is not initialized yet.');
  }
};
