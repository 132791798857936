import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box, Drawer, Hidden, List } from '@mui/material';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  CreditCard,
  Star,
  Grid,
  Trello,
  Book,
  Briefcase,
  FilePlus,
  Database
} from 'react-feather';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/Dori.jpg',
  jobTitle: 'Senior Developer',
  name: 'Doriana Ciccarelli'
};

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/users',
    icon: UsersIcon,
    title: 'Users'
  },
  {
    href: '/clients',
    icon: Star,
    title: 'Clients'
  },
  {
    href: '/suppliers',
    icon: Book,
    title: 'Suppliers'
  },
  // {
  //   href: '/departments',
  //   icon: Grid,
  //   title: 'Departments'
  // },
  // {
  //   href: '/ratecards',
  //   icon: Trello,
  //   title: 'Ratecards'
  // },

  {
    href: '#',
    icon: Briefcase,
    title: 'Master Jobs',
    subitems: [
      // {
      //   // href: '/jobs-add-newbiz',
      //   href: '/masterjobs/add/newbiz',
      //   title: 'Add NewBiz'
      // },
      // {
      //   title: 'NewBiz List',
      //   href: '/masterjobs/newbiz'
      // },
      {
        href: '/masterjobs/add/live',
        title: 'Add New Live'
      },
      {
        title: 'Live Master Jobs',
        href: '/masterjobs/live'
      },
      {
        title: 'Completed',
        href: '/masterjobs/completed'
      },
      {
        title: 'Job Requests',
        href: '/jobrequest'
      }
    ]
  },
  // {
  //   href: '/jobs-add-new',
  //   icon: FilePlus,
  //   title: 'Add New Biz'
  // },
  // {
  //   href: '/jobs',
  //   icon: Briefcase,
  //   title: 'Jobs'
  // },
  {
    href: '#',
    icon: Database,
    title: 'Sub Jobs',
    subitems: [
      // {
      //   title: 'NewBiz List',
      //   href: '/subjobs/newbiz'
      // },
      {
        title: 'Live Sub Jobs',
        href: '/subjobs/live'
      },
      {
        title: 'Completed Sub Jobs',
        href: '/subjobs/completed'
      }
    ]
  },
  {
    href: '#',
    icon: CreditCard,
    title: 'Reports',
    subitems: [
      {
        title: 'Rev Rec',
        href: '/revrec'
      },
      {
        title: 'Open POs',
        href: '/open-pos'
      }
    ]
  },
  {
    href: '/account',
    icon: UserIcon,
    title: 'Account'
  }
  // ,
  // {
  //   href: '/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // }
];

const useStyles = makeStyles()(theme => {
  return {
    mobileDrawer: {
      width: 256
    },
    desktopDrawer: {
      width: 200,
      top: 64,
      height: 'calc(100% - 64px)',
      border: 'none',
      borderTopRightRadius: '13px'
    },
    boxList: {
      paddingRight: '0'
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64
    },
    link: {
      color: '#E1E2EB'
    }
  };
});

const NavBar = ({ onMobileClose, openMobile }) => {
  const { classes } = useStyles();
  const location = useLocation();

  const restrictedRoutes = localStorage.getItem('restrictedRoutes');
  const allowedRoutes = localStorage.getItem('allowedRoutes');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2} className={classes.boxList}>
        <List>
          {items.map(item =>
            !restrictedRoutes.includes(item.href) ? (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                className={classes.link}
                subitems={item.subitems}
              />
            ) : null
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
