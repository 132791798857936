import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import PropTypes from 'prop-types';

import { Box, Card, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const Suppliers = gql`
  query SuppliersList($search: supplierListArgs, $ops: functionalArgs) {
    SuppliersList(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        supplier_number
        category
        company_name
        first_name
        last_name
        email
        phone
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  // get Suppliers

  const { data: dataSuppliers, loading, error } = useQuery(Suppliers, {
    variables: { ops: { page: 0 } }
  });

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data</Typography>;
  const columns = [
    {
      accessorKey: 'supplier_number',
      header: 'Supplier No.',
      enableHiding: false
    },
    {
      accessorKey: 'company_name',
      header: 'Company Name',
      enableHiding: false,
      Cell: ({ cell }) => (
        <Box alignItems="center" display="flex">
          <Typography color="textPrimary" variant="body1">
            <Link to={`/supplier/${cell.row.original.supplier_number}`}>
              {cell.getValue()}
            </Link>
          </Typography>
        </Box>
      )
    },
    {
      accessorKey: 'contact_name',
      header: 'Contact Name',
      enableHiding: true,
      Cell: ({ row }) => {
        const firstName = row.original.first_name || '';
        const lastName = row.original.last_name || '';
        return firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableHiding: true
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      enableHiding: true
    },
    {
      accessorKey: 'category',
      header: 'Category',
      enableHiding: true
    }
  ];

  const data = dataSuppliers?.SuppliersList.data ?? [];

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}></Box>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters
        enableColumnActions={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding
        enablePagination
        initialState={{
          density: 'compact',
          pagination: { pageSize: 25 },
          columnVisibility: {
            phone: false,
            email: false,
            category: false,
            contact_name: false
          }
        }}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
