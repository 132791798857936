import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import gql from 'graphql-tag';

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    addButton: {
      marginBottom: theme.spacing(3)
    }
  };
});

const Toolbar = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box display="flex">
        <Typography color="textPrimary" gutterBottom variant="h3">
          User Rights
        </Typography>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
