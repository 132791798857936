import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  LinearProgress,
  InputAdornment,
  ClickAwayListener,
  Box,
  IconButton
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles()(() => ({
  popper: {
    zIndex: 1300,
    width: 600,
    maxWidth: '90%',
    '& .MuiPaper-root': {
      padding: '16px',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
    }
  },
  paper: {
    padding: 16
  },
  marginList: {
    marginRight: '10px'
  },
  marginListUnits: {
    marginLeft: '10px'
  },
  widthProgress: {
    width: '200px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  updateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  adjustButton: {
    minWidth: '30px',
    width: '30px',
    height: '22px',
    padding: 0
  }
}));

const BudgetTooltip = ({
  value,
  onChange,
  units = [],
  allocatedBudget,
  stage,
  isApproved,
  currencyformatter
}) => {
  const { classes } = useStyles();
  const [error, setError] = useState('');
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [updateValue, setUpdateValue] = useState('0');

  // Update inputValue when value prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleBudgetChange = event => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (newValue === '') {
      onChange(event);
      setError('');
      return;
    }

    const numericValue = parseFloat(newValue);
    if (!Number.isNaN(numericValue)) {
      if (numericValue < allocatedBudget) {
        setError(
          `Budget cannot be less than allocated amount (${currencyformatter.format(
            allocatedBudget
          )})`
        );
      } else {
        setError('');
        onChange({
          ...event,
          target: {
            ...event.target,
            value: numericValue
          }
        });
      }
    } else {
      setError('Please enter a valid number');
    }
  };

  const handleUpdateValueChange = event => {
    const newValue = event.target.value;
    // Prevent values less than 0
    if (parseFloat(newValue) < 0 && newValue !== '') {
      setUpdateValue('0');
    } else {
      setUpdateValue(newValue);
    }
  };

  const handleAdjustBudget = increment => {
    const adjustmentValue = parseFloat(updateValue) || 0;
    if (adjustmentValue === 0) return;

    const currentBudget = parseFloat(inputValue) || 0;
    const newBudget = increment
      ? currentBudget + adjustmentValue
      : currentBudget - adjustmentValue;

    // Check if the new budget is valid
    if (newBudget < allocatedBudget) {
      setError(
        `Budget cannot be less than allocated amount (${currencyformatter.format(
          allocatedBudget
        )})`
      );
      return;
    }

    // Update the input value
    setInputValue(newBudget.toString());
    setError(''); // Clear any previous error

    // Call the parent's onChange handler with the new valid value
    onChange({
      target: {
        name: 'total_budget',
        value: newBudget
      }
    });

    setUpdateValue('0');
  };

  const handleClickAway = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleFocus = () => {
    setOpen(true);
    setIsEditing(true);
    setInputValue(value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    setOpen(false);

    // Clear error if current value is valid
    const numericValue = parseFloat(inputValue);
    if (!Number.isNaN(numericValue) && numericValue >= allocatedBudget) {
      setError('');
    }
  };

  const currentValue = parseFloat(value) || 0;
  const remainingBudget = currentValue - allocatedBudget;

  return (
    <div ref={containerRef}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
        <TextField
          fullWidth
          required
          name="total_budget"
          label={isApproved ? 'Total Budget' : 'Estimated Budget'}
          value={
            isEditing ? inputValue : currencyformatter.format(currentValue)
          }
          onChange={handleBudgetChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={!!error}
          helperText={error}
          variant="outlined"
          type={isEditing ? 'number' : 'text'}
          InputProps={
            isEditing
              ? {
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      £
                    </InputAdornment>
                  ),
                  inputProps: {
                    step: parseFloat(updateValue) || 1
                  }
                }
              : {
                  inputProps: {
                    step: parseFloat(updateValue) || 1
                  }
                }
          }
          disabled={stage === 'complete'}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Update Budget By"
            name="update_budget"
            value={updateValue}
            onChange={handleUpdateValueChange}
            variant="outlined"
            type="number"
            disabled={stage === 'complete'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 0 }}>
                  £
                </InputAdornment>
              )
            }}
            sx={{ width: '150px' }}
          />

          <Box className={classes.buttonContainer} sx={{ ml: 1 }}>
            <IconButton
              size="small"
              className={classes.adjustButton}
              onClick={() => handleAdjustBudget(true)}
              disabled={stage === 'complete'}
            >
              <AddIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              className={classes.adjustButton}
              onClick={() => handleAdjustBudget(false)}
              disabled={stage === 'complete'}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Popper
        open={open}
        anchorEl={containerRef.current}
        placement="bottom-start"
        className={classes.popper}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paper} elevation={4}>
            <List>
              {units.length > 0 && (
                <>
                  <ListItem>
                    <ListItemIcon className={classes.marginList}>
                      Allocated Budget for Sub Jobs
                    </ListItemIcon>
                  </ListItem>
                  {units
                    .filter(item => item.jobunit_ref[0].job_type === 'Normal')
                    .map((item, index) => (
                      <div key={index} className={classes.marginListUnits}>
                        <ListItem>
                          <ListItemIcon className={classes.marginList}>
                            {item.jobunit_ref[0].title}
                          </ListItemIcon>
                          <ListItemText
                            primary={currencyformatter.format(
                              item.allocated_budget
                            )}
                          />
                          <ListItemSecondaryAction>
                            <LinearProgress
                              className={classes.widthProgress}
                              value={
                                (item.allocated_budget * 100) / currentValue
                              }
                              variant="determinate"
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    ))}
                  <Divider component="li" />
                </>
              )}

              <ListItem>
                <ListItemIcon className={classes.marginList}>
                  Remaining Budget
                </ListItemIcon>
                <ListItemText
                  primary={currencyformatter.format(remainingBudget)}
                />
                <ListItemSecondaryAction>
                  <LinearProgress
                    className={classes.widthProgress}
                    value={(remainingBudget * 100) / currentValue}
                    variant="determinate"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default BudgetTooltip;
