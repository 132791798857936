import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Profile = ({ className, element, ...rest }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);
  const titleRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (titleRef.current) {
        const titlePosition = titleRef.current.getBoundingClientRect();
        setIsSticky(titlePosition.top <= 50);
      }
    };

    const updateOffset = () => {
      if (titleRef.current) {
        const titlePosition = titleRef.current.getBoundingClientRect();
        setLeftOffset(titlePosition.left);
      }
    };

    // Initial position calculation
    updateOffset();

    // Update on scroll
    window.addEventListener('scroll', handleScroll);
    // Update on resize
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateOffset);
    };
  }, []);

  return (
    <>
      {/* Fixed header */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: {
            xs: '200px', // Default for screens smaller than 1200px
            lg: `${leftOffset}px` // For screens 1200px and larger
          },
          zIndex: 1100,
          padding: '16px 0', // Remove horizontal padding
          transform: isSticky ? 'translateY(0)' : 'translateY(-100%)',
          visibility: isSticky ? 'visible' : 'hidden'
        }}
      >
        <Typography
          color="textPrimary"
          variant="h3"
          sx={{
            left: `${leftOffset}px`, // Apply the same left offset
            paddingRight: '24px'
          }}
        >
          {element[0].title}
        </Typography>
      </Box>

      <Card
        className={className}
        sx={{
          backgroundColor: 'transparent',
          pb: 0
        }}
        {...rest}
      >
        <CardContent sx={{ pb: '10px!important' }}>
          <Box alignItems="start" display="flex" flexDirection="column">
            {/* Original title that will be tracked for scroll position */}
            <Typography
              ref={titleRef}
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {element[0].title}
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href={'/masterjobs/' + element[0].stage}>
                Jobs
              </Link>
              <Link
                color="inherit"
                href={'/masterjob/' + element[0].job_number.split('-', 1)}
              >
                {element[0].container_title}
              </Link>
              <Typography aria-current="page" color="textPrimary">
                {element[0].title}
              </Typography>
            </Breadcrumbs>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  element: PropTypes.array.isRequired
};

export default Profile;
