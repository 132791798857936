import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';
import Toolbar from './Toolbar';
//---------------------------------------------------------------------------------
//
//---------------------------------------------------------------------------------
//
//
//
//
//
const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const AddSupplier = () => {
  const { classes } = useStyles();
  const clientId = useParams();

  return (
    <Page className={classes.root} title="New Suppliers">
      <Container maxWidth="lg">
        <Toolbar />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={6} xs={12}>
              <ProfileDetails />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default AddSupplier;
