import * as React from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
}));

export default function WeekSelector2({ dateChanged }) {
  const [value, setValue] = React.useState(moment());

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }
    const start = moment(value).weekday(0);
    const end = moment(value).weekday(6);

    const dayIsBetween = moment(date).isBetween(start, end, 'day', '[]');
    const isFirstDay = moment(date).isSame(start, 'day');
    const isLastDay = moment(date).isSame(end, 'day');

    // const dayIsBetween = moment(date).isBetween(start, end, null, '[]');
    // const isFirstDay = moment(value).isSame(start);
    // const isLastDay = moment(value).isSame(end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        displayStaticWrapperAs="desktop"
        label="Week picker"
        value={moment(value).isoWeekday('Monday')}
        onChange={newValue => {
          const nv = moment(newValue)
            .isoWeekday('Monday')
            .toDate();
          setValue(nv);
          dateChanged(nv);
        }}
        renderDay={renderWeekPickerDay}
        renderInput={params => <TextField {...params} fullWidth />}
        // inputFormat="DD/MM/Y"
        inputFormat="\W\e\e\k \C\o\m\m\e\n\c\i\n\g - MMM D"
      />
    </LocalizationProvider>
  );
}
