import React from 'react';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const Users = gql`
  query UsersPage {
    UsersPage {
      _id
      email
      first_name
      last_name
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  const { loading, data: dataUsers, error } = useQuery(Users);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data</Typography>;

  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      accessorFn: row => `${row.first_name} ${row.last_name}`,
      sortingFn: 'alphanumeric',
      Cell: ({ row }) => (
        <Box alignItems="center" display="flex">
          <Avatar className={classes.avatar} src={row.original.avatarUrl}>
            {getInitials(row.original.first_name)}
          </Avatar>
          <Typography color="textPrimary" variant="body1">
            <Link to={`/user/${row.original._id}`}>
              {`${row.original.first_name} ${row.original.last_name}`}
            </Link>
          </Typography>
        </Box>
      )
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableHiding: true
    }
  ];

  const data = dataUsers?.UsersPage ?? [];

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnFilters
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding
          enablePagination
          initialState={{
            density: 'compact',
            pagination: { pageSize: 25 },
            columnVisibility: {
              email: true
            }
          }}
        />
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
