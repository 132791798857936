import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const ReadMoreLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'none',
  marginLeft: theme.spacing(1),
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const ReadMore = ({ children, maxCharacters }) => {
  const [expanded, setExpanded] = useState(false);

  let showEllipsis = false;
  let truncatedText = `...`;
  if (children) {
    showEllipsis = children.length > maxCharacters;
    truncatedText = `${children.substring(0, maxCharacters)}...`;
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {expanded ? children : showEllipsis ? truncatedText : children}
      {showEllipsis && (
        <ReadMoreLink onClick={toggleExpanded}>
          {expanded ? 'Read less' : 'Read more'}
        </ReadMoreLink>
      )}
    </>
  );
};

export default ReadMore;
