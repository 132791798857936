import React from 'react';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const JobsContainers = gql`
  query JobsContainersList($search: JobContainerArgs, $ops: functionalArgs) {
    JobsContainersList(search: $search, ops: $ops) {
      info {
        total_records
        restricted_fields
      }
      data {
        _id
        job_number
        title
        total_budget
        stage
        client {
          client_name
        }
        units {
          _id
        }

        remaining
        total_client_pos
        invoiced
        remaining_to_invoice
      }
    }
  }
`;

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, stagevalue, ...rest }) => {
  const { classes, cx } = useStyles();

  const { loading, data, error } = useQuery(JobsContainers, {
    variables: {
      search: { stage: stagevalue },
      ops: { page: 0 }
    },
    fetchPolicy: 'no-cache'
  });

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data</Typography>;

  const currencyformatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0
  });

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ cell, row }) => (
        <Box alignItems="center" display="flex">
          <Avatar className={classes.avatar}>
            {getInitials(cell.getValue())}
          </Avatar>
          <Typography color="textPrimary" variant="body1">
            <Link to={`/masterjob/${row.original.job_number}`}>
              {cell.getValue()}
            </Link>
          </Typography>
        </Box>
      )
    },
    {
      accessorKey: 'job_number',
      header: 'Job Numer'
    },
    {
      accessorKey: 'client.client_name',
      header: 'Client'
    },
    {
      accessorKey: 'total_budget',
      header: 'Total Budget',
      Cell: ({ cell }) => currencyformatter.format(cell.getValue())
    },
    {
      accessorKey: 'total_client_pos',
      header: 'Total Client POs',
      Cell: ({ cell }) => currencyformatter.format(cell.getValue())
    },
    {
      accessorKey: 'invoiced',
      header: 'Total Invoiced',
      Cell: ({ cell }) => currencyformatter.format(cell.getValue())
    },
    {
      accessorKey: 'remaining_to_invoice',
      header: 'Remaining to Invoice',
      Cell: ({ cell }) => currencyformatter.format(cell.getValue())
    },
    //remaining
    ...(stagevalue === 'live'
      ? [
          {
            accessorKey: 'remaining',
            header: 'Remaining Budget',
            Cell: ({ cell, row }) => (
              <Box>
                {currencyformatter.format(cell.getValue())}
                <LinearProgress
                  value={(cell.getValue() * 100) / row.original.total_budget}
                  variant="determinate"
                />
              </Box>
            )
          }
        ]
      : []),
    {
      accessorKey: 'units',
      header: 'Sub Jobs',
      Cell: ({ cell }) => cell.getValue().length
    }
  ];

  const tableData = data?.JobsContainersList.data ?? [];

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableColumnFilters
        enableColumnActions={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding
        initialState={{ density: 'compact', pagination: { pageSize: 25 } }}
        enablePagination
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  stagevalue: PropTypes.string
};

export default Results;
