import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/lab/Alert';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AuthContext from 'src/components/AuthContext';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import GoogleDriveUpload from '../../../components/googleComponents/GoogleDriveUpload';
import GoogleDriveDeleteIcon, {
  GoogleDeleteFile
} from '../../../components/googleComponents/GoogleDriveDeleteIcon';

// Queries
const addJobContainerSubDocs = gql`
  mutation addJobContainerSubDocs(
    $ref: ID!
    $amount: Float!
    $attachment: String
    $description: String!
    $client_po_number: String!
  ) {
    addJobContainerSubDocs(
      ref: $ref
      subdoc: "clientpo"
      data: {
        clientPoInput: {
          amount: $amount
          attachment: $attachment
          description: $description
          client_po_number: $client_po_number
        }
      }
    ) {
      _id
    }
  }
`;

const updateClientPO = gql`
  mutation($ref: ID!, $data: ClientPoInput!) {
    updateMJClientPo(ref: $ref, data: $data) {
      client_pos {
        _id
      }
    }
  }
`;

const removeClientPO = gql`
  mutation($ref: ID!) {
    removeMJClientPo(ref: $ref) {
      client_pos {
        _id
      }
    }
  }
`;

const ClientPOs = gql`
  query JobsContainers($id: ID) {
    JobsContainers(_id: $id) {
      client_pos {
        _id
        client_po_number
        amount
        description
        attachment
      }
    }
  }
`;
// Styles
const useStyles = makeStyles()(theme => {
  return {
    deleteUpload: {
      float: 'left'
    },
    uploadFile: {
      float: 'left',
      lineHeight: '2.2em',
      marginLeft: '10px'
    },
    uploadedPO: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'auto',
      maxWidth: '15vw',
      display: 'inherit',
      textDecoration: 'underline'
    }
  };
});

// Component
const JobClientPOs = ({ className, job, ...rest }) => {
  const { classes } = useStyles();
  const { userData } = useContext(AuthContext);

  const [file, setFile] = useState();
  const [fileMissing, setFileMissing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [editPO, setEditPO] = useState(false);
  const [delPO, setDelPO] = useState(false);
  const [amountError, setAmountError] = useState('');

  const [opendialog, setOpendialog] = useState(false);
  const [openDel, setOpenDel] = useState(false);

  const [POsList, setPOsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.JobClientPOs === false ? false : true
  );

  const defaultFormState = {
    client_po_number: '',
    amount: '',
    description: '',
    attachment: ''
  };

  const [formState, setFormState] = useState(defaultFormState);

  // Currency formatter for consistent display
  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  });

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const ClientPOsResults = useQuery(ClientPOs, {
    variables: { id: job._id },
    onCompleted: data => {
      console.log('Query completed, data:', data);
    }
  });

  useEffect(() => {
    if (ClientPOsResults.data && ClientPOsResults.data.JobsContainers) {
      const posList = ClientPOsResults.data.JobsContainers[0].client_pos;
      setPOsList(posList);

      // Calculate total amount
      if (posList && posList.length > 0) {
        const total = posList.reduce(
          (sum, po) => sum + parseFloat(po.amount || 0),
          0
        );
        setTotalAmount(total);
      } else {
        setTotalAmount(0);
      }
    }
  }, [ClientPOsResults]);

  const handleClickOpendialog = po => {
    setAmountError('');
    if (po?._id) {
      setEditPO(true);
      setFormState({
        client_po_number: po.client_po_number,
        amount: po.amount,
        description: po.description,
        _id: po._id
      });

      // Safely parse attachment JSON if it exists
      if (po.attachment) {
        try {
          const attachmentData = JSON.parse(po.attachment);
          setFile(attachmentData);
        } catch (error) {
          console.error('Error parsing attachment JSON:', error);
          setFile(null);
        }
      } else {
        setFile(null);
      }
    } else {
      setFormState(defaultFormState);
      setEditPO(false);
      setFile(null);
    }
    setOpendialog(true);
  };

  const handleClosedialog = () => {
    setOpendialog(false);
    setFileMissing(false);
    setErrorMsg('');
    setAmountError('');
  };

  const handleClickOpenDel = id => {
    setDelPO(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setDelPO(false);
    setOpenDel(false);
  };

  const handleYesDel = () => {
    if (delPO.attachment) {
      try {
        const fileID = JSON.parse(delPO.attachment).id;
        GoogleDeleteFile(fileID);
      } catch (error) {
        console.error('Error parsing attachment for deletion:', error);
      }
    }
    delete_JobContainerPO({
      variables: {
        ref: delPO._id
      }
    });
    setOpenDel(false);
  };

  const [delete_JobContainerPO] = useMutation(removeClientPO, {
    onCompleted() {
      handleCloseDel();
      ClientPOsResults.refetch();
      setFileMissing(false);
      setErrorMsg('');
    },
    onError: err => {
      const gqlerr = String(err).replace('Error: GraphQL error: ', '');
      setFileMissing(true);
      setErrorMsg(gqlerr);
    }
  });

  const uploadCallbackFunction = file => {
    const fileOb = {
      id: file.id,
      name: file.name,
      link: file.webViewLink
    };
    setFile(fileOb);
    setFileMissing(false);
    setErrorMsg('');

    setFormState({ ...formState, attachment: JSON.stringify(fileOb) });
  };

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;

    // Validate amount if it's being changed
    if (nam === 'amount') {
      const numVal = parseFloat(val);
      // Clear previous error
      setAmountError('');

      if (!Number.isNaN(numVal)) {
        // Calculate what the new total would be
        let newTotal = totalAmount;

        // If editing an existing PO, subtract its current amount first
        if (editPO) {
          const currentPO = POsList.find(po => po._id === formState._id);
          if (currentPO) {
            newTotal -= parseFloat(currentPO.amount || 0);
          }
        }

        // Add the new amount
        newTotal += numVal;

        // Check if total POs would exceed budget
        if (newTotal > job.total_budget) {
          setAmountError(
            `Total PO amount (${currencyFormatter.format(
              newTotal
            )}) would exceed total budget (${currencyFormatter.format(
              job.total_budget
            )})`
          );
        }
      }
    }

    setFormState({ ...formState, [nam]: val });
  };

  const updatePO = e => {
    e.preventDefault();

    // Don't proceed if there's an amount error
    if (amountError) {
      return;
    }

    if (formState.description && formState.amount) {
      // Double check budget constraint before update
      const newAmount = parseFloat(formState.amount);
      const currentPO = POsList.find(po => po._id === formState._id);
      const currentAmount = currentPO ? parseFloat(currentPO.amount || 0) : 0;
      const otherPOsTotal = totalAmount - currentAmount;

      if (otherPOsTotal + newAmount > job.total_budget) {
        setAmountError(
          `Total PO amount would exceed total budget (${currencyFormatter.format(
            job.total_budget
          )})`
        );
        return;
      }

      update_JobContainerPO({
        variables: {
          ref: formState._id,
          data: {
            amount: newAmount,
            attachment: formState.attachment,
            description: formState.description,
            client_po_number: formState.client_po_number
          }
        }
      });
    } else {
      setFileMissing(true);
      setErrorMsg('Please fill all required fields');
    }
  };

  const [update_JobContainerPO] = useMutation(updateClientPO, {
    onCompleted() {
      ClientPOsResults.refetch();
      setOpendialog(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
      setFileMissing(true);
      setErrorMsg(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const submitPO = e => {
    e.preventDefault();

    // Don't proceed if there's an amount error
    if (amountError) {
      return;
    }

    if (
      formState.description &&
      formState.amount &&
      formState.client_po_number
    ) {
      // Double check budget constraint before submission
      const newAmount = parseFloat(formState.amount);
      if (totalAmount + newAmount > job.total_budget) {
        setAmountError(
          `Total PO amount would exceed total budget (${currencyFormatter.format(
            job.total_budget
          )})`
        );
        return;
      }

      add_JobContainerPO({
        variables: {
          ref: job._id,
          amount: newAmount,
          attachment: formState.attachment,
          description: formState.description,
          client_po_number: formState.client_po_number
        }
      });
    } else {
      setFileMissing(true);
      setErrorMsg('Please fill all required fields');
    }
  };

  const [add_JobContainerPO] = useMutation(addJobContainerSubDocs, {
    onCompleted() {
      ClientPOsResults.refetch();
      setOpendialog(false);
    },
    onError: err => {
      setFileMissing(true);
      setErrorMsg(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const removeFile = () => {
    setFile('');
    setFormState({ ...formState, attachment: null });
  };

  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.JobClientPOs = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Client POs"
          action={
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickOpendialog}
              >
                Add new PO
              </Button>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </>
          }
        ></CardHeader>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PO Number</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>PO Document</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell colSpan={2}>Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {POsList &&
                  POsList.map(po => (
                    <TableRow hover key={po._id}>
                      <TableCell>{po.client_po_number}</TableCell>
                      <TableCell>
                        {currencyFormatter.format(po.amount)}
                      </TableCell>
                      <TableCell>
                        {po.attachment
                          ? (() => {
                              try {
                                const attachmentData = JSON.parse(
                                  po.attachment
                                );
                                return (
                                  <a
                                    href={attachmentData.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.uploadedPO}
                                  >
                                    {attachmentData.name}
                                  </a>
                                );
                              } catch (error) {
                                return 'Invalid attachment data';
                              }
                            })()
                          : 'No file attached'}
                      </TableCell>
                      <TableCell>{po.description}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={() => handleClickOpendialog(po)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell>
                        <DeleteIcon
                          onClick={() => handleClickOpenDel(po)}
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>Total Amount</TableCell>
                  <TableCell colSpan={5}>
                    {currencyFormatter.format(totalAmount)}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </CardContent>
        </Collapse>
      </Card>

      <form autoComplete="off">
        <Dialog
          open={opendialog}
          onClose={handleClosedialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
        >
          <DialogTitle id="alert-dialog-title">
            {editPO ? 'Edit PO' : 'Add PO'}
          </DialogTitle>
          <DialogContent>
            {fileMissing && <Alert severity="error">{errorMsg}</Alert>}
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    {!file ? (
                      <GoogleDriveUpload
                        uploadCallback={file => uploadCallbackFunction(file)}
                        buttonName="Upload&nbsp;PO"
                        folderName={`POs/${job.job_number}`}
                      />
                    ) : (
                      <div className={classes.deleteUpload}>
                        <GoogleDriveDeleteIcon
                          file={file}
                          response={removeFile}
                        />
                      </div>
                    )}
                    {file && (
                      <div className={classes.uploadFile}>
                        <a href={file.link} target="_blank" rel="noreferrer">
                          {file.name}
                        </a>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      label="PO Number"
                      name="client_po_number"
                      variant="outlined"
                      value={formState.client_po_number}
                      onChange={changeHandler}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      type="number"
                      value={formState.amount}
                      onChange={changeHandler}
                      error={!!amountError}
                      helperText={amountError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <TextField
                      fullWidth
                      required
                      label="Description"
                      name="description"
                      variant="outlined"
                      value={formState.description}
                      onChange={changeHandler}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosedialog} color="primary">
              Close
            </Button>
            <Button
              onClick={editPO ? updatePO : submitPO}
              color="primary"
              autoFocus
              variant="contained"
              disabled={!!amountError}
            >
              {editPO ? 'Update' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <Dialog
        open={openDel}
        onClose={handleCloseDel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete PO No{' '}
            <strong>{delPO.client_po_number}</strong>
            {delPO.attachment && (
              <>
                <br />
                and{' '}
                <strong>
                  {(() => {
                    try {
                      return JSON.parse(delPO.attachment).name;
                    } catch (error) {
                      return 'attachment';
                    }
                  })()}
                </strong>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDel} color="primary" autoFocus>
            No
          </Button>
          <Button onClick={handleYesDel} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobClientPOs;
