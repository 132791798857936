import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Page from 'src/components/Page';
import Profile from './JobProfile';
import ProfileDetails from './ProfileDetails';
import TeamDetails from './TeamDetails';
import TimesheetDetails from './TimesheetDetails';
import FixedTaskCostInputDetails from './FixedTaskCostInputDetails';
import SupplierPoDetails from './SupplierPoDetails';
import TimeEstimateDetails from './TimeEstimateDetails';
import BudgetAllocationDetails from './BudgetAllocationDetails';

import Toolbar from './Toolbar';
import CloudPortal from './CloudPortal';

const Jobs = gql`
  query Jobs($job_number: String) {
    Jobs(job_number: $job_number) {
      _id
      subscription_key
      title
      job_number
      url
      description
      cloud_portal
      job_type
      retainer_budget
      retained_jobs
      allotted_budget
      container_ref
      container_title
      stage
      department
      start_date
      end_date
      owner_reference
      owner_name
      ratecard_reference
      time_estimate_switch
      timesheet_switch
      spo_limit
      spo_switch
      spo_restriction
      supplier_po_total
      is_pass_through
      total_budget_allocation
      estimated_time
      estimated_ftc
      available_for_estimate

      team {
        _id
        user_reference
        user_name
        email
        designation
        jobs_designation
        department_name
      }
      fixed_task_cost {
        _id
        title
        cost
        created
        user_reference
        user_name
        ftc_number
        ftc_for_supplier
      }
      budget_allocation {
        _id
        department_name
        note
        created
        user_reference
        user_name
        amount
      }
      time_estimate {
        _id
        department_name
        role
        rate
        hours
        note
        created
        user_reference
        user_name
        amount
      }
      supplier_po {
        _id
        supplier_po
        amount
        description
        suppliers_reference
        company_name
        po_by_reference
        po_by_name
        created_date
        approved
        approved_by_reference
        approved_by_name
        approved_date
        emailed
        email_date
        spo_status
        supply_date
        completed_date
        invoiced_amount
        ftc_title
        ftc_number
        invoices {
          _id
          amount
          description
          invoice
          invoice_by_name
          invoice_date
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(1.5)
    }
  };
});

const Account = () => {
  const { classes } = useStyles();
  const elementId = useParams();
  const navigate = useNavigate();

  const [jobstage, setJobstage] = useState('');
  const [jobBudget, setJobBudget] = useState('');
  const [timesheetswitch, setTimesheetswitch] = useState(false);
  const [time_estimate_switch, setTime_estimate_switch] = useState(false);
  const [sposwitch, setSposwitch] = useState(false);
  const [ptcswitch, setPtcswitch] = useState(false);
  const [sporestriction, setSporestriction] = useState(false);

  const { data, loading } = useQuery(Jobs, {
    variables: { job_number: elementId.id }
  });

  useEffect(() => {
    if (!loading && (!data || !data.Jobs || data.Jobs.length === 0)) {
      navigate(`/subjobs/live?jobNotFound=${elementId.id}`);
      return;
    }

    const onCompleted = data => {
      setJobstage(data.Jobs[0].stage);
      setJobBudget(data.Jobs[0].allotted_budget);
      setTimesheetswitch(data.Jobs[0].timesheet_switch);
      setTime_estimate_switch(data.Jobs[0].time_estimate_switch);
      setSposwitch(data.Jobs[0].spo_switch);
      setPtcswitch(data.Jobs[0].is_pass_through);
      setSporestriction(data.Jobs[0].spo_restriction);
    };

    if (!loading && data) {
      onCompleted(data);
    }
  }, [data, loading, navigate, elementId.id]);

  const sendChildToParent = stage => {
    setJobstage(stage);
  };

  const fromProfileDetails = budget => {
    setJobBudget(budget);
  };

  // c2p : Child to Parent
  const from_c2p_timesheet = switch_value => {
    setTimesheetswitch(switch_value);
  };

  // c2p : Child to Parent
  const from_c2p_timeestimate = switch_value => {
    setTime_estimate_switch(switch_value);
  };

  // c2p : Child to Parent
  const from_c2p_sposwitch = switch_value => {
    setSposwitch(switch_value);
  };
  // c2p : Child to Parent
  const from_c2p_ptcswitch = switch_value => {
    setPtcswitch(switch_value);
  };

  // c2p : Child to Parent
  const from_c2p_sporestriction = value => {
    setSporestriction(value);
  };

  if (loading || !data || !data.Jobs || data.Jobs.length === 0) {
    return null;
  }

  return (
    <Page elevation={0} className={classes.root} title="Sub Job">
      {!loading && data && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Profile element={data.Jobs} />
            </Grid>
            <Grid item xs={4}>
              <Toolbar
                id={data.Jobs[0]._id}
                stage={data.Jobs[0].stage}
                sendChildToParent={sendChildToParent}
              />
            </Grid>
            <Grid item xs={12}>
              <ProfileDetails
                element={data.Jobs}
                stage={jobstage}
                fromProfileDetails={fromProfileDetails}
                from_c2p_timesheet={from_c2p_timesheet}
                from_c2p_timeestimate={from_c2p_timeestimate}
                from_c2p_sposwitch={from_c2p_sposwitch}
                from_c2p_ptcswitch={from_c2p_ptcswitch}
                from_c2p_sporestriction={from_c2p_sporestriction}
              />
            </Grid>

            {(jobstage === 'live' || jobstage === 'complete') && (
              <Grid item xs={12}>
                <BudgetAllocationDetails
                  element={data.Jobs}
                  stage={jobstage}
                  updatedsingleAllocated={jobBudget}
                  time_estimate_switch={time_estimate_switch}
                />
              </Grid>
            )}

            {timesheetswitch === true && (
              <>
                <Grid item xs={12}>
                  <TimesheetDetails element={data.Jobs} stage={jobstage} />
                </Grid>
              </>
            )}

            {(jobstage === 'live' || jobstage === 'complete') && (
              <Grid item xs={12}>
                <FixedTaskCostInputDetails
                  element={data.Jobs}
                  stage={jobstage}
                />
              </Grid>
            )}

            {sposwitch === true && (
              <Grid item xs={12}>
                <SupplierPoDetails
                  job={data.Jobs[0]}
                  jobBudgetstate={jobBudget}
                  ftcTotal={data.Jobs[0].estimated_ftc}
                  sporestriction={sporestriction}
                />
              </Grid>
            )}

            {time_estimate_switch === true &&
              (jobstage === 'live' || jobstage === 'complete') && (
                <Grid item xs={12}>
                  <TimeEstimateDetails
                    element={data.Jobs}
                    stage={jobstage}
                    updatedsingleAllocated={jobBudget}
                    time_estimate_switch={time_estimate_switch}
                  />
                </Grid>
              )}

            <Grid item xs={12}>
              <TeamDetails element={data.Jobs} stage={jobstage} />
            </Grid>

            <Grid item xs={12}>
              <CloudPortal job={data.Jobs[0]} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default Account;
