import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialReactTable from 'material-react-table';
import { Avatar, Box, Card, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const Jobs = gql`
  query JobsList($search: jobListArgs, $ops: functionalArgs) {
    JobsList(search: $search, ops: $ops) {
      info {
        total_records
        restricted_fields
      }
      data {
        _id
        title
        job_number
        allotted_budget
        total_revrec
        total_forecast
        estimated_ftc
        supplier_po_total
        stage
        is_pass_through
        client {
          client_name
        }
        department
        end_date
        start_date
        container_title
        container_ref
        container_job_number
        job_request_from {
          job_request_number
          client_name
          sub_job_number
          sub_job_title
          spo_number
          spo_amount
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const formatCurrency = value => {
  if (value === null || value === undefined) return '-';
  return `£${value}`;
};

const SubResults = ({ className, stagevalue, stageLabel, ...rest }) => {
  const { classes, cx } = useStyles();
  const [restrictedfields, setRestrictedfields] = React.useState('');

  const { data: dataJobs, refetch } = useQuery(Jobs, {
    variables: {
      search: { stage: stagevalue },
      ops: { page: 0, perpage: 10 }
    },
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    if (dataJobs?.JobsList?.info) {
      setRestrictedfields(dataJobs.JobsList.info.restricted_fields);
    }
  }, [dataJobs]);

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row }) => (
        <Box alignItems="center" display="flex">
          <Avatar className={classes.avatar} src={row.original.avatarUrl}>
            {getInitials(row.original.title)}
          </Avatar>
          <Typography color="textPrimary" variant="body1">
            <Link to={`/subjob/${row.original.job_number}`}>
              {row.original.title}
            </Link>
          </Typography>
        </Box>
      ),
      filterFn: 'contains',
      size: 250
    },
    {
      accessorKey: 'job_number',
      header: 'Job no.'
    },
    {
      accessorKey: 'container_title',
      header: 'Master Job',
      Cell: ({ row }) => (
        <Link to={`/masterjob/${row.original.container_job_number}`}>
          {row.original.container_title || '-'}
        </Link>
      ),
      filterFn: 'contains'
    },
    {
      accessorKey: 'client.client_name',
      header: 'Client',
      filterFn: 'contains'
    },
    {
      accessorKey: 'allotted_budget',
      header: 'Budget',
      Cell: ({ cell }) => formatCurrency(cell.getValue()),
      filterFn: 'between'
    },
    {
      accessorKey: 'estimated_ftc',
      header: 'Supplier Costs',
      Cell: ({ cell }) => formatCurrency(cell.getValue()),
      filterFn: 'between'
    },
    {
      accessorKey: 'total_revrec',
      header: 'Rev Rec',
      Cell: ({ cell }) => formatCurrency(cell.getValue()),
      filterFn: 'between'
    },
    {
      accessorKey: 'total_forecast',
      header: 'Forecast',
      Cell: ({ cell }) => formatCurrency(cell.getValue()),
      filterFn: 'between'
    },
    {
      accessorKey: 'supplier_po_total',
      header: 'SPO Total',
      Cell: ({ cell }) => formatCurrency(cell.getValue()),
      filterFn: 'between'
    },
    {
      accessorKey: 'is_pass_through',
      header: 'Is Pass Through',
      Cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No'),
      filterFn: 'equals'
    },
    {
      accessorKey: 'end_date',
      header: 'TX Date',
      Cell: ({ cell }) =>
        cell.getValue()
          ? new Date(parseInt(cell.getValue())).toLocaleDateString()
          : '-',
      filterFn: 'lessThanOrEqualTo'
    },
    {
      accessorKey: 'job_request_from.client_name',
      header: 'Ultimate Client',
      filterFn: 'contains'
    },
    {
      accessorKey: 'job_request_from.sub_job_title',
      header: 'Ultimate SJ',
      filterFn: 'contains'
    }
  ];

  const handlePaginationChange = ({ pageIndex, pageSize }) => {
    refetch({
      search: { stage: stagevalue },
      ops: { page: pageIndex, perpage: pageSize }
    });
  };

  return (
    // <Card elevation={0} className={cx(classes.root, className)} {...rest}>
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <MaterialReactTable
          columns={columns}
          data={dataJobs?.JobsList?.data || []}
          enableColumnFilters
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding
          initialState={{
            density: 'compact',
            pagination: { pageSize: 25 },
            columnVisibility: {
              'job_request_from.client_name': false,
              'job_request_from.sub_job_title': false
            }
          }}
          enablePagination
        />
      </Box>
    </Card>
  );
};

SubResults.propTypes = {
  className: PropTypes.string,
  stagevalue: PropTypes.string
};

export default SubResults;
