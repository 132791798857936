import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AuthContext from 'src/components/AuthContext';

import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation } from '@apollo/client';
import { addJobContainer } from 'src/queries/query';

import TextEditor from '../../../components/TextEditor';

const Client = gql`
  query Client($id: ID) {
    Client(_id: $id) {
      _id
      name
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    }
  };
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileDetails = ({ stage, ...rest }) => {
  console.log(stage);
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const [state, setState] = useState({
    client_ref: ''
  });

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const [error, setError] = useState(null);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [
    add_JobContainer,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addJobContainer, {
    onCompleted: response => {
      if (
        response &&
        response.addJobContainer &&
        response.addJobContainer.job_number
      ) {
        setOpen(false); // Close any existing error messages
        setErrorMessage(''); // Clear any existing error messages
        timer.current = window.setTimeout(() => {
          setIsloading(false);
          setCompleted(true);
        }, 1000);

        timer2.current = window.setTimeout(() => {
          setIsloading(false);
          setCompleted(false);
          setAskareyousure(false);
          navigate('/masterjob/' + response.addJobContainer.job_number);
        }, 2500);
      } else {
        // Handle case where response is missing expected data
        setErrorMessage('Error creating job: Invalid response from server');
        setOpen(true);
        setIsloading(false);
        setIserror(true);
      }
    },
    onError: err => {
      setError(err);
      const errorMessage = err.message.includes('Duplicate Entry')
        ? 'A job with this name already exists'
        : err.message.split('GraphQL error: ')[1] || 'An error occurred';
      setErrorMessage(errorMessage);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [jobDesc, setJobDesc] = useState('');

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_JobContainer({
            variables: {
              ref: '',
              data: {
                title: e.target.title.value,
                description: jobDesc,
                total_budget: parseFloat(e.target.total_budget.value),
                start_date: e.target.start_date.value
                  ? e.target.start_date.value
                  : new Date(),
                end_date: e.target.end_date.value,
                ratecard: null,
                stage: stage,
                client: {
                  client_ref: state.client_ref,
                  client_name: e.target.client_name.value
                }
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            title="Add New Job"
            classes={{
              action: classes.cardAction
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  label="Job title"
                  name="title"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Budget"
                  required
                  name="total_budget"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextEditor textEditorOutput={textEditorOutput} />
              </Grid>

              <Grid item xs={12}>
                <Query query={Client}>
                  {({ loading, data }) =>
                    !loading && (
                      <Autocomplete
                        id="Clientlist"
                        options={data.Client}
                        getOptionLabel={option => option.name}
                        onChange={(event, values) => {
                          if (values) {
                            setState({
                              ...state,
                              client_ref: values._id
                            });
                          } else {
                            setState({
                              ...state,
                              client_ref: ''
                            });
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option._id}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Client"
                            name="client_name"
                            variant="outlined"
                          />
                        )}
                      />
                    )
                  }
                </Query>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete',
                    onFocus: e => {
                      if (stage !== 'complete') {
                        e.target.showPicker?.();
                      }
                    }
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Delivery Date"
                  name="end_date"
                  required
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete',
                    onFocus: e => {
                      if (stage !== 'complete') {
                        e.target.showPicker?.();
                      }
                    }
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
