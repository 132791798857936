import React from 'react';
import { useParams } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import { Client } from 'src/queries/query';
import { makeStyles } from 'tss-react/mui';
import { Container, Grid, Card, CardHeader } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';
import ContactDetails from './ContactDetails';
import Ratecard from './Ratecard';
import Toolbar from './Toolbar';

import Profile from './Profile';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    }
  };
});

const Account = () => {
  const { classes } = useStyles();
  const clientId = useParams();

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Page className={classes.root} title="Client">
      <Query query={Client} variables={{ client_number: clientId.id }}>
        {({ loading, data }) =>
          !loading && (
            <Container maxWidth="lg">
              <Toolbar id={data.Client[0]._id} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Profile element={data.Client} />
                </Grid>
                <Grid item xs={12}>
                  <ProfileDetails element={data.Client} />
                </Grid>
                {/* <Grid item xs={12}>
                  <Ratecard element={data.Client} />
                </Grid> */}

                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Contacts" />
                    {data.Client[0].contacts
                      ? data.Client[0].contacts.map(contact => (
                          <Accordion
                            key={contact._id}
                            expanded={expanded === contact._id}
                            onChange={handleChange(contact._id)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography className={classes.heading}>
                                {contact.first_name} {contact.last_name}
                              </Typography>
                              <Typography className={classes.secondaryHeading}>
                                {contact.email}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ContactDetails
                                id={data.Client[0]._id}
                                clientcontact={contact}
                              />
                            </AccordionDetails>
                          </Accordion>
                        ))
                      : null}
                  </Card>
                </Grid>
              </Grid>
            </Container>
          )
        }
      </Query>
    </Page>
  );
};

export default Account;
