import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialReactTable from 'material-react-table';
import { Avatar, Box, Card, Typography, LinearProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getInitials from 'src/utils/getInitials';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export const JobsContainers = gql`
  query JobsContainersList($search: JobContainerArgs, $ops: functionalArgs) {
    JobsContainersList(search: $search, ops: $ops) {
      info {
        total_records
        restricted_fields
      }
      data {
        _id
        title
        total_budget
        remaining
        stage
        client {
          client_name
        }
        units {
          _id
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      '& .MuiCardContent-root': {
        paddingTop: 0
      }
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const currencyformatter = new Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
  maximumFractionDigits: 0
});

const SubResults = ({ className, stagevalue, ...rest }) => {
  const { classes, cx } = useStyles();
  const [restrictedfields, setRestrictedfields] = React.useState('');

  const { data: dataJobsContainers, refetch } = useQuery(JobsContainers, {
    variables: {
      search: { stage: stagevalue },
      ops: { page: 0, perpage: 10 }
    },
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    if (dataJobsContainers?.JobsContainersList?.info) {
      setRestrictedfields(
        dataJobsContainers.JobsContainersList.info.restricted_fields
      );
    }
  }, [dataJobsContainers]);

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row }) => (
        <Box alignItems="center" display="flex">
          <Avatar className={classes.avatar} src={row.original.avatarUrl}>
            {getInitials(row.original.title)}
          </Avatar>
          <Typography color="textPrimary" variant="body1">
            <Link to={{ pathname: `job/${row.original._id}` }}>
              {row.original.title}
            </Link>
          </Typography>
        </Box>
      ),
      size: 250
    },
    {
      accessorKey: 'client.client_name',
      header: 'Client'
    },
    {
      accessorKey: 'total_budget',
      header: 'Total Budget',
      Cell: ({ cell }) => currencyformatter.format(cell.getValue() || 0)
    },
    ...(stagevalue === 'live'
      ? [
          {
            accessorKey: 'remaining',
            header: 'Remaining Budget',
            Cell: ({ row }) => (
              <Box>
                {currencyformatter.format(row.original.remaining || 0)}
                <LinearProgress
                  value={
                    (row.original.remaining * 100) / row.original.total_budget
                  }
                  variant="determinate"
                  sx={{ mt: 1 }}
                />
              </Box>
            )
          }
        ]
      : []),
    {
      accessorKey: 'units',
      header: 'Units',
      Cell: ({ cell }) => cell.getValue()?.length || 0
    }
  ];

  const handlePaginationChange = ({ pageIndex, pageSize }) => {
    refetch({
      search: { stage: stagevalue },
      ops: { page: pageIndex, perpage: pageSize }
    });
  };

  return (
    <Card elevation={0} className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <MaterialReactTable
          columns={columns}
          data={dataJobsContainers?.JobsContainersList?.data || []}
          enableColumnFilters
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding
          initialState={{ density: 'compact', pagination: { pageSize: 25 } }}
          enablePagination
        />
      </Box>
    </Card>
  );
};

SubResults.propTypes = {
  className: PropTypes.string,
  stagevalue: PropTypes.string
};

export default SubResults;
