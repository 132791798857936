import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialReactTable from 'material-react-table';
import { Box, Card, CardContent, Checkbox, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AuthContext from 'src/components/AuthContext';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import TextEditor from 'src/components/TextEditor';

const TasksList = gql`
  query Tasks($assigned_to_ref: String!) {
    Tasks(assigned_to_ref: $assigned_to_ref) {
      _id
      subscription_key
      title
      description
      collection_name
      collection_ref
      assigned_to_ref
      assigned_to_name
      assigned_by_ref
      assigned_by_name
      collection_title
      due_by
      completed_date
      status
    }
  }
`;

const updateTask = gql`
  mutation addTask($ref: ID!, $status: String, $completed_date: String) {
    updateTask(
      ref: $ref
      data: { status: $status, completed_date: $completed_date }
    ) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {
      height: '100%'
    },
    actions: {
      justifyContent: 'flex-end'
    },
    date: {
      width: 'fit-content',
      backgroundColor: '#f4f6fc',
      padding: '5px 15px',
      borderRadius: '5px',
      display: 'table-caption',
      textAlign: 'center',
      fontWeight: '500',
      lineHeight: '1.1em'
    },
    link: {
      fontWeight: '600',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    }
  };
});

const Tasks = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const [taskList, setTaskList] = useState([]);
  const { userData } = useContext(AuthContext);

  const TasksResults = useQuery(TasksList, {
    variables: {
      assigned_to_ref: userData._id
    },
    fetchPolicy: 'no-cache'
  });

  const [update_Task] = useMutation(updateTask, {
    onCompleted() {
      TasksResults.refetch();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  useEffect(() => {
    if (TasksResults.data) {
      const pendingTasks = TasksResults.data.Tasks.filter(
        task => task.status !== 'Completed'
      );
      setTaskList(pendingTasks);
    }
  }, [TasksResults]);

  const handleCheck = item => {
    let status = 'Pending';
    if (item.status !== 'Completed') status = 'Completed';
    const data = {
      ref: item._id,
      status: status,
      completed_date: moment()
    };
    update_Task({
      variables: data
    });
  };

  const columns = [
    {
      accessorKey: 'due_by',
      header: 'Due Date',
      Cell: ({ row }) => (
        <Box className={classes.date}>
          {moment.unix(row.original.due_by / 1000).format('MMM DD')}
        </Box>
      ),
      size: 100
    },
    {
      accessorKey: 'title',
      header: 'Task',
      Cell: ({ row }) => (
        <Box>
          {/* <Link
            to={`/jobunits/jobunit/${row.original.collection_ref}`}
            className={classes.link}
          > */}
          {row.original.collection_title}
          {/* </Link> */}
          <TextEditor
            textEditorInput={row.original.title}
            isInput={false}
            canEdit={false}
            size={'small'}
          />
        </Box>
      ),
      size: 300
    },
    {
      accessorKey: 'assigned_by_name',
      header: 'Assigned by',
      size: 150
    },
    {
      accessorKey: 'status',
      header: 'Complete',
      Cell: ({ row }) => (
        <Checkbox
          edge="start"
          checked={row.original.status === 'Completed'}
          tabIndex={-1}
          disableRipple
          onClick={() => handleCheck(row.original)}
        />
      ),
      size: 100
    }
  ];

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box>
        <MaterialReactTable
          renderTopToolbarCustomActions={() => (
            <Typography variant="h5" component="div" sx={{ pt: 1 }}>
              Tasks
            </Typography>
          )}
          columns={columns}
          data={taskList}
          enableColumnFilters
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding
          initialState={{
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 10 },
            showColumnFilters: false
          }}
        />
      </Box>
    </Card>
  );
};

Tasks.propTypes = {
  className: PropTypes.string
};

export default Tasks;
