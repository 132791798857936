import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';

import ClientListView from 'src/views/client/ClientListView';
import SingleClientView from 'src/views/client/SingleClientView';
import AddClientView from 'src/views/client/AddClientView';
import AddClientContactView from 'src/views/client/AddClientContactView';

import UserListView from 'src/views/user/UserListView';
import SingleUserView from 'src/views/user/SingleUserView';
import AddUserView from 'src/views/user/AddUserView';

import DepartmentListView from 'src/views/department/DepartmentListView';
import SingleDepartmentView from 'src/views/department/SingleDepartmentView';
import AddDepartmentView from 'src/views/department/AddDepartmentView';

import RevrecListView from 'src/views/revrec/RevrecListView';
// import BARListView from 'src/views/budgetallocationreport/BARListView';

import JobListView from 'src/views/job/JobListView';
import MasterJobListView from 'src/views/job/MasterJobListView';
import SubJobListView from 'src/views/job/SubJobListView';
import SingleMasterJobView from 'src/views/job/SingleMasterJobView';
import SingleSubJobView from 'src/views/job/SingleSubJobView';
import AddMasterJobView from 'src/views/job/AddMasterJobView';
import AddSubJobView from 'src/views/job/AddSubJobView';

import JobRequestsListView from 'src/views/job/JobRequestsListView';
import ConvertJobRequest from 'src/views/job/ConvertJobRequest';

import SupplierListView from 'src/views/supplier/SupplierListView';
import SingleSupplierView from 'src/views/supplier/SingleSupplierView';
import AddSupplierView from 'src/views/supplier/AddSupplierView';

import RatecardListView from 'src/views/ratecard/RatecardListView';
import SingleRatecardView from 'src/views/ratecard/SingleRatecardView';
import AddRatecardView from 'src/views/ratecard/AddRatecardView';
import AddRatesView from 'src/views/ratecard/AddRatesView';

import UserRights from 'src/views/rights/UserRights';

import DashboardView from 'src/views/reports/DashboardView';
import SPoListView from 'src/views/reports/SPoListView';

import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import RestrictedView from 'src/views/auth/RestrictedView';
import SettingsView from 'src/views/settings/SettingsView';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },

      { path: 'clients', element: <ClientListView /> },
      { path: 'client/:id', element: <SingleClientView /> },
      { path: 'clients/add-new', element: <AddClientView /> },

      {
        path: 'clients/client/:id/add-new-contact',
        element: <AddClientContactView />
      },

      { path: 'users', element: <UserListView /> },
      { path: 'user/:id', element: <SingleUserView /> },
      { path: 'users/add-new', element: <AddUserView /> },

      {
        path: 'departments',
        element: <DepartmentListView />
      },
      {
        path: 'departments/department/:id',
        element: <SingleDepartmentView />
      },
      { path: 'departments/add-new', element: <AddDepartmentView /> },

      // { path: 'budget-allocation-report', element: <BARListView /> },
      { path: 'revrec', element: <RevrecListView /> },
      { path: 'revrec/:year', element: <RevrecListView /> },
      // JobListView need to be deleted
      { path: 'masterjobs', element: <JobListView /> },

      { path: 'masterjobs/newbiz', element: <MasterJobListView /> },
      { path: 'masterjobs/live', element: <MasterJobListView /> },
      { path: 'masterjobs/completed', element: <MasterJobListView /> },
      // for single master job
      { path: 'masterjob/:id', element: <SingleMasterJobView /> },
      { path: 'masterjob/:id/add-subjob', element: <AddSubJobView /> },

      //instead of below
      // { path: 'jobs/job/:id', element: <SingleMasterJobView /> },
      //jobunits paths need to be removed
      { path: 'jobunits', element: <SubJobListView /> },
      { path: 'jobunits/jobunit/:id', element: <SingleSubJobView /> },

      { path: 'subjob/:id', element: <SingleSubJobView /> },
      { path: '/subjobs/newbiz', element: <SubJobListView /> },
      { path: '/subjobs/live', element: <SubJobListView /> },
      { path: '/subjobs/completed', element: <SubJobListView /> },
      { path: '/subjobs/invoice', element: <SubJobListView /> },

      { path: 'masterjobs/add/newbiz', element: <AddMasterJobView /> },
      { path: 'masterjobs/add/live', element: <AddMasterJobView /> },

      { path: 'jobrequest', element: <JobRequestsListView /> },
      { path: 'jobrequest/add/:id', element: <ConvertJobRequest /> },

      { path: 'ratecards', element: <RatecardListView /> },
      { path: 'ratecards/ratecard/:id', element: <SingleRatecardView /> },
      { path: 'ratecards/add-new', element: <AddRatecardView /> },
      {
        path: 'ratecards/ratecard/:id/add-new-rate',
        element: <AddRatesView />
      },

      { path: 'rights/', element: <UserRights /> },

      { path: 'suppliers', element: <SupplierListView /> },
      { path: 'supplier/:id', element: <SingleSupplierView /> },
      { path: 'suppliers/add-new', element: <AddSupplierView /> },

      { path: 'ratecards', element: <RatecardListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'open-pos', element: <SPoListView /> },
      { path: '/', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'restricted', element: <RestrictedView /> },
      // { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> }
      // { path: '/*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    // element: <DashboardLayout />,
    children: [{ path: 'login', element: <LoginView /> }]
  }
];

export default routes;
