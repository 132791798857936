import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialReactTable from 'material-react-table';
import { Box, Card, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

// const removeClient = gql`
//   mutation removeClient($id: ID!) {
//     removeClient(id: $id) {
//       name
//     }
//   }
// `;

const Clients = gql`
  query ClientsList($search: clientListArgs, $ops: functionalArgs) {
    ClientsList(search: $search, ops: $ops) {
      info {
        total_records
      }
      data {
        _id
        name
        client_number
        use_po
        company_contact_info {
          url
          email
          phone
        }
        contacts {
          _id
        }
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  // get clients
  const { data: dataClients } = useQuery(Clients, {
    variables: { ops: { page: 0 } }
  });

  const columns = [
    {
      accessorKey: 'client_number',
      header: 'Client No.',
      enableHiding: false
    },
    {
      accessorKey: 'name',
      header: 'Name',
      enableHiding: false,
      Cell: ({ cell, row }) => (
        <Box alignItems="center" display="flex">
          <Typography color="textPrimary" variant="body1">
            <Link to={`/client/${row.original.client_number}`}>
              {cell.getValue()}
            </Link>
          </Typography>
        </Box>
      )
    },
    {
      accessorKey: 'company_contact_info.phone',
      header: 'Phone',
      enableHiding: true
    },
    {
      accessorKey: 'company_contact_info.email',
      header: 'Email',
      enableHiding: true
    },
    {
      accessorKey: 'use_po',
      header: 'Uses PO',
      Cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No')
    },
    {
      accessorKey: 'contacts.length',
      header: 'Number of Contacts'
    }
  ];

  const clientData = dataClients?.ClientsList?.data || [];
  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box minWidth={1050}>
        <MaterialReactTable
          columns={columns}
          data={clientData}
          enableColumnFilters
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding
          initialState={{
            density: 'compact',
            pagination: { pageSize: 25 },
            columnVisibility: {
              'company_contact_info.phone': false,
              'company_contact_info.email': false,
              'contacts.length': false,
              use_po: false
            }
          }}
          enablePagination
        />
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
