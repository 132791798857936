// GoogleApiLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { GoogleLogin } from 'react-google-login';
import GoogleIcon from 'src/icons/Google';
import { CLIENT_ID, SCOPES } from './GoogleApiAccess';
import SubscriptionDialog from './SubscriptionDialog';

const GoogleApiLogin = props => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [subscriptionChoices, setSubscriptionChoices] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const onLoginSuccessful = res => {
    const googleToken = res.tokenId;
    const gResp = res.profileObj;

    axios
      .post('googleLogin', {
        googleTokenID: googleToken
      })
      .then(response => {
        if (response.data.token) {
          const googleUser = JSON.stringify({
            firstName: gResp.givenName,
            lastName: gResp.familyName,
            email: gResp.email,
            thumbnail: gResp.imageUrl
          });
          localStorage.setItem('googleUser', googleUser);
          if (response.data.gotopage === 'dashboard') {
            localStorage.setItem('authToken', response.data.token);
            setTimeout(() => props.onChange(true), 1000);
          } else {
            setCurrentToken(response.data.token);
            const newArray = response.data.gotopage.map(item => ({
              _id: item._id,
              company_name: item.company_name
            }));
            console.log(newArray, 'newArray');
            setSubscriptionChoices(newArray);
            setDialogOpen(true);
          }
        }
      })
      .catch(error => {
        props.onChange(false);
        return error;
      });
  };

  return (
    <div>
      <GoogleLogin
        clientId={CLIENT_ID}
        render={renderProps => (
          <Button
            fullWidth
            disabled={renderProps.disabled}
            startIcon={<GoogleIcon />}
            onClick={renderProps.onClick}
            size="large"
            variant="contained"
          >
            Login with Google
          </Button>
        )}
        approvalPrompt="force"
        prompt="consent"
        disabled={isSignedIn}
        buttonText="Login"
        onSuccess={onLoginSuccessful}
        onFailure={() => setIsSignedIn(false)}
        cookiePolicy={'single_host_origin'}
        scope={SCOPES}
      />

      <SubscriptionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        subscriptionChoices={subscriptionChoices}
        onSubscriptionSelect={props.onChange}
        currentToken={currentToken}
      />
    </div>
  );
};

export default GoogleApiLogin;
