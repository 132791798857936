import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

// https://www.material-react-table.com/
import MaterialReactTable from 'material-react-table';

import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LockIcon from '@mui/icons-material/Lock';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import Link from '@mui/material/Link';

import AuthContext from 'src/components/AuthContext';

import Notes from 'src/views/notes/Notes';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& table': {
        borderCollapse: 'separate',
        borderSpacing: 0
      }
    }
  };
});

//nested data is ok, see accessorKeys in ColumnDef below
const datalocal = [
  {
    __typename: 'spoReport',
    _id: '6669bb24ab47d51377165f9f',
    title: 'Unit 1000 for spo test',
    spo_sum_cal: 30,
    client_name: 'Sold Out',
    supplier_po: {
      __typename: 'spoObj',
      _id: '6669dc60ab47d51377165fa3',
      amount: 10,
      description: 'Unit 1 for spo test - 01',
      created_date: '1718150400000',
      company_name: 'Adobe',
      supplier_po: 'SPO-6669-0624-914'
    }
  },
  {
    __typename: 'spoReport',
    _id: '6669bb24ab47d51377165f9f',
    title: 'Unit 1 for spo test',
    spo_sum_cal: 30,
    client_name: 'Sold Out',
    supplier_po: {
      __typename: 'spoObj',
      _id: '6669dc60ab47d51377165fa3',
      amount: 10,
      description: 'Unit 1 for spo test - 01',
      created_date: '1718150400000',
      company_name: 'Adobe',
      supplier_po: 'SPO-6669-0624-914'
    }
  },
  {
    __typename: 'spoReport',
    _id: '6669bb24ab47d51377165f9f',
    title: 'Unit 1 for spo test',
    spo_sum_cal: 30,
    client_name: 'Sold Out',
    supplier_po: {
      __typename: 'spoObj',
      _id: '6669dc60ab47d51377165fa3',
      amount: 10,
      description: 'Unit 1 for spo test - 01',
      created_date: '1718150400000',
      company_name: 'Adobe',
      supplier_po: 'SPO-6669-0624-914'
    }
  },
  {
    __typename: 'spoReport',
    _id: '666aff68ab47d51377165fa7',
    title: 'Pitch Cost  - Second Container',
    spo_sum_cal: 100,
    client_name: 'ITV',
    supplier_po: {
      __typename: 'spoObj',
      _id: '666aff87ab47d51377166005',
      amount: 100,
      description: 'PO on pitch cost',
      created_date: '1718236800000',
      company_name: 'Bonnie & Betty Limited',
      supplier_po: 'SPO-666a-0624-9411'
    }
  },
  {
    __typename: 'spoReport',
    _id: '666affe8ab47d51377166006',
    title: 'Unit 1 of second container',
    spo_sum_cal: 300,
    client_name: 'ITV',
    supplier_po: {
      __typename: 'spoObj',
      _id: '666b0048ab47d5137716600d',
      amount: 200,
      description: 'PO1 - Unit 1 of second container',
      created_date: '1718236800000',
      company_name: 'Amazon Web Services',
      supplier_po: 'SPO-666a-0624-706'
    }
  },
  {
    __typename: 'spoReport',
    _id: '666affe8ab47d51377166006',
    title: 'Unit 1 of second container',
    spo_sum_cal: 300,
    client_name: 'ITV',
    supplier_po: {
      __typename: 'spoObj',
      _id: '666b0048ab47d5137716600d',
      amount: 200,
      description: 'PO1 - Unit 1 of second container',
      created_date: '1718236800000',
      company_name: 'Amazon Web Services',
      supplier_po: 'SPO-666a-0624-706'
    }
  },
  {
    __typename: 'spoReport',
    _id: '666b000cab47d51377166009',
    title: 'Unit 2 of second container',
    spo_sum_cal: 350,
    client_name: 'ITV',
    supplier_po: {
      __typename: 'spoObj',
      _id: '666b0085ab47d51377166010',
      amount: 100,
      description: 'PO1 - Unit 2 of second container',
      created_date: '1718236800000',
      company_name: 'AirBNB',
      supplier_po: 'SPO-666b-0624-3717'
    }
  },
  {
    __typename: 'spoReport',
    _id: '666b000cab47d51377166009',
    title: 'Unit 2 of second container',
    spo_sum_cal: 350,
    client_name: 'ITV',
    supplier_po: {
      __typename: 'spoObj',
      _id: '666b0085ab47d51377166010',
      amount: 100,
      description: 'PO1 - Unit 2 of second container',
      created_date: '1718236800000',
      company_name: 'AirBNB',
      supplier_po: 'SPO-666b-0624-3717'
    }
  }
];

const QueryReport = gql`
  query {
    Jobreport {
      job_id
      job_number
      title
      spo_sum_cal
      invoice_sum_cal
      client_name
      invoice_sum_cal
      remaining_spo_amount
      is_pass_through
      supplier_po {
        _id
        amount
        description
        created_date
        company_name
        supplier_po
        spo_status
        completed_date
        supply_date
      }
    }
  }
`;

const Results = () => {
  const [datatable, setDatatable] = useState([]);

  // get jobs
  const {
    loading: loadingReportqry,
    data: dataReportqry,
    error: errorReportqry,
    refetch
  } = useQuery(QueryReport);

  // callback for jobs query
  useEffect(() => {
    const onCompleted = dataReportqry => {
      // console.log(dataReportqry.Jobreport);
      setDatatable(dataReportqry.Jobreport);
    };
    const onError = errorReportqry => {
      console.log('query errorReportqry', errorReportqry);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingReportqry && !errorReportqry) {
        onCompleted(dataReportqry);
      } else if (onError && !loadingReportqry && errorReportqry) {
        onError(errorReportqry);
      }
    }
  }, [loadingReportqry, dataReportqry, errorReportqry]);

  const calculatedAmount = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.supplier_po.amount, 0);
  }, [datatable]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title', //access nested data with dot notation
        header: 'Job Title',
        enableGrouping: true,
        size: 150,
        Cell: ({ cell }) => (
          <Link href={'/subjob/' + cell.row.original.job_number}>
            {cell.getValue()}
          </Link>
        )
      },
      {
        accessorKey: 'job_number',
        header: 'Job No.',
        size: 150
      },
      {
        accessorKey: 'client_name',
        header: 'Client',
        enableGrouping: true,
        size: 150
      },
      {
        accessorKey: 'is_pass_through',
        header: 'PTC',
        enableGrouping: false,
        size: 150,
        Cell: ({ cell }) => <>{cell.getValue() ? 'Yes' : 'No'}</>
      },
      {
        accessorKey: 'supplier_po.supplier_po', //normal accessorKey
        header: 'Supplier PO',
        enableGrouping: false,
        size: 200
      },
      {
        accessorKey: 'supplier_po.company_name',
        header: 'Supplier',
        enableGrouping: true,
        size: 150
      },
      {
        accessorKey: 'supplier_po.amount',
        header: 'Amount',
        enableGrouping: false,
        size: 150,
        // to add additional information based on grouping
        AggregatedCell: ({ cell }) => (
          <div>
            Group Amount {/* title of that section */}
            {cell.getValue().toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        ),
        Footer: () => (
          <div style={{ textAlign: 'left' }}>
            {calculatedAmount.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'invoice_sum_cal',
        header: 'Total Invoiced',
        enableGrouping: false,
        size: 150
      },
      {
        accessorKey: 'remaining_spo_amount',
        header: 'Remaining',
        enableGrouping: false,
        size: 150
      },
      {
        accessorKey: 'supplier_po.description',
        header: 'Description',
        enableGrouping: false,
        size: 150
      },
      {
        accessorKey: 'supplier_po.spo_status',
        header: 'Status',
        enableGrouping: false,
        size: 150
      },
      {
        accessorKey: 'supplier_po.created_date',
        header: 'Created Date',
        enableGrouping: false,
        size: 150,
        Cell: ({ cell }) => (
          <>{moment.unix(cell.getValue() / 1000).format('DD/MM/YYYY')}</>
        )
      },
      {
        accessorKey: 'supplier_po.supply_date',
        header: 'Supply Date',
        enableGrouping: false,
        size: 150,
        Cell: ({ cell }) => (
          <>{moment.unix(cell.getValue() / 1000).format('DD/MM/YYYY')}</>
        )
      },
      {
        accessorKey: 'supplier_po.completed_date',
        header: 'Completed Date',
        enableGrouping: false,
        size: 150,
        Cell: ({ cell }) => (
          <>
            {cell.getValue()
              ? moment.unix(cell.getValue() / 1000).format('DD/MM/YYYY')
              : '-'}
          </>
        )
      }
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={datatable}
      enableGrouping
      initialState={{
        density: 'compact',
        expanded: false, //expand all groups by default
        grouping: [] //an array of columns to group by by default (can be multiple)
      }}
    />
  );
};

export default Results;
