import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { makeStyles } from 'tss-react/mui';
import GoogleDriveDeleteIcon, {
  GoogleDeleteFile
} from './GoogleDriveDeleteIcon';

const useStyles = makeStyles()(() => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  treeNode: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));

const TreeNode = ({ data, level = 0, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = data.children && data.children.length > 0;
  const isFolder = data.mimeType === 'application/vnd.google-apps.folder';
  const { classes } = useStyles();

  return (
    <div className="w-full">
      <Box className={classes.treeNode} sx={{ paddingLeft: `${level * 32}px` }}>
        {hasChildren && (
          <IconButton onClick={() => setIsExpanded(!isExpanded)} size="small">
            {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
        {!hasChildren && <Box sx={{ width: 28 }} />}

        {isFolder ? <FolderIcon color="primary" /> : <InsertDriveFileIcon />}

        <Typography variant="body2" sx={{ ml: 2, flexGrow: 1 }}>
          {data.name}
        </Typography>

        <Box sx={{ display: 'flex', gap: 1 }}>
          {/* <IconButton onClick={() => onDelete(data.id)} size="small">
            <DeleteIcon />
            <GoogleDriveDeleteIcon file={file} response={removeFile} />
            <GoogleDriveDeleteIcon file={data} response={removeFile} />
          </IconButton> */}
          <GoogleDriveDeleteIcon file={data} response={onDelete} />

          {data.webViewLink && (
            <IconButton
              component="a"
              href={data.webViewLink}
              target="_blank"
              rel="noreferrer"
              size="small"
            >
              <LaunchIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {hasChildren && isExpanded && (
        <Box sx={{ ml: 4 }}>
          {data.children.map(child => (
            <TreeNode
              key={child.id}
              data={child}
              level={level + 1}
              onDelete={onDelete}
            />
          ))}
        </Box>
      )}
    </div>
  );
};

const CustomTreeView = ({ data, onDelete }) => {
  return (
    <Box>
      {data?.map(item => (
        <TreeNode key={item.id} data={item} onDelete={onDelete} />
      ))}
    </Box>
  );
};
function GoogleDriveFileTree(props) {
  const { classes } = useStyles();
  const folderID = props.folderID || '';
  const uploadedFile = props.uploadedFile || '';

  const [state, setState] = useState({
    folderID,
    uploadedFile,
    fileTree: null
  });

  useEffect(() => {
    if (uploadedFile) {
      updateList();
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (
      props.folderID !== state.folderID ||
      props.uploadedFile !== state.uploadedFile
    ) {
      updateList();
    }
  }, [props.folderID, props.uploadedFile, state.folderID, state.uploadedFile]);

  const updateList = () => {
    const folderID = props.folderID || '';
    setState(prev => ({ ...prev, folderID }));

    axios
      .get('/getFileTree', {
        params: { folderID }
      })
      .then(response => {
        const sortedData = sortDriveList(response.data);
        setState(prev => ({ ...prev, fileTree: sortedData }));
      });
  };

  const sortDriveList = list => {
    return [...list].sort((a, b) => {
      // Sort by type (folders first)
      if (
        a.mimeType === 'application/vnd.google-apps.folder' &&
        a.mimeType !== b.mimeType
      ) {
        return -1;
      }
      // Then sort by name
      return a.name.localeCompare(b.name);
    });
  };

  const removeFile = fileID => {
    const newList = state.fileTree.filter(item => item.id !== fileID);
    setState({ ...state, fileTree: newList });
  };

  return state.fileTree ? (
    <>
      <CustomTreeView data={state.fileTree} onDelete={removeFile} />
    </>
  ) : null;
}

export default GoogleDriveFileTree;
