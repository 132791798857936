import React, { useState, useMemo } from 'react';

import MaterialReactTable from 'material-react-table';

import { useMutation } from '@apollo/client';
import { updateRates } from 'src/queries/query';

const RatesDetails = ({ className, id, rates, var_approved, ...rest }) => {
  const [datarates, setDatarates] = useState(rates);

  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);

  const [update_Rates, { error: mutationError }] = useMutation(updateRates, {
    onCompleted() {
      console.log('update_Rates Completed');
    },
    onError: err => {
      setError(err);
      console.log(err);
    }
  });

  const handleSaveCell = (cell, value) => {
    console.log(cell.row.original._id);
    console.log('perform the update here');
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'role',
        header: 'Role',
        enableEditing: false
      },
      {
        accessorKey: 'perhour',
        header: 'Rate',
        enableEditing: 'True',
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.('en-UK', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </>
        )
      }
    ],
    []
  );

  return (
    <>
      {/* https://www.material-react-table.com/docs/guides/editing#cell-editing-mode */}
      <MaterialReactTable
        columns={columns}
        data={datarates}
        editingMode="cell"
        enableEditing
        enablePagination={false}
        enableColumnActions={false}
        enableDensityToggle={false}
        initialState={{ density: 'compact' }}
        enableFullScreenToggle={false}
        enableHiding={false}
        muiTableBodyCellEditTextFieldProps={({ cell }) => ({
          //onBlur is more efficient, but could use onChange instead
          onBlur: event => {
            handleSaveCell(cell, event.target.value);
          }
        })}
      />
    </>
  );
};

export default RatesDetails;
