// HeaderSubscriptionButton.js
import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SubscriptionDialog from './SubscriptionDialog';

const HeaderSubscriptionButton = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);
  const [hasSubscriptions, setHasSubscriptions] = useState(false);
  const currentToken = localStorage.getItem('authToken');
  const navigate = useNavigate();
  // Check for subscriptions on component mount
  useEffect(() => {
    checkForSubscriptions();
  }, []);

  const checkForSubscriptions = async () => {
    const storedChoices = localStorage.getItem('subscriptionChoices');
    if (storedChoices) {
      const choices = JSON.parse(storedChoices);
      setHasSubscriptions(choices && choices.length > 1);
      setSubscriptions(choices);
      return;
    }

    try {
      const response = await fetch('your-api-endpoint-for-subscriptions', {
        headers: {
          'x-access-token': currentToken
        }
      });
      const data = await response.json();
      const formattedData = data.map(item => ({
        key: item._id,
        name: item.company_name
      }));
      const hasMultipleSubscriptions =
        formattedData && formattedData.length > 1;
      setHasSubscriptions(hasMultipleSubscriptions);
      setSubscriptions(formattedData);
      localStorage.setItem(
        'subscriptionChoices',
        JSON.stringify(formattedData)
      );
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      setHasSubscriptions(false);
    }
  };

  const handleClick = async () => {
    setDialogOpen(true);
  };

  const handleSubscriptionChange = success => {
    if (success) {
      // window.location.reload();
      navigate('/dashboard');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  // Don't render anything if there are no subscriptions or only one subscription
  if (!hasSubscriptions) {
    return null;
  }

  return (
    <>
      {React.cloneElement(children, { onClick: handleClick })}

      <SubscriptionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        subscriptionChoices={subscriptions}
        onSubscriptionSelect={handleSubscriptionChange}
        currentToken={currentToken}
      />
    </>
  );
};

export default HeaderSubscriptionButton;
