import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaterialReactTable from 'material-react-table';

import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import moment from 'moment';

const Users = gql`
  query Users {
    Users {
      _id
      first_name
      last_name
      email
    }
  }
`;

const addTeam = gql`
  mutation addTeam(
    $ref: ID!
    $user_reference: String!
    $user_name: String!
    $email: String!
    $designation: String!
    $jobs_designation: String!
    $department_name: String!
  ) {
    addTeam(
      ref: $ref
      data: {
        user_reference: $user_reference
        user_name: $user_name
        email: $email
        designation: $designation
        jobs_designation: $jobs_designation
        department_name: $department_name
      }
    ) {
      _id
      team {
        _id
        user_reference
        user_name
        email
        designation
        jobs_designation
        department_name
      }
    }
  }
`;

const removeTeam = gql`
  mutation removeTeam($ref: ID!) {
    removeTeam(ref: $ref) {
      _id
      team {
        _id
        user_reference
        user_name
        email
        designation
        jobs_designation
        department_name
      }
    }
  }
`;

const TeamDetails = ({ className, element, stage, ...rest }) => {
  // Default form's state values for adding a team member

  const formDefaults = {
    user_reference: '',
    user_name: '',
    email: '',
    designation: '',
    jobs_designation: '',
    department_name: ''
  };

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [formState, setFormState] = useState(formDefaults);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorallert, setErrorallert] = useState('');

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.TeamDetails === false ? false : true
  );

  const [randkey, setRandkey] = useState(
    moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
  );

  //-----------------------
  const [rowID, setrowID] = useState(null);
  const [openDel, setOpenDel] = useState(false);

  //-----------------------

  const jobref = element[0]._id;

  const submitRecord = () => {
    if (
      formState.user_reference &&
      formState.user_name &&
      formState.email &&
      formState.designation &&
      formState.jobs_designation &&
      formState.department_name
    ) {
      add_Team({
        variables: {
          ref: jobref,
          user_reference: formState.user_reference,
          user_name: formState.user_name,
          email: formState.email,
          designation: formState.designation,
          jobs_designation: formState.jobs_designation,
          department_name: formState.department_name
        }
      });
      setFormState(formDefaults);
      setAskareyousure(false);
    } else {
      const msg = 'Please add the necessary information';
      setErrorallert(msg);

      setErrorMsg(msg);
    }
  };

  const [add_Team] = useMutation(addTeam, {
    onCompleted() {
      setFormState(formDefaults);
      setRandkey(
        moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
      );
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      console.log(msg);
      setErrorallert(msg);

      setErrorMsg(msg);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const handleClickOpenDel = id => {
    setrowID(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setrowID(null);
    setOpenDel(false);
  };

  const handleYesDel = () => {
    if (rowID) {
      console.log(' delete row called for ' + rowID);
      remove_Team({
        variables: {
          ref: rowID
        }
      });
    }
    setOpenDel(false);
  };

  const [remove_Team] = useMutation(removeTeam, {
    onCompleted() {
      console.log('row deleted');
      handleCloseDel();
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      setErrorallert(msg);

      setErrorMsg(msg);
    }
  });
  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.TeamDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  const hideError = () => {
    setErrorMsg('');
    setErrorallert(false);
  };
  const columns = [
    {
      accessorKey: 'user_name',
      header: 'Name'
    },
    {
      accessorKey: 'email',
      header: 'Email'
    },
    {
      accessorKey: 'designation',
      header: 'Designation'
    },
    {
      accessorKey: 'jobs_designation',
      header: 'Job Designation'
    },
    {
      accessorKey: 'department_name',
      header: 'Department'
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 50,
      Cell: ({ row }) =>
        stage !== 'complete' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DeleteIcon
              onClick={() => handleClickOpenDel(row.original._id)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )
    }
  ];

  return (
    <Card>
      <CardHeader
        // subheader="The information can be edited"
        title="Team Members"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Grid item md={12} xs={12}>
          <MaterialReactTable
            columns={columns}
            data={element[0].team}
            enableColumnActions={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableHiding={false}
            enablePagination={false}
            initialState={{
              density: 'compact',
              sorting: [{ id: 'department_name', desc: false }]
            }}
          />
        </Grid>
        {/* Dialog Section */}
        <Dialog
          open={openDel}
          onClose={handleCloseDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this row
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDel} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleYesDel} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog Section */}
        <Divider />
        {stage !== 'complete' && ( // Team Member can be added only if the job is live
          <CardContent>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Query query={Users}>
                    {({ loading, data }) =>
                      !loading && (
                        <Autocomplete
                          id="userlist"
                          options={data.Users}
                          getOptionLabel={option =>
                            [option.first_name, option.last_name].join(' ')
                          }
                          key={randkey}
                          onChange={(event, values) => {
                            if (values) {
                              setFormState({
                                user_reference: values._id,
                                user_name: [
                                  values.first_name,
                                  values.last_name
                                ].join(' '),
                                email: values.email,
                                designation: values.designation
                                  ? values.designation
                                  : 'not defined',
                                jobs_designation: values.designation
                                  ? values.designation
                                  : 'not defined',
                                department_name: values.department
                                  ? values.department
                                  : 'not defined'
                              });
                            } else {
                              setFormState({
                                user_reference: '',
                                user_name: '',
                                email: '',
                                designation: '',
                                jobs_designation: '',
                                department_name: ''
                              });
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Name"
                              name="user_name"
                              variant="outlined"
                            />
                          )}
                        />
                      )
                    }
                  </Query>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    label="jobs_designation"
                    name="jobs_designation"
                    variant="outlined"
                    value={formState.jobs_designation}
                    onChange={changeHandler}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              {askareyousure ? (
                <button
                  onClick={submitRecord}
                  id="button"
                  className={`btn-animate ${isloading ? 'onclic' : null} ${
                    !isloading && completed ? 'validate' : null
                  } ${iserror ? 'novalidate' : null}`}
                ></button>
              ) : (
                <button
                  onClick={ask}
                  id="ask"
                  className={`btn-animate ask`}
                ></button>
              )}
            </Box>
            <Divider />
          </CardContent>
        )}
      </Collapse>
    </Card>
  );
};

export default TeamDetails;
