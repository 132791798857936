import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

import { useMutation } from '@apollo/client';
import { updateSupplier } from 'src/queries/query';
import { useAlert } from '../../../utils/AlertSystem';

const ProfileDetails = ({ className, element, ...rest }) => {
  const { showAlert } = useAlert();

  const [updateSupplierMutation] = useMutation(updateSupplier, {
    onCompleted() {
      showAlert('Supplier updated successfully', 'success');
    },
    onError: err => {
      const errorMessage = err.message.includes('GraphQL error:')
        ? err.message.split('GraphQL error: ')[1]
        : err.message;
      showAlert(errorMessage, 'error');
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    updateSupplierMutation({
      variables: {
        ref: element._id,
        data: {
          company_name: e.target.name.value,
          category: e.target.category.value,
          first_name: e.target.first_name.value,
          last_name: e.target.last_name.value,
          email: e.target.email.value,
          phone: e.target.phone.value,
          fax: e.target.fax.value,
          address_line1: e.target.address_line1.value,
          address_line2: e.target.address_line2.value,
          city: e.target.city.value,
          county: e.target.county.value,
          country: e.target.country.value,
          postcode: e.target.postcode.value,
          url: e.target.url.value,
          other: e.target.other.value
        }
      }
    });
  };

  return (
    <form autoComplete="off" noValidate {...rest} onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the name"
                label="Supplier Name"
                name="name"
                type="text"
                required
                variant="outlined"
                defaultValue={element.company_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                variant="outlined"
                defaultValue={element.email}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                type="number"
                variant="outlined"
                defaultValue={element.phone}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  label="Category"
                  name="category"
                  defaultValue={element.category || ''}
                >
                  <MenuItem value="Company">Company</MenuItem>
                  <MenuItem value="Sister Company">Sister Company</MenuItem>
                  <MenuItem value="Freelancer">Freelancer</MenuItem>
                  <MenuItem value="CreditCard">Credit Card</MenuItem>
                  <MenuItem value="Manufacture">Manufacture</MenuItem>
                  <MenuItem value="Retailers">Retailers</MenuItem>
                  <MenuItem value="Wholesalers">Wholesalers</MenuItem>
                  <MenuItem value="Distributors">Distributors</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="first_name"
                type="text"
                variant="outlined"
                defaultValue={element.first_name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="last_name"
                type="text"
                variant="outlined"
                defaultValue={element.last_name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="address_line1"
                type="text"
                variant="outlined"
                defaultValue={element.address_line1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="address_line2"
                type="text"
                variant="outlined"
                defaultValue={element.address_line2}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="city"
                type="text"
                variant="outlined"
                defaultValue={element.city}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="County"
                name="county"
                type="text"
                variant="outlined"
                defaultValue={element.county}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                type="text"
                variant="outlined"
                defaultValue={element.country}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Postcode"
                name="postcode"
                type="text"
                variant="outlined"
                defaultValue={element.postcode}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="URL"
                name="url"
                type="text"
                variant="outlined"
                defaultValue={element.url}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Fax"
                name="fax"
                variant="outlined"
                defaultValue={element.fax}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Other Info"
                name="other"
                type="textarea"
                variant="outlined"
                defaultValue={element.other}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" type="submit">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ProfileDetails;
