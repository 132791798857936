import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import { Query } from '@apollo/client/react/components';

import { Supplier } from 'src/queries/query';

import ProfileDetails from './ProfileDetails';
import Toolbar from './Toolbar';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const Account = () => {
  const { classes } = useStyles();
  const elementId = useParams();

  return (
    <Page className={classes.root} title="Supplier">
      <Query query={Supplier} variables={{ supplier_number: elementId.id }}>
        {({ loading, data }) =>
          !loading && (
            <Container maxWidth="lg">
              <Toolbar />
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={6} xs={12}>
                    <ProfileDetails element={data.Supplier} />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          )
        }
      </Query>
    </Page>
  );
};

export default Account;
