import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableCell,
  TextField,
  TableHead,
  TableRow,
  Collapse,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { useMutation, useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, AlertTitle } from '@mui/lab';

import moment from 'moment';

import currencyformatter from 'src/utils/currencyformatter';
import groupBy from 'src/utils/groupBy';
import JUBudgetDetails from 'src/components/JUBudgetDetails';
import ReadMore from 'src/components/ReadMore';

import gql from 'graphql-tag';

const Departments = gql`
  query Department {
    Department(is_sub_department: true) {
      name
    }
  }
`;

const DepartmentQuery = gql`
  query Ratecard($id: ID!) {
    Ratecard(_id: $id) {
      _id
      rates {
        department_name
        role
        perhour
      }
    }
  }
`;

const addBudgetAllocation = gql`
  mutation addBudgetAllocation(
    $ref: ID!
    $department_name: String!
    $amount: Float!
  ) {
    addBudgetAllocation(
      ref: $ref
      data: { department_name: $department_name, amount: $amount }
    ) {
      _id
      total_budget_allocation
      budget_allocation {
        _id
        department_name
        note
        created
        user_reference
        user_name
        amount
      }
    }
  }
`;

const removeBudgetAllocation = gql`
  mutation removeBudgetAllocation($ref: ID!) {
    removeBudgetAllocation(ref: $ref) {
      _id
      total_budget_allocation
      budget_allocation {
        _id
        department_name
        amount
        created
        user_reference
        user_name
      }
    }
  }
`;

const updateBudgetAllocation = gql`
  mutation updateBudgetAllocation($ref: ID!, $amount: Float!) {
    updateBudgetAllocation(ref: $ref, data: { amount: $amount }) {
      _id
      total_budget_allocation
      budget_allocation {
        _id
        department_name
        amount
        note
        created
        user_reference
        user_name
      }
    }
  }
`;

const BudgetAllocationDetails = ({
  className,
  element,
  containerTotalbudget,
  updatedsingleAllocated,
  time_estimate_switch,
  containerAllocated,
  stage,
  ...rest
}) => {
  // Default form's state values

  const jobref = element[0]._id;

  const [updatedTimeEstimate, setUpdatedTimeEstimate] = useState(
    element[0].estimated_time
  );
  const [updatedBudgetAllocation, setupdatedBudgetAllocation] = useState(
    element[0].total_budget_allocation
  );
  const [updatedEstimated_ftc, setUpdatedEstimated_ftc] = useState(
    element[0].estimated_ftc
  );

  let budget_allocation_sum = '';
  // let ftc_estimate_sum = '';

  useEffect(() => {
    setUpdatedEstimated_ftc(element[0].estimated_ftc);
  }, [element[0]]);

  useEffect(() => {
    setUpdatedTimeEstimate(element[0].estimated_time);
  }, [element[0]]);

  // Query for Ratecard data
  const DepartmentResult = useQuery(DepartmentQuery, {
    variables: { id: element[0].ratecard_reference }
  });

  // Query for Department names
  const DepartmentsResult = useQuery(Departments);

  const [rateList, setRateList] = useState([]);
  useEffect(() => {
    if (!DepartmentResult.loading) {
      setRateList(DepartmentResult.data.Ratecard);
    }
  }, [DepartmentResult.data]);

  const [departmentList, setDepartmentList] = useState([]);
  useEffect(() => {
    if (!DepartmentsResult.loading && DepartmentsResult.data) {
      setDepartmentList(DepartmentsResult.data.Department);
    }
  }, [DepartmentsResult.data]);

  const formDefaults = {
    department_name: '',
    amount: 0
  };

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [formState, setFormState] = useState(formDefaults);

  const changeHandler = e => {
    const nam = e.target.name;
    const val = e.target.value;
    setFormState({ ...formState, [nam]: val });
  };

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [editRow, setEditRow] = useState(false);

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.TimeEstimateDetails === false ? false : true
  );

  const [totalHours, setTotalHours] = useState(0);
  const [totalCost, setTotalCost] = useState(false);
  const [totalAmount, setTotalAmount] = useState(false);

  //-----------------------
  const [rowID, setrowID] = useState(null);
  const [openDel, setOpenDel] = useState(false);

  //-----------------------

  const [errorallert, setErrorallert] = useState('');
  const [randkey, setRandkey] = useState(
    moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
  );

  const [add_BudgetAllocation] = useMutation(addBudgetAllocation, {
    onCompleted: data => {
      setFormState(formDefaults);
      setErrorallert('');
      // console.log(data.addJobEstimate.time_estimate);
      budget_allocation_sum = data.addBudgetAllocation.budget_allocation.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );
      setupdatedBudgetAllocation(budget_allocation_sum);
      setRandkey(
        moment().format('MMYY') + '-' + Math.floor(Math.random() * 10000)
      );
      // console.log(sum);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
      setErrorallert(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const submitRecord = () => {
    if (formState.department_name) {
      add_BudgetAllocation({
        variables: {
          ref: jobref,
          department_name: formState.department_name,
          amount: parseFloat(formState.amount)
        }
      });
      // setFormState(formDefaults);
      setAskareyousure(false);
    } else {
      setErrorMsg('Please add the necessary information');
      // setOpendialog(true);
    }
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };
  //---------------------------------------
  // Remove
  //---------------------------------------
  const handleClickOpenDel = id => {
    setrowID(id);
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setrowID(null);
    setOpenDel(false);
  };

  const [remove_BudgetAllocation] = useMutation(removeBudgetAllocation, {
    onCompleted: data => {
      console.log('row deleted');
      handleCloseDel();
      budget_allocation_sum = data.removeBudgetAllocation.budget_allocation.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );
      setupdatedBudgetAllocation(budget_allocation_sum);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');
    }
  });

  const handleYesDel = () => {
    if (rowID) {
      console.log(' delete row called for ' + rowID);
      remove_BudgetAllocation({
        variables: {
          ref: rowID
        }
      });
    }
    setOpenDel(false);
  };

  //---------------------------------------
  // Remove Ends
  //---------------------------------------

  //---------------------------------------
  // Edit
  //---------------------------------------

  const handleOpenEditDialog = (_id, amount) => {
    setrowID(_id);
    setFormState({
      amount: amount
    });
    setEditRow(true);
  };

  const handleCloseEditDialog = () => {
    setEditRow(false);
  };

  const [update_BudgetAllocation] = useMutation(updateBudgetAllocation, {
    onCompleted: data => {
      console.log('DATA', data);
      budget_allocation_sum = data.updateBudgetAllocation.budget_allocation.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );
      setupdatedBudgetAllocation(budget_allocation_sum);
    },
    onError: err => {
      const msg = String(err).replace('Error: GraphQL error: ', '');

      setOpendialog(true);
      setErrorMsg(msg);
    }
  });

  const updateRow = () => {
    if (formState.amount !== 0) {
      update_BudgetAllocation({
        variables: {
          ref: rowID,
          amount: parseFloat(formState.amount)
        }
      });
      setFormState(formDefaults);
    } else {
      console.log('fail!');
    }
  };

  const handleSaveEditDialog = () => {
    updateRow();
    setEditRow(false);
  };

  const hideError = () => {
    setErrorMsg('');
    setErrorallert(false);
  };
  const arrayTest = [];
  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.TimeEstimateDetails = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  // Form Query Actions end

  //---------------------------------------
  // Edit Ends
  //---------------------------------------

  return (
    <Card>
      <CardHeader
        // subheader="The information can be edited"
        title="Budget Allocation"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        {element[0].budget_allocation.length > 0 && (
          <>
            <Grid item md={12} xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Department</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Added By</TableCell>
                    <TableCell>Date</TableCell>
                    {stage !== 'complete' && <TableCell></TableCell>}
                  </TableRow>
                  {element[0].budget_allocation.map(item => (
                    <TableRow>
                      <TableCell>{item.department_name}</TableCell>

                      <TableCell>{item.amount}</TableCell>
                      <TableCell sx={{ lineHeight: '1.2em' }}>
                        {item.user_name}
                      </TableCell>
                      <TableCell>
                        {moment.unix(item.created * 0.001).format('DD/MM/YYYY')}
                      </TableCell>
                      {stage !== 'complete' && (
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <IconButton aria-label="delete" size="small">
                              <DeleteIcon
                                onClick={() => handleClickOpenDel(item._id)}
                                style={{ cursor: 'pointer' }}
                              />
                            </IconButton>
                            <IconButton aria-label="edit" size="small">
                              <EditRoundedIcon
                                onClick={() =>
                                  handleOpenEditDialog(item._id, item.amount)
                                }
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableHead>
              </Table>
            </Grid>
          </>
        )}
        {/* Dialog for SPO Edit */}
        <Dialog
          open={editRow}
          onClose={handleCloseEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Edit Budget Allocation
            </DialogContentText>

            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  variant="outlined"
                  value={formState.amount || ''}
                  onChange={changeHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary" autoFocus>
              Close
            </Button>
            <Button onClick={handleSaveEditDialog} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog for FTC Edit */}
        {/* Dialog For Delete Confirmation */}
        <Dialog
          open={openDel}
          onClose={handleCloseDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this row
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDel} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleYesDel} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog For Delete Confirmation End */}
        {stage !== 'complete' && (
          <>
            {/* <Divider /> */}
            <CardContent>
              <Grid container spacing={4} alignItems="flex-start">
                {departmentList && departmentList.length > 0 && (
                  <Grid container item md={6} xs={12} spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        id="departmentlist"
                        key={randkey}
                        options={
                          departmentList && departmentList.length > 0
                            ? departmentList.map(dept => dept.name)
                            : []
                        }
                        onChange={(event, value) => {
                          if (value) {
                            setFormState({
                              ...formState,
                              department_name: value
                            });
                          } else {
                            setFormState({
                              ...formState,
                              department_name: ''
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Department"
                            name="department_roles"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Amount"
                        name="amount"
                        variant="outlined"
                        value={formState.amount || ''}
                        onChange={changeHandler}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          )
                        }}
                      />
                    </Grid>

                    {/* ------------------------------------------------ */}
                    <CardContent>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end">
                        {askareyousure ? (
                          <button
                            onClick={submitRecord}
                            id="button"
                            className={`btn-animate ${
                              isloading ? 'onclic' : null
                            } ${!isloading && completed ? 'validate' : null} ${
                              iserror ? 'novalidate' : null
                            }`}
                          ></button>
                        ) : (
                          <button
                            onClick={ask}
                            id="ask"
                            className={`btn-animate ask`}
                          ></button>
                        )}

                        {errorallert && (
                          <Alert severity="error" onClose={hideError}>
                            <AlertTitle>{errorallert}</AlertTitle>
                          </Alert>
                        )}
                      </Box>
                    </CardContent>
                    {/* ------------------------------------------------ */}
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <JUBudgetDetails
                    budget={updatedsingleAllocated}
                    estimated_time={updatedTimeEstimate}
                    estimated_ftc={updatedEstimated_ftc}
                    estimated_budget_allocation={updatedBudgetAllocation}
                    available_for_estimate={element[0].available_for_estimate}
                    containerID={element[0].container_ref}
                    elementID={element[0]._id}
                    showForm={false}
                    showContainer={false}
                    time_estimate_switch={time_estimate_switch}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}
      </Collapse>
    </Card>
  );
};

export default BudgetAllocationDetails;
