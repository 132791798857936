import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SubResults from './SubResults';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    titleSection: {
      marginBottom: theme.spacing(3)
    }
  };
});

const stageMap = {
  newbiz: { label: 'New Biz', value: 'newbiz', index: 0 },
  live: { label: 'Live', value: 'live', index: 1 },
  completed: { label: 'Completed', value: 'complete', index: 2 },
  invoice: { label: 'To be Invoiced', value: 'toinvoice', index: 3 }
};

const Results = ({ className, stage, ...rest }) => {
  const { classes, cx } = useStyles();

  // Map URL parameter to stage value
  const currentStage = stageMap[stage] || stageMap.live;

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box pt={1} px={1} pb={3}>
        <SubResults
          stagevalue={currentStage.value}
          stageLabel={currentStage.label} // Pass the label here
          index={currentStage.index}
        />
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  stage: PropTypes.string
};

export default Results;
