import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormHelperText,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import WeekSelector from './WeekSelector';
import WeekSelector2 from './WeekSelector2';

const updateTimesheet = gql`
  mutation updateTimesheet(
    $ref: ID!
    $job_reference: ID!
    $note: String
    $hours: Float!
    $mm_dd_yyyy: String! #
  ) {
    updateTimesheet(
      ref: $ref
      job_reference: $job_reference
      data: { note: $note, mm_dd_yyyy: $mm_dd_yyyy, hours: $hours }
    ) {
      _id
    }
  }
`;
const addTimesheet = gql`
  mutation addTimesheet(
    $job_reference: String!
    $note: String
    $hours: Float!
    $mm_dd_yyyy: String!
  ) {
    addTimesheet(
      data: {
        job_reference: $job_reference
        note: $note
        mm_dd_yyyy: $mm_dd_yyyy
        hours: $hours
      }
    ) {
      _id
    }
  }
`;

let date = moment().day('Monday');
let weeknr = '';
let year = '';
let data = '';
const i = 0;

const TimesheetForm = ({
  activeRow,
  open,
  handleClose,
  operation,
  postoperationfnc,
  job_title_param,
  job_id_param
}) => {
  const [row, setRow] = useState(activeRow);
  const [utcweek, setUTCweek] = useState('');

  const dateChanged = date => {
    const utctime = Date.parse(date);
    setUTCweek(utctime);
    weeknr = moment(date)
      .weekday(0)
      .week();
    year = moment(date).year();
    setRow({
      ...row,
      for_week: weeknr,
      year: year
    });
  };

  const [add_Timesheet] = useMutation(addTimesheet, {
    onCompleted(data) {
      postoperationfnc();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const [update_Timesheet] = useMutation(updateTimesheet, {
    onCompleted(data) {
      postoperationfnc();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const submitUpdateTimesheet = entry => {
    if (entry && entry.hours > 0) {
      date = moment(utcweek)
        .day('monday')
        .format('MM/DD/YYYY');

      data = {
        ref: entry._id,
        hours: parseFloat(entry.hours),
        note: entry.note ? entry.note : '',
        mm_dd_yyyy: date
      };
    }
    update_Timesheet({
      variables: {
        ...data,
        job_reference: job_id_param
      }
    });
  };

  const submitAddTimesheet = entry => {
    if (entry && entry.hours > 0) {
      data = {
        job_reference: job_id_param,
        job_container_reference: entry.container_ref,
        hours: parseFloat(entry.hours),
        note: entry.note,
        mm_dd_yyyy: moment(utcweek).format('MM/DD/YYYY')
      };
    }
    add_Timesheet({
      variables: data
    });
  };

  useEffect(() => {
    setRow(activeRow);

    // console.log(date, 'activeRow');
    if (activeRow.year) {
      date = moment(activeRow.year)
        .week(activeRow.for_week)
        .day('monday');
    }
    const utctime = Date.parse(date);
    setUTCweek(utctime);
  }, [activeRow, open]);

  const handleChange = event => {
    setRow({ ...row, [event.target.name]: event.target.value });
  };

  const handleSave = () => {
    if (activeRow !== row) {
      if (operation === 'Add') {
        submitAddTimesheet(row);
      }
      if (operation === 'Edit') {
        submitUpdateTimesheet(row);
      }
    }
    setRow('');
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Time Sheet - '}
        {job_title_param}
        <IconButton
          onClick={handleClose}
          size="large"
          style={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ 'margin-top': '-10px' }}>
          <Grid item xs={9}>
            {/* <WeekSelector onAccept={dateChanged} date={utcweek} /> */}
            <WeekSelector2 date={utcweek} dateChanged={dateChanged} />
            <FormHelperText>* Required</FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Hours"
              name="hours"
              type="number"
              onChange={handleChange}
              value={row.hours}
              style={{ width: '100%' }}
              InputProps={{
                inputProps: { min: 0, max: 100, step: 0.5 }
              }}
            />
            <FormHelperText>* Required</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={row.note}
              onChange={handleChange}
              label="Description"
              name="note"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          {activeRow.hours ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimesheetForm;
