import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AuthContext from 'src/components/AuthContext';

import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation } from '@apollo/client';
import { addJobContainer } from 'src/queries/query';

import TextEditor from '../../../components/TextEditor';

const Client = gql`
  query Client($id: ID) {
    Client(_id: $id) {
      _id
      name
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({ stage, dataJobRequest, ...rest }) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  // console.log(userData.first_name);

  const [state, setState] = useState({
    client_ref: '',
    client_name: '',
    title: dataJobRequest.title
  });

  // We need to spread the previous state and change the one we're targeting, so other data cannot be lost.
  const handleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [
    add_JobContainer,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addJobContainer, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        navigate('/masterjobs/live');
      }, 2500);
    },
    onError: err => {
      setError(err);
      // setErrorMessage(error.message.split('GraphQL error: ')[1])
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [jobDesc, setJobDesc] = useState('');

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_JobContainer({
            variables: {
              ref: '',
              data: {
                title: state.title,
                description: jobDesc,
                total_budget: parseFloat(e.target.total_budget.value),
                start_date: e.target.start_date.value
                  ? e.target.start_date.value
                  : new Date(),
                end_date: e.target.end_date.value,
                ratecard: null,
                stage: 'live',
                is_requested_job: true,
                job_request_number: dataJobRequest.request_number,
                client: {
                  client_ref: dataJobRequest.client._id,
                  client_name: dataJobRequest.client.name
                }
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Add New Job"
            classes={{
              action: classes.cardAction
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  label="Job title"
                  name="title"
                  required
                  variant="outlined"
                  value={state.title}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label={state.approved ? 'Total Budget' : 'Estimated Budget'}
                  required
                  name="total_budget"
                  variant="outlined"
                  defaultValue={dataJobRequest.amount}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                  disabled="true"
                />
              </Grid>
              <Grid item xs={12}>
                <TextEditor textEditorOutput={textEditorOutput} />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Client"
                  name="title"
                  required
                  variant="outlined"
                  value={dataJobRequest.client.name}
                  InputProps={{
                    readOnly: true
                  }}
                  disabled="true"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Delivery Date"
                  name="end_date"
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
