import React from 'react';
import { Box, Container, Alert } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocation, useSearchParams } from 'react-router-dom';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  alert: {
    marginBottom: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const stage = location.pathname.split('/').pop();
  const jobNotFound = searchParams.get('jobNotFound');

  return (
    <Page className={classes.root} title="Jobs">
      <Container maxWidth={false}>
        {jobNotFound && (
          <Alert severity="error" className={classes.alert}>
            Job [{jobNotFound}] does not exist
          </Alert>
        )}
        <Toolbar />
        <Box mt={3}>
          <Results stage={stage} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
