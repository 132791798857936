import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation } from '@apollo/client';
import { updateRole, Department } from 'src/queries/query';

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RoleDetails = ({ className, id, departmenttrole, ...rest }) => {
  const { classes } = useStyles();

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [
    update_Role,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateRole, {
    onCompleted() {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);

        // Close the accordion by triggering its change handler
        if (rest.onAccordionClose) {
          rest.onAccordionClose();
        }
      }, 2500);
    },
    onError: err => {
      setError(err);
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    },
    // Update the cache to immediately reflect the role title change
    update(cache, { data }) {
      try {
        // Read the current department from the cache
        const existingData = cache.readQuery({
          query: Department,
          variables: { id }
        });

        if (existingData && existingData.Department) {
          // Map through roles to update the specific one
          const updatedRoles = existingData.Department.roles.map(role => {
            if (role._id === departmenttrole._id) {
              // Return updated role with new title
              return {
                ...role,
                title: document.querySelector('input[name="title"]').value
              };
            }
            return role;
          });

          // Create updated department object
          const updatedDepartment = {
            ...existingData.Department,
            roles: updatedRoles
          };

          // Write back the updated data to the cache
          cache.writeQuery({
            query: Department,
            variables: { id },
            data: {
              Department: updatedDepartment
            }
          });
        }
      } catch (error) {
        console.error('Error updating cache:', error);
      }
    },
    // Refetch query as a backup
    refetchQueries: [
      {
        query: Department,
        variables: { id }
      }
    ]
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          update_Role({
            variables: {
              ref: departmenttrole._id,
              data: {
                title: e.target.title.value
              }
            }
          });
        }}
      >
        <Card>
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  required
                  defaultValue={departmenttrole.title}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default RoleDetails;
