import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import { Box, Card, CardHeader, Avatar, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@apollo/client';
import AuthContext from 'src/components/AuthContext';
import TruncatedText from 'src/components/TruncatedText';
import gql from 'graphql-tag';
import moment from 'moment';
import TimesheetForm from './TimesheetForm';

const CurrentJobsQuery = gql`
  query DashboardJobs($id: String) {
    DashboardJobs(user_reference: $id) {
      _id
      subscription_key
      job_number
      title
      url
      description
      cloud_portal
      job_type
      retainer_budget
      allotted_budget
      container_ref
      container_job_number
      container_title
      stage
      department
      start_date
      end_date
      owner_reference
      owner_name
      ratecard_reference
      estimated_time
      estimated_ftc
      total_revrec
      available_for_estimate
      available_for_revrec
      supplier_po_total
      client {
        client_name
      }
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      justifyContent: 'flex-end'
    },
    avatar: {
      marginRight: 2
    }
  };
});

const CurrentJobs = ({ className, postoperationfnc, ...rest }) => {
  const { classes, cx } = useStyles();
  const { userData } = useContext(AuthContext);
  const [currentJobsList, setCurrentJobsList] = useState([]);

  const [activeRow, setActiveRow] = useState('');
  const [job_id, setJob_id] = useState('');
  const [job_title, setJob_title] = useState('');
  const [open, setOpen] = useState(false);

  const CurrentJobsResults = useQuery(CurrentJobsQuery, {
    variables: { id: userData._id },
    fetchPolicy: 'cache-and-network' // This ensures we always get fresh data
  });

  // Refetch on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        await CurrentJobsResults.refetch();
      } catch (error) {
        console.error('Error refetching data:', error);
      }
    };
    fetchData();
  }, []); // Empty dependency array means this runs on mount

  useEffect(() => {
    if (!CurrentJobsResults.loading && CurrentJobsResults.data) {
      setCurrentJobsList(CurrentJobsResults.data.DashboardJobs);
    }
  }, [CurrentJobsResults.data]);

  const handleClickOpen = row => {
    setJob_id(row._id);
    setJob_title(row.title);
    setActiveRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setJob_id(0);
    setJob_title('');
    setActiveRow([]);
    setOpen(false);
  };

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color="textPrimary" variant="body1">
            <Link to={`/subjob/${row.original.job_number}`}>
              <TruncatedText text={row.original.title} maxLength={150} />
            </Link>
          </Typography>
        </Box>
      ),
      size: 250
    },
    {
      accessorKey: 'container_title',
      header: 'Master Job',
      Cell: ({ row }) => (
        <Link to={`/masterjob/${row.original.container_job_number}`}>
          {row.original.container_title}
        </Link>
      )
    },
    {
      accessorKey: 'client.client_name',
      header: 'Client'
    },
    {
      accessorKey: 'owner_name',
      header: 'Team Leader'
    },
    {
      accessorKey: 'stage',
      header: 'Stage'
    },
    {
      accessorKey: 'end_date',
      header: 'TX Date',
      Cell: ({ row }) =>
        row.original.end_date
          ? moment.unix(row.original.end_date / 1000).format('DD.MM.YYYY')
          : '-'
    }
  ];

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <Box>
        <MaterialReactTable
          renderTopToolbarCustomActions={() => (
            <Typography variant="h5" component="div" sx={{ pt: 1 }}>
              Current Sub Jobs
            </Typography>
          )}
          columns={columns}
          data={currentJobsList || []}
          enableColumnFilters
          enableColumnActions={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding
          initialState={{
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 10 },
            showColumnFilters: false
          }}
        />
      </Box>
      {activeRow && (
        <TimesheetForm
          open={open}
          handleClose={handleClose}
          activeRow={activeRow}
          operation="Add"
          postoperationfnc={postoperationfnc}
          job_id_param={job_id}
          job_title_param={job_title}
        />
      )}
    </Card>
  );
};

CurrentJobs.propTypes = {
  className: PropTypes.string
};

export default CurrentJobs;
