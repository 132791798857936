import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  LinearProgress,
  InputAdornment,
  ClickAwayListener,
  Alert,
  AlertTitle,
  IconButton,
  Box
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import currencyformatter from 'src/utils/currencyformatter';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const JobsContainers = gql`
  query JobsContainers($id: ID) {
    JobsContainers(_id: $id) {
      _id
      title
      total_budget
      units {
        _id
        allocated_budget
        jobunit_ref {
          title
          stage
          _id
        }
      }
      remaining
    }
  }
`;

const useStyles = makeStyles()(() => ({
  popper: {
    zIndex: 1300,
    width: 600,
    maxWidth: '90%',
    '& .MuiPaper-root': {
      padding: '16px',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
    }
  },
  paper: {
    padding: 16
  },
  marginList: {
    marginRight: '10px'
  },
  txtRight: {
    marginLeft: '10px',
    textAlign: 'right'
  },
  widthProgress: {
    width: '100%'
  },
  fullProgress: {
    width: '100%',
    margin: '0 auto',
    '& $widthProgress': {
      width: '100%'
    }
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  adjustButton: {
    minWidth: '30px',
    width: '30px',
    height: '22px',
    padding: 0
  }
}));

const JUBudgetDetails = ({
  className,
  budget,
  elementID,
  containerID,
  showForm,
  sendChildToParent,
  available_for_estimate,
  estimated_ftc,
  estimated_time,
  estimated_budget_allocation,
  showContainer,
  time_estimate_switch,
  stage,
  onBudgetChange,
  ...rest
}) => {
  const { classes } = useStyles();
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(budget);
  const [updateValue, setUpdateValue] = useState('0');
  const [errorBudget, setErrorBudget] = useState('');
  const [container_allocated, setContainer_allocated] = useState('');
  const [initialsingle_budget, setInitialsingle_budget] = useState(budget);
  const [initialtotal_allocated, setInitialtotal_allocated] = useState(0);
  const [updatedtotal_allocated, setUpdatedtotal_allocated] = useState(0);

  const { data: dataJobContainer, loading: loadingJobContainer } = useQuery(
    JobsContainers,
    {
      skip: !showContainer,
      variables: { id: containerID },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (showContainer && !loadingJobContainer && dataJobContainer) {
      setContainer_allocated(dataJobContainer.JobsContainers[0].total_budget);
      const total = dataJobContainer.JobsContainers[0].units.reduce(
        (acc, item) => acc + item.allocated_budget,
        0
      );
      setInitialtotal_allocated(total);
    }
  }, [loadingJobContainer, dataJobContainer, showContainer]);

  useEffect(() => {
    setUpdatedtotal_allocated(
      initialtotal_allocated -
        initialsingle_budget +
        parseFloat(inputValue || 0)
    );
  }, [inputValue, initialsingle_budget, initialtotal_allocated]);

  const handleBudgetChange = event => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setErrorBudget('');

    if (newValue === '') return;

    const numericValue = parseFloat(newValue);
    if (!Number.isNaN(numericValue)) {
      if (numericValue < 0) {
        setErrorBudget('Budget cannot be negative');
      } else if (
        showContainer &&
        numericValue >
          container_allocated - (initialtotal_allocated - initialsingle_budget)
      ) {
        setErrorBudget('Budget exceeds available master budget');
      } else {
        // Call the parent's onBudgetChange handler with the new valid value
        onBudgetChange(numericValue);
      }
    }
  };

  const handleUpdateValueChange = event => {
    const newValue = event.target.value;
    if (parseFloat(newValue) < 0 && newValue !== '') {
      setUpdateValue('0');
    } else {
      setUpdateValue(newValue);
    }
  };

  const handleAdjustBudget = increment => {
    const adjustmentValue = parseFloat(updateValue) || 0;
    if (adjustmentValue === 0) return;

    const currentBudget = parseFloat(inputValue) || 0;
    const newBudget = increment
      ? currentBudget + adjustmentValue
      : currentBudget - adjustmentValue;

    // Check if the new budget is valid
    if (newBudget < 0) {
      setErrorBudget('Budget cannot be negative');
      return;
    }

    if (
      showContainer &&
      newBudget >
        container_allocated - (initialtotal_allocated - initialsingle_budget)
    ) {
      setErrorBudget('Budget exceeds available master budget');
      return;
    }

    // Update the input value
    setInputValue(newBudget.toString());

    // Call the parent's onBudgetChange handler with the new valid value
    onBudgetChange(newBudget);

    setUpdateValue('0');
  };

  const currentValue = parseFloat(inputValue) || 0;
  const totalAllocated =
    estimated_time + estimated_ftc + estimated_budget_allocation;
  const remainingBudget = currentValue - totalAllocated;

  const handleClickAway = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
      setIsEditing(false);
    }
  };

  const handleFocus = () => {
    if (stage === 'complete') return;
    setOpen(true);
    setIsEditing(true);
    if (parseFloat(inputValue) === 0) {
      setInputValue('');
    }
  };

  const handleBlur = () => {
    setOpen(false);
    setIsEditing(false);
  };

  useEffect(() => {
    setInputValue(budget);
    setInitialsingle_budget(budget);
  }, [budget]);

  // Budget information component that's shown either in the tooltip or directly
  const BudgetInfoContent = () => (
    <List>
      {showContainer && dataJobContainer?.JobsContainers[0].units.length > 0 && (
        <>
          <ListItem>
            <ListItemIcon className={classes.marginList}>
              Master Job Budget
            </ListItemIcon>
            <ListItemText
              className={classes.txtRight}
              primary={currencyformatter.format(
                dataJobContainer.JobsContainers[0].total_budget
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.marginList}>
              Remaining Master Job Budget
            </ListItemIcon>
            <ListItemText
              className={classes.txtRight}
              primary={currencyformatter.format(
                container_allocated - updatedtotal_allocated
              )}
            />
          </ListItem>
          <Divider />
        </>
      )}

      <ListItem>
        <ListItemIcon className={classes.marginList}>
          Sub Job Budget
        </ListItemIcon>
        <ListItemText
          className={classes.txtRight}
          primary={currencyformatter.format(currentValue)}
        />
      </ListItem>

      <ListItem>
        <ListItemIcon className={classes.marginList}>
          Department Budget Allocation
        </ListItemIcon>
        <ListItemText
          className={classes.txtRight}
          primary={currencyformatter.format(estimated_budget_allocation)}
        />
      </ListItem>

      {time_estimate_switch && (
        <ListItem>
          <ListItemIcon className={classes.marginList}>
            Estimated Time Cost
          </ListItemIcon>
          <ListItemText
            className={classes.txtRight}
            primary={currencyformatter.format(estimated_time)}
          />
        </ListItem>
      )}

      <ListItem>
        <ListItemIcon className={classes.marginList}>
          Estimated Third Party Cost
        </ListItemIcon>
        <ListItemText
          className={classes.txtRight}
          primary={currencyformatter.format(estimated_ftc)}
        />
      </ListItem>

      <Divider />

      {!showContainer && (
        <>
          <ListItem>
            <ListItemIcon className={classes.marginList}>
              Total Amount Allocated
            </ListItemIcon>
          </ListItem>

          <ListItem>
            <ListItemIcon className={classes.fullProgress}>
              <LinearProgress
                className={classes.widthProgress}
                value={(totalAllocated * 100) / currentValue}
                variant="determinate"
              />
            </ListItemIcon>
          </ListItem>

          <ListItem>
            <ListItemIcon className={classes.marginList}>
              {`${currencyformatter.format(
                totalAllocated
              )} of ${currencyformatter.format(currentValue)}`}
            </ListItemIcon>
            <ListItemText
              className={classes.txtRight}
              primary={`${currencyformatter.format(remainingBudget)} left`}
            />
          </ListItem>
        </>
      )}

      {showContainer && dataJobContainer?.JobsContainers[0].units.length > 0 && (
        <>
          <ListItem>
            <ListItemIcon className={classes.marginList}>
              Sub Job Budget
            </ListItemIcon>
          </ListItem>

          <ListItem>
            <ListItemIcon className={classes.fullProgress}>
              <LinearProgress
                className={classes.widthProgress}
                value={(updatedtotal_allocated * 100) / container_allocated}
                variant="determinate"
              />
            </ListItemIcon>
          </ListItem>

          <ListItem>
            <ListItemIcon className={classes.marginList}>
              {`${currencyformatter.format(
                currentValue
              )} of ${currencyformatter.format(
                dataJobContainer.JobsContainers[0].total_budget
              )}`}
            </ListItemIcon>
            <ListItemText
              className={classes.txtRight}
              primary={`${currencyformatter.format(
                container_allocated - updatedtotal_allocated
              )} left`}
            />
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <div ref={containerRef}>
      {/* Only render the input field if showForm is true */}
      {showForm ? (
        <>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            <TextField
              fullWidth
              required
              label="Allocated Budget"
              name="allotted_budget"
              value={
                isEditing ? inputValue : currencyformatter.format(currentValue)
              }
              onChange={handleBudgetChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              error={!!errorBudget}
              helperText={errorBudget}
              variant="outlined"
              type={isEditing ? 'number' : 'text'}
              disabled={stage === 'complete'}
              InputProps={
                isEditing
                  ? {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ mr: 0, mt: 0.1 }}
                        >
                          £
                        </InputAdornment>
                      ),
                      inputProps: {
                        step: parseFloat(updateValue) || 1
                      }
                    }
                  : {
                      inputProps: {
                        step: parseFloat(updateValue) || 1
                      }
                    }
              }
            />

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Update Budget By"
                name="update_budget"
                value={updateValue}
                onChange={handleUpdateValueChange}
                variant="outlined"
                type="number"
                disabled={stage === 'complete'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0, mt: 0.1 }}>
                      £
                    </InputAdornment>
                  )
                }}
                sx={{ width: '150px' }}
              />

              <Box className={classes.buttonContainer} sx={{ ml: 1 }}>
                <IconButton
                  size="small"
                  className={classes.adjustButton}
                  onClick={() => handleAdjustBudget(true)}
                  disabled={stage === 'complete'}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  className={classes.adjustButton}
                  onClick={() => handleAdjustBudget(false)}
                  disabled={stage === 'complete'}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Popper
            open={open}
            anchorEl={containerRef.current}
            placement="bottom-start"
            className={classes.popper}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className={classes.paper} elevation={4}>
                <BudgetInfoContent />
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      ) : (
        // If showForm is false, display the budget info directly inline
        <div className={classes.inlineBudgetInfo}>
          <BudgetInfoContent />
        </div>
      )}

      {errorBudget && (
        <Alert severity="error">
          <AlertTitle>{errorBudget}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

JUBudgetDetails.defaultProps = {
  showForm: false,
  showContainer: true,
  sendChildToParent: () => {},
  onBudgetChange: () => {},
  budget: '',
  time_estimate_switch: false
};

export default JUBudgetDetails;
