import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Autocomplete from '@mui/material/Autocomplete';

import Switch from '@mui/material/Switch';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation, useQuery } from '@apollo/client';
import {
  updateDepartment,
  Departments as DepartmentsList,
  Department
} from 'src/queries/query';

import AuthContext from 'src/components/AuthContext';

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({ className, element, ...rest }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const [state, setState] = useState({
    sub_department: element.is_sub_department,
    parent_ref: element.parent_ref || ''
  });

  const {
    data: dataMainDepartment,
    loading: loadingMainDepartment,
    error: errorMainDepartment
  } = useQuery(DepartmentsList, {
    variables: { is_sub_department: false }
  });

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Helper function to update department in list cache
  const updateDepartmentInListCache = (cache, searchParams) => {
    try {
      const existingData = cache.readQuery({
        query: DepartmentsList,
        variables: searchParams
      });

      if (existingData && existingData.Department) {
        // Map through departments to update the specific one
        const updatedDepartments = existingData.Department.map(dept => {
          if (dept._id === element._id) {
            // Return department with updated values
            return {
              ...dept,
              name: document.querySelector('input[name="name"]').value,
              is_sub_department: state.sub_department,
              parent_ref: state.parent_ref || dept.parent_ref
            };
          }
          return dept;
        });

        // Write back the updated data
        cache.writeQuery({
          query: DepartmentsList,
          variables: searchParams,
          data: {
            Department: updatedDepartments
          }
        });
      }
    } catch (error) {
      console.error('Error updating list cache:', error);
    }
  };

  const [
    update_Department,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(updateDepartment, {
    onCompleted(data) {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        navigate('/departments');
      }, 2500);
    },
    onError: err => {
      setError(err);
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    },
    // Update the cache and refetch queries to ensure UI is updated
    update(cache, { data: { updateDepartment } }) {
      try {
        // Update main departments list
        updateDepartmentInListCache(cache, { is_sub_department: false });

        // Update sub-departments list if applicable
        if (element.parent_ref) {
          updateDepartmentInListCache(cache, {
            is_sub_department: true,
            parent_ref: element.parent_ref
          });
        }

        // Also update the specific department detail view
        const existingDepartment = cache.readQuery({
          query: Department,
          variables: { id: element._id }
        });

        if (existingDepartment && existingDepartment.Department) {
          cache.writeQuery({
            query: Department,
            variables: { id: element._id },
            data: {
              Department: {
                ...existingDepartment.Department,
                name: document.querySelector('input[name="name"]').value,
                is_sub_department: state.sub_department,
                parent_ref:
                  state.parent_ref || existingDepartment.Department.parent_ref
              }
            }
          });
        }
      } catch (error) {
        console.error('Error updating cache:', error);
      }
    },
    // Refetch queries as a backup to ensure data consistency
    refetchQueries: [
      {
        query: DepartmentsList,
        variables: { is_sub_department: false }
      },
      {
        query: DepartmentsList,
        variables: {
          is_sub_department: true,
          parent_ref: element.parent_ref
        }
      },
      {
        query: Department,
        variables: { id: element._id }
      }
    ]
  });

  const handleChangeSwitch = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>

      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          update_Department({
            variables: {
              ref: element._id,
              data: {
                name: e.target.name.value,
                parent_ref: state.parent_ref,
                is_sub_department: state.sub_department
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader title="Department" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Department Name"
                  name="name"
                  required
                  defaultValue={element.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Department type</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.sub_department}
                          onChange={handleChangeSwitch}
                          name="sub_department"
                        />
                      }
                      label="Sub Department"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {state.sub_department &&
                !loadingMainDepartment &&
                dataMainDepartment && (
                  <Grid item xs={12}>
                    <Autocomplete
                      id="ParentDepartment"
                      defaultValue={
                        element.parent_ref && dataMainDepartment.Department
                          ? dataMainDepartment.Department.find(
                              item => item._id === element.parent_ref
                            )
                          : null
                      }
                      options={dataMainDepartment.Department || []}
                      getOptionLabel={option => option.name}
                      onChange={(event, values) => {
                        setState({
                          ...state,
                          parent_ref: values ? values._id : ''
                        });
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          label="Parent Department"
                          name="parent_ref"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
