import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Button, List, ListItem, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  subitems: {
    marginLeft: theme.spacing(4)
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0,
    border: 0,
    '&.active': {
      color: theme.palette.primary.main,
      borderRight: `solid 2px ${theme.palette.primary.main}`,
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      },
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  }
}));

const NavItem = ({ className, href, icon: Icon, title, subitems, ...rest }) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const isActive = React.useMemo(() => {
    if (!subitems) {
      return location.pathname === href;
    }
    return subitems.some(subitem => location.pathname === subitem.href);
  }, [location.pathname, href, subitems]);

  const handleClick = () => {
    if (subitems) {
      setOpen(!open);
    }
  };

  return (
    <>
      <ListItem
        className={cx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          className={cx(classes.button, { active: isActive })}
          component={subitems ? 'div' : RouterLink}
          to={!subitems ? href : undefined}
          onClick={handleClick}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {subitems && (open ? <ExpandLess /> : <ExpandMore />)}
        </Button>
      </ListItem>
      {subitems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subitems}>
            {subitems.map(subitem => (
              <ListItem
                key={subitem.title}
                className={cx(classes.item, className)}
                disableGutters
                {...rest}
              >
                <Button
                  className={cx(classes.button, {
                    active: location.pathname === subitem.href
                  })}
                  component={RouterLink}
                  to={subitem.href}
                >
                  <span className={classes.title}>{subitem.title}</span>
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  subitems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string
    })
  )
};

export default NavItem;
