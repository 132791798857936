import React from 'react';
import { useParams, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Container, Grid, Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';

const get_container = gql`
  query JobsContainers($job_number: String) {
    JobsContainers(job_number: $job_number) {
      _id
      title
      job_number
      ratecard
      client {
        client_ref
        client_name
      }
      units {
        _id
        allocated_budget
        jobunit_ref {
          title
          job_type
        }
      }
      remaining
      total_budget
      stage
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    link: {
      '&:hover': { textDecoration: 'underline' }
    }
  };
});

const JobUnit = () => {
  const { classes } = useStyles();
  const job_number = useParams();

  const { data, loading, error } = useQuery(get_container, {
    variables: { job_number: job_number.id }
  });

  return (
    <Page className={classes.root} title="New Job">
      {!loading && !error && data && (
        <>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box alignItems="start" display="flex" flexDirection="column">
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    <Link
                      className={classes.link}
                      to={`/masterjobs/${data.JobsContainers[0].stage}`}
                    >
                      Jobs
                    </Link>{' '}
                    /{' '}
                    <Link
                      className={classes.link}
                      to={`/masterjob/${job_number.id}`}
                    >
                      {data.JobsContainers[0].title}
                    </Link>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <ProfileDetails
                  id={data.JobsContainers[0]._id}
                  container={data.JobsContainers[0]}
                />
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Page>
  );
};

export default JobUnit;
