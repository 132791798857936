import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation } from '@apollo/client';
import { addSupplier } from 'src/queries/query';
import { useAlert } from '../../../utils/AlertSystem';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileDetails = ({ className, ...rest }) => {
  const { showAlert } = useAlert();
  const link = '/clients/add-new';
  const linkList = '/clients';

  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const [add_Supplier, { error: mutationError }] = useMutation(addSupplier, {
    onCompleted() {
      showAlert('Client added successfully', 'success');
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
        setShowBtns(true);
        navigate('/suppliers');
      }, 2500);
    },
    onError: err => {
      const errorMessage = err.message.includes('GraphQL error:')
        ? err.message.split('GraphQL error: ')[1]
        : err.message;
      showAlert(errorMessage, 'error');
      setError(err);
      // setErrorMessage(error.message.split('GraphQL error: ')[1])
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>
      <form
        {...rest}
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_Supplier({
            variables: {
              ref: '',
              data: {
                company_name: e.target.name.value,
                category: e.target.category.value,
                first_name: e.target.first_name.value,
                last_name: e.target.last_name.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                fax: e.target.fax.value,
                address_line1: e.target.address_line1.value,
                address_line2: e.target.address_line2.value,
                city: e.target.city.value,
                county: e.target.county.value,
                country: e.target.country.value,
                postcode: e.target.postcode.value,
                url: e.target.url.value,
                other: e.target.other.value
              }
            }
          });
        }}
      >
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Add New Supplier"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the name"
                  label="Supplier Name"
                  name="name"
                  type="text"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    labelId="category-label"
                    label="Category"
                    name="category"
                    defaultValue=""
                  >
                    <MenuItem value="Company">Company</MenuItem>
                    <MenuItem value="Sister Company">Sister Company</MenuItem>
                    <MenuItem value="Freelancer">Freelancer</MenuItem>
                    <MenuItem value="CreditCard">Credit Card</MenuItem>
                    <MenuItem value="Manufacture">Manufacture</MenuItem>
                    <MenuItem value="Retailers">Retailers</MenuItem>
                    <MenuItem value="Wholesalers">Wholesalers</MenuItem>
                    <MenuItem value="Distributors">Distributors</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="first_name"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="last_name"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 1"
                  name="address_line1"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 2"
                  name="address_line2"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="City"
                  name="city"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="County"
                  name="county"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Postcode"
                  name="postcode"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="URL"
                  name="url"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fax"
                  name="fax"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Other Info"
                  name="other"
                  type="textarea"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            p={2}
          >
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProfileDetails;
