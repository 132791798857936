import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

// https://www.material-react-table.com/
import MaterialReactTable from 'material-react-table';

import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Tooltip
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LockIcon from '@mui/icons-material/Lock';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AuthContext from 'src/components/AuthContext';

import Notes from 'src/views/notes/Notes';
import { before, min } from 'lodash';

const addJobForecast = gql`
  mutation addJobForecast($ref: ID!, $data: revrecInput!) {
    addJobForecast(ref: $ref, data: $data) {
      allotted_budget
    }
  }
`;

const updateJobForecast = gql`
  mutation updateJobForecast($ref: ID!, $amount: Float!) {
    updateJobForecast(ref: $ref, amount: $amount) {
      allotted_budget
    }
  }
`;

const RecogniseMonth = gql`
  mutation RecogniseMonth($data: revrecStatusInput!) {
    RecogniseMonth(data: $data) {
      _id
    }
  }
`;

const RevRec = gql`
  query Jobs($id: ID, $job_type: String) {
    Jobs(_id: $id, job_type: $job_type) {
      _id
      job_number
      title
      container_title
      container_job_number
      client {
        client_ref
        client_name
      }
      allotted_budget
      estimated_ftc
      total_revrec
      total_forecast
      available_for_estimate
      available_for_revrec
      total_revrec_and_forecast
      revrec {
        _id
        column_title
        month
        amount
      }
    }
  }
`;

const RecognisedMonths = gql`
  query RecognisedMonths {
    RecognisedMonths {
      column_title
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& table': {
        borderCollapse: 'separate',
        borderSpacing: 0
      }
    }
  };
});

const TruncatedText = ({ text, maxLength = 100 }) => {
  const shouldTruncate = text.length > maxLength;
  const displayText = shouldTruncate ? `${text.slice(0, maxLength)}...` : text;

  return shouldTruncate ? (
    <Tooltip title={text} arrow placement="top">
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',
          maxWidth: `${maxLength * 0.3}em` // Dynamically set maxWidth based on maxLength
        }}
      >
        {displayText}
      </span>
    </Tooltip>
  ) : (
    <span>{displayText}</span>
  );
};

const Results = () => {
  const year_start_month = 3; //Recipe's year starting from April(3 => 0,1,2,3) need to make this a variable for other companies
  const date_obj = new Date();
  const param_year = useParams();
  const current_month = date_obj.getMonth();
  let isCurrentYear = true;

  let var_financial_year = parseInt(date_obj.getFullYear());

  if (param_year.year) {
    var_financial_year = param_year.year;
    isCurrentYear =
      parseInt(param_year.year) === parseInt(date_obj.getFullYear())
        ? true
        : false;
  } else {
    isCurrentYear = true;
    if (current_month <= year_start_month) {
      var_financial_year = parseInt(date_obj.getFullYear()) - 1;
    }
  }
  //  var_financial_year = param_year.year
  //   ? param_year.year
  //   : date_obj.getFullYear();
  // console.log('var_financial_year1 ===> ', var_financial_year);
  // console.log('var_financial_year2 ===> ', date_obj.getFullYear());
  // console.log('current month ===> ', current_month);
  // //2025
  // if (
  //   var_financial_year === date_obj.getFullYear() &&
  //   current_month <= year_start_month
  // ) {
  //   console.log('executed');
  //   var_financial_year -= 1;
  // }

  // console.log('after if statement ', var_financial_year);
  // if (param_year.year) {
  //   isCurrentYear =
  //     parseInt(param_year.year) === parseInt(date_obj.getFullYear())
  //       ? true
  //       : false;
  // }
  const [selectedYear, setSelectedYear] = useState(var_financial_year);
  const navigate = useNavigate();

  const handleYearChange = event => {
    const year = event.target.value;
    setSelectedYear(year);
    window.location.href = `/revrec/${year}`;
  };

  const currentYear = new Date().getFullYear();
  // list of years for the dropdown
  const years = Array.from({ length: 7 }, (_, i) => currentYear - 5 + i);

  const getFinancialYear = () => {
    const temp_year = parseInt(var_financial_year) + 1;
    return var_financial_year + '-' + temp_year;
  };
  // Function to get full month name from title like "Jan 2023" => January
  const getMonthName = columnid => {
    const monthNames = [
      { short: 'Jan', full: 'January' },
      { short: 'Feb', full: 'February' },
      { short: 'Mar', full: 'March' },
      { short: 'Apr', full: 'April' },
      { short: 'May', full: 'May' },
      { short: 'Jun', full: 'June' },
      { short: 'Jul', full: 'July' },
      { short: 'Aug', full: 'August' },
      { short: 'Sep', full: 'September' },
      { short: 'Oct', full: 'October' },
      { short: 'Nov', full: 'November' },
      { short: 'Dec', full: 'December' }
    ];

    const result_temp = monthNames.find(
      ({ short }) => short === columnid.split(' ')[0]
    );
    const MonthName = result_temp ? result_temp.full : 'no answer';

    return MonthName;
  };

  const [open, setOpen] = React.useState(false);
  const [hidecol, setHidecol] = React.useState(true);
  const { userData } = useContext(AuthContext);
  const [datatable, setDatatable] = useState([]);
  // const [recognisemonthcaller, setRecognisemonthcaller] = useState(0);
  const [dRecognisedMonths, setDRecognisedMonths] = useState([]);
  const [totalallotted_budget, setTotalallotted_budget] = useState();

  const currencyFormat = {
    Cell: ({ cell }) => (
      <>
        {cell.getValue()?.toLocaleString?.('en-UK', {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}
      </>
    )
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [
    add_JobForecast,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addJobForecast, {
    onCompleted() {
      // console.log('added forecast');
      // return 'JobForecast';
    },
    onError: err => {
      console.log(err);
      // return 'error in JobForecast';
    },
    refetchQueries: [{ query: RevRec }]
  });

  const [
    update_JobForecast,
    { loading: mutationupdateLoading, error: mutationupdateError }
  ] = useMutation(updateJobForecast, {
    onCompleted() {
      console.log('updated forecast');
    },
    onError: err => {
      console.log(err);
    },
    refetchQueries: [{ query: RevRec }]
  });

  const [
    Recognise_Month,
    { loading: mutationRecogniseLoading, error: mutationRecogniseError }
  ] = useMutation(RecogniseMonth, {
    onCompleted() {
      // setRecognisemonthcaller(recognisemonthcaller + 1);
      window.location.reload();
    },
    onError: err => {
      console.log(err);
    },
    refetchQueries: [{ query: RevRec }] //RecognisedMonths
  });

  ////////////////////////////////
  //
  // to be considered for infinite scroll
  // https://www.material-react-table.com/docs/guides/row-virtualization#what-is-virtualization?
  //
  //
  // good grouping example
  //https://codesandbox.io/s/github/KevinVandy/material-react-table/tree/main/material-react-table-docs/examples/aggregation-and-grouping/sandbox?file=/src/JS.js:3666-3728
  //
  ////////////////////////////////

  //********************************************** */
  // get jobs
  const {
    loading: loadingRecognisedMonths,
    data: dataRecognisedMonths,
    error: errorRecognisedMonths
  } = useQuery(RecognisedMonths);

  // callback for above query
  useEffect(() => {
    const onCompleted = dataRecognisedMonths => {
      //chage data to prepare for the table here
      const newData = [];
      const tempData = [];
      dataRecognisedMonths.RecognisedMonths.map((rmonth, index) => {
        tempData.push(rmonth.column_title);
      });
      setDRecognisedMonths(tempData);
    };
    const onError = errorRecognisedMonths => {
      console.log('query errorRecognisedMonths', errorRecognisedMonths);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRecognisedMonths && !errorRecognisedMonths) {
        onCompleted(dataRecognisedMonths);
      } else if (onError && !loadingRecognisedMonths && errorRecognisedMonths) {
        onError(errorRecognisedMonths);
      }
    }
  }, [loadingRecognisedMonths]);

  // useEffect(() => {
  //   console.log('recognisemonthcaller called');
  //   const onCompleted = dataRecognisedMonths => {
  //     //chage data to prepare for the table here
  //     const newData = [];
  //     const tempData = [];
  //     dataRecognisedMonths.RecognisedMonths.map((rmonth, index) => {
  //       tempData.push(rmonth.column_title);
  //     });
  //     setDRecognisedMonths(tempData);
  //   };
  //   const onError = errorRecognisedMonths => {
  //     console.log('query errorRecognisedMonths', errorRecognisedMonths);
  //   };
  //   if (onCompleted || onError) {
  //     if (onCompleted && !loadingRecognisedMonths && !errorRecognisedMonths) {
  //       onCompleted(dataRecognisedMonths);
  //     } else if (onError && !loadingRecognisedMonths && errorRecognisedMonths) {
  //       onError(errorRecognisedMonths);
  //     }
  //   }
  // }, [recognisemonthcaller]);

  //********************************************** */

  // get jobs
  const {
    loading: loadingRevRec,
    data: dataRevRec,
    error: errorRevRec,
    refetch
  } = useQuery(RevRec, {
    variables: { job_type: 'Normal' }
  });

  // callback for jobs query
  useEffect(() => {
    const onCompleted = dataRevRec => {
      //chage data to prepare for the table here
      const newData = [];
      let tempData = [];
      dataRevRec.Jobs.map((job, index) => {
        tempData = { ...job };
        job.revrec.map((revrec, index) => {
          const month = revrec.column_title;
          const amount = revrec.amount;
          tempData = { ...tempData, [month]: amount };
        });
        newData.push(tempData);
      });
      setDatatable(newData);
      // console.log('Datatable', datatable);
      // const totalb = dataRevRec.Jobs.reduce(
      //   (acc, row) => acc + row.allotted_budget,
      //   0
      // );
      // setTotalallotted_budget(totalb);
    };
    const onError = errorRevRec => {
      console.log('query errorRevRec', errorRevRec);
    };
    if (onCompleted || onError) {
      if (onCompleted && !loadingRevRec && !errorRevRec) {
        onCompleted(dataRevRec);
      } else if (onError && !loadingRevRec && errorRevRec) {
        onError(errorRevRec);
      }
    }
  }, [loadingRevRec, dataRevRec, errorRevRec]);

  // calculate the totals for all columns in the table in a useMemo hook
  // see https://www.w3schools.com/react/react_usememo.asp

  const totalBudget = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.allotted_budget, 0);
  }, [datatable]);

  const totalConsumed = useMemo(() => {
    return datatable.reduce(
      (acc, row) => acc + row.total_revrec_and_forecast,
      0
    );
  }, [datatable]);

  const totalRemaining = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.available_for_revrec, 0);
  }, [datatable]);

  const totalRec = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.total_revrec, 0);
  }, [datatable]);

  const totalForecast = useMemo(() => {
    return datatable.reduce((acc, row) => acc + row.total_forecast, 0);
  }, [datatable]);

  const getnextMonthtitle = month => {
    let var_set_year = var_financial_year;

    const var_set_month = year_start_month + month;

    if (parseInt(var_set_month) > 11) {
      var_set_year++;
    }
    const createdDate = new Date();
    createdDate.setDate(1);
    createdDate.setMonth(var_set_month);
    createdDate.setYear(var_set_year);
    return createdDate.toLocaleString('en', {
      month: 'short',
      year: 'numeric'
    });
  };

  const totalColumnValue = useMemo(() => {
    const totalColumnValue_array = [];
    let reduced_value = 0;
    for (let i = 0; i < 12; i++) {
      const temp_column_title = getnextMonthtitle(i);

      reduced_value = datatable.reduce((acc, row) => {
        const row_revrec = row.revrec.find(
          ({ column_title }) => column_title === temp_column_title
        );
        return acc + (row_revrec !== undefined ? row_revrec.amount : 0);
      }, 0);
      totalColumnValue_array[temp_column_title] = reduced_value;
    }
    return totalColumnValue_array;
  }, [datatable]);

  const financialYearMonths = [];
  for (let i = 0; i < 12; i++) {
    ///////////////////////
    // ---------------------------------------------------------
    // *** to do:
    // - get list of RecognisedMonths and make enableEditing conditional based on list of RecognisedMonths
    // - calculate totals for months columns here:
    // const month = getnextMonthtitle(i);
    // const {total+month] = useMemo(() => {
    //   return datatable.reduce((acc, row) => acc + row.month, 0);
    // }, [datatable]);
    // ---------------------------------------------------------
    ///////////////////////

    // get array of all the recognised months
    // *** to do change it with the query
    const next_month_title = getnextMonthtitle(i);

    let var_enable_month_forcast_txt = false;
    let can_recognise = true;
    let already_recognised = false;

    if (isCurrentYear) {
      if (!dRecognisedMonths.includes(next_month_title)) {
        // if the month is not recognised then open for editing
        var_enable_month_forcast_txt = true;
        already_recognised = true;
      }
    } else {
      if (!dRecognisedMonths.includes(next_month_title)) {
        already_recognised = true;
      }
      can_recognise = false;
    }

    // console.log('recognised months list', dRecognisedMonths);

    financialYearMonths.push({
      header: next_month_title,
      accessorKey: next_month_title,
      //enableEditing will be false if the month is recognised based on getnextMonthtitle with in the array of recognised months
      enableEditing: var_enable_month_forcast_txt,
      // columnVisibility: i < new Date.getMonth() ? hidecol : true,
      columnVisibility: hidecol,
      enableColumnFilter: false,
      enableGrouping: false,
      ...currencyFormat,
      muiTableBodyCellProps: {
        align: 'right'
      },
      Footer: () => (
        <div style={{ textAlign: 'right' }}>
          {totalColumnValue[next_month_title].toLocaleString('en-UK', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}
        </div>
      ),
      // add custom column action to Recognise and lock a Month
      renderColumnActionsMenuItems: ({ closeMenu, column }) => {
        return [
          var_enable_month_forcast_txt ? (
            <MenuItem
              key={column.id}
              onClick={() => {
                // // run mutation to recognise month and setColumns on mutation completed (+ find a better way to setColumns without .map() )
                blockMonth(column.id);
                // console.log('column', column);
                closeMenu();
              }}
            >
              <ListItemIcon>
                <LockIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Recognise Month</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem key={column.id}>
              <ListItemIcon>
                <DoneOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Recognised </ListItemText>
            </MenuItem>
          )
        ];
      }
    });
  }

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        accessorKey: 'client.client_name',
        header: 'Client',
        enableEditing: false,
        enableGrouping: true,
        maincol: true,
        Cell: ({ cell, row }) => (
          <Box alignItems="left" display="flex">
            <Typography
              color="textPrimary"
              variant="body1"
              sx={{ fontSize: '0.875rem' }}
            >
              <TruncatedText text={cell.getValue()} maxLength={25} />
            </Typography>
          </Box>
        ),
        size: 125,
        Footer: () => <div>Total</div>
      },
      {
        accessorKey: 'title',
        header: 'Sub Job',
        enableEditing: false,
        enableGrouping: false,
        maincol: true,
        size: 125,
        Cell: ({ cell, row }) => (
          <Box alignItems="left" display="flex">
            <Typography
              color="textPrimary"
              variant="body1"
              sx={{ fontSize: '0.875rem' }}
            >
              <Link to={`/subjob/${row.original.job_number}`}>
                <TruncatedText text={cell.getValue()} maxLength={30} />
              </Link>
            </Typography>
          </Box>
        )
      },
      {
        accessorKey: 'container_title',
        header: 'Master Job',
        enableEditing: false,
        enableGrouping: true,
        maincol: true,
        size: 125,
        Cell: ({ cell, row }) => (
          <Box alignItems="left" display="flex">
            <Typography
              color="textPrimary"
              variant="body1"
              sx={{ fontSize: '0.875rem' }}
            >
              <Link to={`/masterjob/${row.original.container_job_number}`}>
                <TruncatedText text={cell.getValue()} maxLength={30} />
              </Link>
            </Typography>
          </Box>
        )
      },
      {
        accessorKey: 'allotted_budget',
        header: 'Budget',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'right'
        },
        // to add additional information based on grouping
        AggregatedCell: ({ cell }) => (
          <div>
            Group Budget {/* title of that section */}
            {cell.getValue().toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        ),
        Footer: () => (
          <div style={{ textAlign: 'right' }}>
            {totalBudget.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'total_revrec_and_forecast',
        header: 'Consumed',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'right'
        },
        Footer: () => (
          <div style={{ textAlign: 'right' }}>
            {totalConsumed.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'available_for_revrec',
        header: 'Remaining',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue()?.toLocaleString?.('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </>
        ),
        // Cell: ({ cell }) => (
        //   <>
        //     {/* Example to add notes.
        //       Add additional prop to show a different and smaller btn
        //       <Notes type="job_unit" reference={cell.getValue()} /> */}
        //     {cell.getValue()}
        //   </>
        // ),

        Footer: () => (
          <div style={{ textAlign: 'right' }}>
            {totalRemaining.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'total_revrec',
        header: 'Rec',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'right'
        },
        Footer: () => (
          <div style={{ textAlign: 'right' }}>
            {totalRec.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'total_forecast',
        header: 'Forecast',
        enableEditing: false,
        enableColumnFilter: false,
        enableGrouping: false,
        ...currencyFormat,
        //right align the body cells
        muiTableBodyCellProps: {
          align: 'right'
        },
        Footer: () => (
          <div style={{ textAlign: 'right' }}>
            {totalForecast.toLocaleString('en-UK', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      // commenting previous months
      ...financialYearMonths
    ]);
  }, [datatable, totalBudget]);

  const handleSaveCell = (cell, value) => {
    // console.log('cell.column.columnDef', cell.column.columnDef);
    // console.log('cell.row.original.revrec', cell.row.original.revrec);
    const var_column_title = cell.column.columnDef.accessorKey;
    let var_cell_id = '';
    cell.row.original.revrec.forEach(obj => {
      if (obj.column_title === var_column_title) {
        var_cell_id = obj._id;
      }
    });

    if (var_cell_id) {
      console.log('update');
      const update_JobForecast_result = update_JobForecast({
        variables: {
          ref: var_cell_id,
          amount: parseFloat(value)
        }
      });

      update_JobForecast_result.then(callbackval => {
        // console.log('and the result is ', value);
        // Expected output: "Success!"

        if (callbackval.errors) {
          alert(callbackval.errors);
        } else {
          datatable[cell.row.index][cell.column.id] = value;
        }
      });
    } else {
      console.log('add');
      const add_JobForecast_result = add_JobForecast({
        variables: {
          ref: cell.row.original._id,
          data: {
            financial_year: var_financial_year.toString(),
            // month:"December" # Month
            column_title: var_column_title,
            amount: parseFloat(value)
            // user_ref: userData._id,
            // user_name: userData.first_name + ' ' + userData.last_name
          }
        }
      });
      add_JobForecast_result.then(callbackval => {
        // console.log('and the result is ', value);
        // Expected output: "Success!"

        if (callbackval.errors) {
          datatable[cell.row.index][cell.column.id] = '';
          alert(callbackval.errors);
        } else {
          datatable[cell.row.index][cell.column.id] = value;
        }
      });
    }

    //if the _id exist against "cell.column.columnDef.accessorKey" within cell.row.original.revrec then call update mutation
    // else add_JobForecast

    // console.log('cell', cell);
    // console.log('column_title', var_column_title);
    // console.log('value', value);

    setDatatable([...datatable]); //set new data
  };

  // blockMonth is actually recognise month
  const blockMonth = columnid => {
    // console.log('called');
    // console.log('col ID', columnid);
    // console.log('called');
    setColumns(
      [...columns].map(object => {
        if (object.accessorKey === columnid) {
          return {
            ...object,
            enableEditing: false
          };
        }
        return object;
      })
    );
    // *** to do: get list of RecognisedMonths
    Recognise_Month({
      variables: {
        data: {
          financial_year: getFinancialYear(),
          // *** to do - make month name a variable
          month: getMonthName(columnid),
          column_title: columnid
        }
      }
    });
  };

  // not in use for now
  const col_visible = {};

  if (
    //hide columns only if we are in current year
    date_obj.getFullYear() === parseInt(param_year.year) ||
    !param_year.year
  ) {
    const get_month = date_obj.getMonth();
    let month_loop = 0;
    if (get_month === year_start_month) {
      month_loop = 0;
    } else if (get_month < year_start_month) {
      month_loop = 11 - year_start_month + get_month;
    } else {
      month_loop = get_month - (year_start_month + 1);
    }
    for (let j = 0; j < month_loop; j++) {
      col_visible[financialYearMonths[j].accessorKey] = false;
    }
  }

  const convertToCSV = data => {
    // const header = Object.keys(data[0]).join(',') + '\n';
    // const rows = data.map(row =>
    //   Object.values(row)
    //     .map(value => (typeof value === 'string' ? `"${value}"` : value))
    //     .join(',')
    // );
    // return header + rows.join('\n');
  };

  const handleExportData = () => {
    // const csv = convertToCSV(datatable);
    // const blob = new Blob([csv], { type: 'text/csv' });
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'data.csv';
    // a.click();
    // URL.revokeObjectURL(url);
    console.log('handleExportData called');
    alert('working on it...');
  };

  return (
    <div>
      {dataRevRec && (
        <MaterialReactTable
          columns={columns}
          //putting data in material react table
          data={datatable}
          editingMode="cell"
          enableEditing
          enableGrouping
          enablePinning
          enablePagination={false}
          enableDensityToggle
          positionActionsColumn="last"
          // onEditingCellChange={cell => {
          //   console.log('cell', cell);
          // }}
          initialState={{
            density: 'compact',
            //grouping: ['client.client_name'], //column to group by by default
            expanded: true, //expand all groups by default
            columnPinning: {
              left: ['client.client_name', 'title', 'container_title']
            }
            // columnVisibility commented for now
            // columnVisibility: col_visible
          }}
          defaultColumn={{
            size: 100, // This sets the default width for all columns
            maxSize: 200, // Optional: limits how wide columns can get
            minSize: 0 // Optional: limits how narrow columns can get
          }}
          muiTableBodyProps={{
            sx: {
              //stripe the rows, make odd rows a darker color
              // '& tr:nth-of-type(odd)': {
              //   backgroundColor: '#f5f5f5'
              // }
            }
          }}
          muiTableHeadCellProps={({ column }) => ({
            sx: theme => ({
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between'
              },
              // borderRight: !column.columnDef.maincol
              //   ? '1px solid #e0e0e0'
              //   : 'none'
              borderRight: '1px solid #e0e0e0'
            })
          })}
          muiTableBodyCellProps={({ cell }) => ({
            sx: theme => ({
              borderRight: !cell.column.columnDef.maincol
                ? '1px solid #e0e0e0'
                : 'none',
              // borderRight: '1px solid #e0e0e0',
              backgroundColor:
                cell.column.columnDef.enableEditing ||
                cell.column.columnDef.maincol
                  ? '#fff'
                  : theme.palette.disabled.pink
            })
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                p: '0.5rem',
                flexWrap: 'wrap'
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export - TBC
              </Button>
              <FormControl
                variant="outlined"
                sx={{ minWidth: 120, height: 40 }}
              >
                <InputLabel htmlFor="year-select">Year</InputLabel>
                <Select
                  native
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                  inputProps={{
                    name: 'year',
                    id: 'year-select'
                  }}
                  sx={{ height: '40px' }}
                >
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year + ' - ' + (year + 1)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: event => {
              handleSaveCell(cell, event.target.value);
            }
          })}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="note"
            multiline
            maxRows={Infinity}
            type="textarea"
            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Results;
