import React, { useState, useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Avatar
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import AuthContext from 'src/components/AuthContext';

import Notesvg from 'src/utils/Notesvg';

import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const drawerWidth = 450;

const NotesList = gql`
  query Notes($collection_ref: String, $collection_name: String) {
    Notes(collection_ref: $collection_ref, collection_name: $collection_name) {
      subscription_key
      collection_name
      collection_ref
      user_name
      note
      note_type
      user_ref
      createdAt
    }
  }
`;

const addNote = gql`
  mutation addNote(
    $subscription_key: String
    $collection_name: String
    $collection_ref: String
    $user_name: String
    $note: String
    $note_type: String
    $user_ref: String
  ) {
    addNote(
      data: {
        subscription_key: $subscription_key
        collection_name: $collection_name
        collection_ref: $collection_ref
        user_name: $user_name
        note: $note
        note_type: $note_type
        user_ref: $user_ref
      }
    ) {
      _id
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      justifyContent: 'flex-end'
    },
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 10,
      color: '#aaa'
    },
    drawerPaper: {
      width: drawerWidth,
      padding: '20px'
    },
    noteList: {
      maxHeight: '100',
      overflowY: 'scroll'
    },
    noteTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.2em',
      marginBottom: 'auto',
      paddingBottom: '20px'
    }
  };
});

const Notes = props => {
  const { classes } = useStyles();
  const noteChat = useRef(null);
  // const noteInput = useRef(null);
  const { userData } = useContext(AuthContext);
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [noteList, setNoteList] = useState([]);

  const handleChange = event => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };
  const inView = () => {
    setTimeout(() => {
      if (noteChat.current) {
        noteChat.current.scrollIntoView();
      }
    }, 100);
  };

  const handleClickModal = () => {
    setOpen(true);
    inView();
    // noteChat.current.scrollIntoView();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const NotesResults = useQuery(NotesList, {
    variables: { collection_ref: props.reference, collection_name: props.type }
  });

  useEffect(() => {
    if (NotesResults.data) setNoteList(NotesResults.data.Notes);
    inView();
  }, [NotesResults]);

  useEffect(() => {
    if (noteList) {
      const members = [];
      noteList.forEach(note => {
        // console.log(note.user_ref);
        members[note.user_ref] = '';
      });
      // console.log(members);
    }
  }, [noteList]);

  const handleSubmit = () => {
    if (value) {
      const data = {
        collection_name: props.type,
        subscription_key: userData.subscription_key,
        note: value.message,
        user_ref: userData._id,
        user_name: userData.first_name + ' ' + userData.last_name,
        collection_ref: props.reference
      };

      add_Note({
        variables: data
      });
      setTimeout(() => {
        setValue({});
      }, 100);
    }
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit();
      // console.log('enter press here! ');
    }
  };

  const [add_Note] = useMutation(addNote, {
    onCompleted() {
      NotesResults.refetch();
      inView();
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: NotesList }]
  });

  let runDate = '';

  return (
    <>
      <Box style={{ marginRight: '15px' }}>
        <Badge
          overlap="rectangular"
          badgeContent={noteList.length}
          color="secondary"
        >
          <Button color="primary" variant="outlined" onClick={handleClickModal}>
            {/* {noteList.length > 0 && (
            <Chip
              label={noteList.length}
              style={{
                marginLeft: '-10px',
                marginRight: '5px',
                cursor: 'pointer'
              }}
              size="small"
            />
          )}{' '} */}
            Notes
          </Button>
        </Badge>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box className={classes.noteTitle}>Notes</Box>

        <List
          className={classes.noteList}
          sx={{
            bgcolor: 'background.paper'
          }}
        >
          <Notesvg />
          {noteList &&
            noteList.map(item => {
              let showDate = moment.unix(item.createdAt / 1000).format('LL');
              if (runDate === showDate) {
                showDate = '';
              } else {
                runDate = showDate;
              }
              return (
                <>
                  {showDate ? (
                    <Box style={{ textAlign: 'center', fontWeight: 'bold' }}>
                      {showDate}
                    </Box>
                  ) : (
                    <Divider variant="inset" component="li" />
                  )}
                  {/* {item.user_ref} - {userData._id} */}
                  <ListItem alignItems="flex-start" key={item._id}>
                    <ListItemAvatar>
                      <Avatar
                        alt={item.user_name}
                        src="/static/images/avatar/1.jpg"
                      />
                      {/* <img
                        className={classes.avatar}
                        // src={userAvatar}
                        alt={item.user_name}
                      /> */}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.user_name}
                      secondary={<>{item.note}</>}
                    />
                    <ListItemSecondaryAction
                      style={{
                        bottom: 0,
                        top: 'initial',
                        fontSize: '0.8em',
                        opacity: '.75'
                      }}
                    >
                      {/* {item.user_ref} */}
                      {/* {item.createdAt} */}
                      {moment.unix(item.createdAt / 1000).format('HH:mm')}
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
          <div ref={noteChat}></div>
        </List>
        <br />
        <TextField
          id="standard-multiline-flexible"
          label="Note"
          multiline
          maxRows={4}
          value={value.message ? value.message : ''}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          name="message"
          variant="outlined"
          margin="normal"
          style={{
            width: '100%'
          }}
        />
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </Drawer>
    </>
  );
};

export default Notes;
