import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { updateUser } from '../queries/query';

const loginPath = '/login';

const User = gql`
  query User($id: ID!) {
    User(_id: $id) {
      _id
      first_name
      last_name
    }
  }
`;

function CheckAuth(props, ref) {
  const defaultUser = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: 'UK',
    thumbnail: '',
    department: ''
  };

  const debugLog = (message, ...optionalParams) => {
    if (window.location.hash.includes('#debug')) {
      console.log(message, ...optionalParams);
    }
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const sendUserData = props.setUserData;
  const [userRef, setUserRef] = useState('');
  const [userData, setUserData] = useState(defaultUser);
  const googleUser = JSON.parse(localStorage.getItem('googleUser'));

  const authToken = localStorage.getItem('authToken');

  const { data } = useQuery(User, {
    variables: { id: userRef }
  });

  // restrictedRoutes
  const allowedRoutes = [
    '/restricted',
    '/dashboard',
    '/users',
    '/clients',
    '/departments',
    '/ratecards',
    '/jobs',
    '/jobunits',
    '/suppliers',
    '/account',
    '/settings'
  ];

  // const restrictedRoutes = ['/suppliers'];
  const restrictedRoutes = [''];

  localStorage.setItem('restrictedRoutes', restrictedRoutes);
  localStorage.setItem('allowedRoutes', allowedRoutes);

  const [update_User] = useMutation(updateUser, {
    onCompleted() {},
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
    // refetchQueries: [{ query: NotesList }]
  });

  function testUserData(userData) {
    // Function will get the thumbnail from Gmail and update it in the DB if it's not already present
    if (!googleUser) return userData; // Return early if googleUser is not available

    let thumbnail;
    try {
      thumbnail = JSON.parse(userData.thumbnail);
    } catch (error) {
      console.error('Error parsing thumbnail:', error);
      thumbnail = null; // Handle JSON parsing errors gracefully
    }

    const currentThumbnailSrc = thumbnail?.src;
    const googleUserThumbnailSrc = googleUser?.thumbnail;

    // Check if the current thumbnail is missing or invalid, and update it from googleUser
    if (!currentThumbnailSrc && googleUserThumbnailSrc) {
      const updatedThumbnail = JSON.stringify({ src: googleUserThumbnailSrc });

      update_User({
        variables: {
          ref: userData._id,
          data: { thumbnail: updatedThumbnail }
        }
      });
    }

    return userData;
  }

  const doLogout = () => {
    // Logout function - removes the Token and redirects to the login page
    debugLog('doLogout called');
    localStorage.removeItem('authToken');
    localStorage.removeItem('googleUser');
    if (pathname !== loginPath) {
      window.location.href = loginPath;
    }
  };

  // [1] First check if there is a authToken, if not, logout
  useEffect(() => {
    // setUserRef('5fd3d0a5d2522906b70ef19f');
    // return;
    if (!authToken) {
      debugLog('Missing out token - logout 1');
      doLogout();
      //   setUserData(defaultUser);
    } else {
      debugLog('auth exists');
      axios
        .get('getUserData', {
          headers: {
            'x-access-token': authToken
          }
        })
        .then(response => {
          setUserRef(response.data.user_ref);
        })
        .catch(function(error) {
          debugLog(
            "Axious didn't get info from getUserData from server - logout 2"
          );
          doLogout();
          return false;
        });
    }
  }, []);

  // [2] If there is a token, get the user data, if not, logout.
  useEffect(() => {
    debugLog('useEffect2');
    if (data && data.User !== userData) {
      // debugLog('data.User');
      const newUserData = testUserData(data.User);
      setUserData(newUserData);
    } else {
      debugLog(
        "User data does not exist in the database or hasn't loaded yet - waiting for the real user, setting the default user"
      );
      //   doLogout();
      debugLog('defaultUser', defaultUser);
      setUserData(defaultUser);
    }
  }, [data]);

  // [3] If there is user data, navigate to the Dashboard, if not, logout
  useEffect(() => {
    debugLog('useEffect3');
    if (authToken) {
      // Runs when user is logged in to check
      // ristricted path
      debugLog('useEffect 3 if executed', pathname);
      sendUserData(userData); // sending data back to parent controller
      debugLog('user read level at this point', userData.user_level);
      if (pathname === loginPath) {
        debugLog('if logged in then should not be able to go on login page');
        navigate('/');
      }
    } else {
      debugLog('Local storage missing authToken - logout 4');
      //   doLogout();
      //   if (pathname !== loginPath) navigate(loginPath);
    }
  }, [userData]);

  //   Function calls from outside
  useImperativeHandle(
    ref,
    () => ({
      LogoutUser: () => {
        localStorage.removeItem('subscriptionChoices');
        navigate(loginPath);
        sendUserData(defaultUser);
        axios
          .get('logOut', {
            headers: {
              'x-access-token': authToken
            }
          })
          .then(response => {
            doLogout();
            return response.data;
          })
          .catch(function(error) {
            return error;
          });
      },
      loginUser: () => {
        if (pathname === loginPath) {
          window.location.href = '/';
        }
      }
    }),
    []
  );

  // Test button function
  const buttonTask = () => {
    debugLog('userRef', userRef);
    debugLog('userData', userData);
    //   sendUserData(userData);
  };

  return (
    <>
      {/* <button
        style={{ position: 'absolute', zIndex: '10000' }}
        onClick={() => buttonTask()}
      >
        Check User Data
      </button> */}
    </>
  );
}
export default forwardRef(CheckAuth);
