import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import currencyformatter from 'src/utils/currencyformatter';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import Autocomplete from '@mui/material/Autocomplete';

import Notes from 'src/views/notes/Notes';
import Tasks from 'src/views/tasks/Tasks';
import TextEditor from 'src/components/TextEditor';
import BudgetTooltip from 'src/components/BudgetTooltip';

import {
  updateJobContainer,
  updateJobContainerBudget,
  updateJobContainerClient
} from 'src/queries/query';

import RatecardsDetails from './RatecardsDetails';

const Client = gql`
  query Client($id: ID) {
    Client(_id: $id) {
      _id
      name
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    },
    root: {
      flexGrow: 1,
      maxWidth: 752
    },
    demo: {
      backgroundColor: '#fcfcfc'
    },
    marginList: {
      marginRight: '10px'
    },
    marginListUnits: {
      marginLeft: '10px'
    },
    widthProgress: {
      width: '200px'
    }
  };
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileDetails = ({
  className,
  element,
  dialogRatecard,
  fromProfileDetails,
  stage,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  let total_allocated = 0;
  element[0].units.forEach(item => {
    total_allocated += item.allocated_budget;
  });

  const [state, setState] = useState({
    isBlocking: false,
    state_total_allocated: total_allocated,
    total_budgetmodal: element[0].total_budget,
    budget_form: !stage === 'complete',
    approved: element[0].stage === 'live',
    stagecompleted: element[0].stage === 'complete',
    pre_to_live_date: element[0].pre_to_live_date
      ? new Date(parseInt(element[0].pre_to_live_date))
      : '',
    complete_date: element[0].complete_date
      ? new Date(parseInt(element[0].complete_date))
      : '',
    owner_reference: element[0].owner_reference,
    client_ref: element[0].client.client_ref,
    client_name: element[0].client.client_name,
    client_po: element[0].client_po
  });

  const [openDialogRatecard, setOpenDialogRatecard] = useState(dialogRatecard);
  const [newactiveratecard, setNewactiveratecard] = useState(
    element[0].ratecard
  );
  const [openDialogClient, setOpenDialogClient] = React.useState(false);

  const fromRatecardsDetails = (open, activeratecard) => {
    setOpenDialogRatecard(open);
    setNewactiveratecard(activeratecard);
    console.log('from child', activeratecard);
  };

  useEffect(() => {
    fromProfileDetails(newactiveratecard);
  }, [newactiveratecard]);

  const handleCloseDialogRatecard = () => {
    setOpenDialogRatecard(false);
  };

  useEffect(() => {
    setOpenDialogRatecard(dialogRatecard);
  }, [dialogRatecard]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  const [opendialog, setOpendialog] = React.useState(false);

  const handleClosedialog = () => {
    setOpendialog(false);
  };

  const [update_JobContainerBudget] = useMutation(updateJobContainerBudget, {
    onCompleted() {
      setOpendialog(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  const [update_JobContainerClient] = useMutation(updateJobContainerClient, {
    onCompleted() {
      setOpenDialogClient(false);
    },
    onError: err => {
      console.log(String(err).replace('Error: GraphQL error: ', ''));
    }
  });

  // update Client

  const handleCloseDialogClient = () => {
    setOpenDialogClient(false);
  };

  const submitClient = e => {
    e.preventDefault();
    // console.log('Client', state.clientmodal);
    update_JobContainerClient({
      variables: {
        ref: element[0]._id,
        data: {
          client_ref: state.client_ref,
          client_name: state.client_name
        }
      }
    });
  };

  // end update Client

  const [update_JobContainer] = useMutation(updateJobContainer, {
    onCompleted() {
      setOpen(false); // Close any existing error messages
      setErrorMessage(''); // Clear any existing error messages
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    },
    onError: err => {
      // Check for duplicate title error
      const errorMessage = err.message.includes('Record Already Exists')
        ? 'A job with this title already exists'
        : err.message.split('GraphQL error: ')[1] || 'An error occurred';

      setErrorMessage(errorMessage);
      setOpen(true);
      setIsloading(true);

      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  });

  const [jobDesc, setJobDesc] = useState(element[0].description);

  const textEditorOutput = textoutput => {
    setJobDesc(textoutput);
  };

  // Add a new effect to handle budget updates
  const handleFormSubmit = async e => {
    e.preventDefault();
    setIsloading(true);

    try {
      // First, update the main job container data
      await update_JobContainer({
        variables: {
          ref: element[0]._id,
          data: {
            title: e.target.title.value,
            description: jobDesc,
            owner_reference: state.owner_reference,
            owner_name: e.target.owner_name.value,
            start_date: e.target.start_date.value,
            end_date: e.target.end_date.value
          }
        }
      });

      // Then, update the budget if it has changed
      if (state.total_budgetmodal !== element[0].total_budget) {
        await update_JobContainerBudget({
          variables: {
            ref: element[0]._id,
            budget: parseFloat(state.total_budgetmodal)
          }
        });
      }

      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);
      }, 2500);
    } catch (error) {
      console.error('Error updating:', error);
      setOpen(true);
      setIsloading(true);

      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    }
  };

  // Simplified budget change handler
  const handleBudgetChange = newValue => {
    setState(prev => ({
      ...prev,
      total_budgetmodal: newValue
    }));
  };

  // Add cleanup for timers
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      if (timer2.current) {
        clearTimeout(timer2.current);
      }
    };
  }, []);
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Add this
        sx={{ position: 'fixed' }} // Add this to ensure proper positioning
      >
        <Alert
          onClose={handleClose}
          severity="error"
          elevation={6} // Add this
          variant="filled" // Add this
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <Card>
          <CardHeader
            // subheader="The information can be edited"
            title="Master Job"
            classes={{
              action: classes.cardAction
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}></Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  label="Job title"
                  name="title"
                  required
                  defaultValue={element[0].title}
                  variant="outlined"
                  InputProps={{
                    readOnly: stage === 'complete'
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <BudgetTooltip
                  value={state.total_budgetmodal}
                  onChange={e => {
                    const newValue = parseFloat(e.target.value);
                    if (!Number.isNaN(newValue)) {
                      handleBudgetChange(newValue);
                    }
                  }}
                  units={element[0].units}
                  allocatedBudget={state.state_total_allocated}
                  stage={stage}
                  isApproved={state.approved}
                  currencyformatter={currencyformatter}
                />
              </Grid>

              <Grid item xs={12}>
                <TextEditor
                  textEditorOutput={textEditorOutput}
                  textEditorInput={element[0].description}
                  canEdit={stage === 'complete' ? false : true}
                  disabled={stage === 'complete'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      required
                      label="Client"
                      name="client_name"
                      variant="outlined"
                      value={state.client_name}
                      InputProps={{
                        readOnly: true
                      }}
                      disabled={stage === 'complete'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      required
                      label="Owner"
                      name="owner_name"
                      variant="outlined"
                      value={element[0].owner_name}
                      InputProps={{
                        readOnly: true
                      }}
                      disabled={stage === 'complete'}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Start Date"
                  name="start_date"
                  defaultValue={
                    element[0].start_date
                      ? new Date(parseInt(element[0].start_date))
                          .toISOString()
                          .slice(0, 10)
                      : null
                  }
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete',
                    onFocus: e => {
                      if (stage !== 'complete') {
                        e.target.showPicker?.();
                      }
                    }
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  className={cx(classes.date)}
                  id="date"
                  label="Delivery Date"
                  name="end_date"
                  defaultValue={
                    element[0].end_date
                      ? new Date(parseInt(element[0].end_date))
                          .toISOString()
                          .slice(0, 10)
                      : null
                  }
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: stage === 'complete',
                    onFocus: e => {
                      if (stage !== 'complete') {
                        e.target.showPicker?.();
                      }
                    }
                  }}
                  disabled={stage === 'complete'}
                />
              </Grid>
              {state.approved && state.pre_to_live_date && (
                <Grid item xs={12}>
                  <p>Approved on {state.pre_to_live_date.toLocaleString()}</p>
                </Grid>
              )}
              {state.stagecompleted && state.complete_date && (
                <Grid item xs={12}>
                  <p>Completed on {state.complete_date.toLocaleString()}</p>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Notes type="job_container" reference={element[0]._id} />
            <Tasks
              type="job_container"
              collection_ref={element[0].job_number}
              element={element[0]}
            />

            {stage !== 'complete' &&
              (askareyousure ? (
                <button
                  type="submit"
                  id="button"
                  className={`btn-animate ${isloading ? 'onclic' : null} ${
                    !isloading && completed ? 'validate' : null
                  } ${iserror ? 'novalidate' : null}`}
                ></button>
              ) : (
                <button
                  onClick={ask}
                  id="ask"
                  className={`btn-animate ask`}
                ></button>
              ))}
          </Box>
        </Card>
      </form>

      {/* dialog ratecard */}
      <Dialog
        open={openDialogRatecard}
        onClose={handleCloseDialogRatecard}
        fullWidth
        maxWidth={'md'}
      >
        <DialogContent>
          <RatecardsDetails
            jobref={element[0]._id}
            activeratecard={newactiveratecard}
            clientref={element[0].client.client_ref}
            fromRatecardsDetails={fromRatecardsDetails}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialogRatecard} color="primary">
            Skip
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog to update client */}
      <Dialog
        open={openDialogClient}
        onClose={handleCloseDialogClient}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>Update Job Client</DialogTitle>
        <DialogContent>
          <Query query={Client}>
            {({ loading, data }) =>
              !loading && (
                <Autocomplete
                  id="clientlist"
                  disabled={element[0].ratecard}
                  defaultValue={data.Client.find(
                    item => item.name === element[0].client.client_name
                  )}
                  options={data.Client}
                  getOptionLabel={option => option.name}
                  onChange={(event, values) => {
                    if (values) {
                      setState({
                        ...state,
                        client_ref: values._id,
                        client_name: values.name
                      });
                    } else {
                      setState({
                        ...state,
                        client_ref: '',
                        client_name: ''
                      });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Client"
                      name="client_name"
                      variant="outlined"
                      helperText={
                        element[0].ratecard
                          ? 'Can not update Client if Ratecard is approved'
                          : ''
                      }
                    />
                  )}
                />
              )
            }
          </Query>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialogClient} color="primary">
            Cancel
          </Button>
          <Button onClick={submitClient} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileDetails;
