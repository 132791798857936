import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import gql from 'graphql-tag';
import AuthContext from 'src/components/AuthContext';
import { useLazyQuery } from '@apollo/client';
import TimesheetForm from './TimesheetForm';
import DeleteTimesheet from './DeleteTimesheet';

const Timesheet = gql`
  query Timesheet($id: String, $week: Int, $year: String) {
    Timesheet(user_reference: $id, for_week: $week, year: $year) {
      _id
      subscription_key
      user_reference
      user_name
      user_designation
      job_container_reference
      job_reference
      jobs_designation
      note
      user_rate
      hours
      amount
      for_week
      created
      year
      job_state
      job_info {
        title
        client {
          client_name
        }
      }
      tags
    }
  }
`;

const useStyles = makeStyles()(() => {
  return {
    root: {},
    actions: {
      justifyContent: 'flex-end'
    }
  };
});

const TimeSheets = ({
  className,
  week,
  updateHours,
  timesheetkey,
  postoperationfnc,
  ...rest
}) => {
  const { classes } = useStyles();
  const [timesheets, setTimesheets] = useState([]);

  const [fullname, setFullname] = useState('');
  // open , activeRow timesheet related
  const [open, setOpen] = useState(false);
  const [activeRow, setActiveRow] = useState('');
  const [job_id, setJob_id] = useState('');
  const [job_title, setJob_title] = useState('');
  const { userData } = useContext(AuthContext);

  const [getData, { called, loading, refetch, data }] = useLazyQuery(Timesheet);

  useEffect(() => {
    const weeknr = moment(week).week();
    const year = String(moment(week).year());
    if (userData._id)
      getData({
        variables: {
          id: userData._id,
          week: weeknr,
          year: year
        }
      });
    // refetch();
  }, [week, userData]);

  useEffect(() => {
    if (!loading && data && userData) refetch();
  }, [timesheetkey]);

  useEffect(() => {
    if (!loading && data) setTimesheets(data.Timesheet);
  }, [loading, data]);

  useEffect(() => {
    const fn = userData.first_name + ' ' + userData.last_name + ' - ';
    setFullname(fn);
  }, [userData]);

  useEffect(() => {
    let hours = 0;
    if (timesheets) {
      timesheets.map(entry => {
        hours += entry.hours;
      });
      updateHours(hours);
    }
  }, [timesheets]);

  // handleClickOpen, setOpen => Timesheet related
  const handleClickOpen = row => {
    setJob_id(0); // to update job id is not required so set 0
    setJob_title(row.job_info[0].title); // Job title to display on the form
    setActiveRow(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const thisDay = moment(week);
  const md = thisDay.day('monday');
  const monday = md.format('D MMM');

  const fd = thisDay.day('friday');
  const friday = fd.format('D MMM');

  const fullWeek = monday + ' - ' + friday;

  moment.updateLocale('en', {
    week: {
      dow: 1
    }
  });

  return (
    <Card elevation={0}>
      <CardHeader title={fullname + 'Time Sheets - ' + fullWeek} />
      {timesheets.length > 0 && (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Week</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timesheets &&
                timesheets.map(entry => (
                  <TableRow hover key={entry._id}>
                    <TableCell>
                      {entry.job_info[0].client.client_name}
                    </TableCell>
                    <TableCell>{entry.job_info[0].title}</TableCell>
                    <TableCell>{entry.hours}</TableCell>
                    <TableCell>{entry.note}</TableCell>
                    <TableCell>
                      {moment(entry.year)
                        .week(entry.for_week)
                        .day('monday')
                        .format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() => handleClickOpen(entry)}
                      >
                        <EditRoundedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <DeleteTimesheet
                        title={entry.job_info[0].title}
                        note={entry.note}
                        id={entry._id}
                        hours={entry.hours}
                        postoperationfnc={postoperationfnc}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {activeRow && (
        <TimesheetForm
          open={open}
          handleClose={handleClose}
          activeRow={activeRow}
          operation="Edit"
          postoperationfnc={postoperationfnc}
          job_id_param={job_id}
          job_title_param={job_title}
        />
      )}
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

TimeSheets.propTypes = {
  className: PropTypes.string
};

export default TimeSheets;
