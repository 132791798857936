import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, TextField } from '@mui/material';

import MUIEditor, {
  MUIEditorState,
  toolbarControlTypes
} from 'react-mui5-draft-wysiwyg';
import { convertToRaw, convertFromRaw } from 'draft-js';
const useStyles = makeStyles()(() => {
  return {
    box: {
      padding: '5px 10px 15px 10px',
      borderRadius: '5px',
      width: '100%'
    },
    boxSmall: {
      width: 'auto',
      padding: '5px 0',
      borderRadius: '1px'
    }
  };
});

const MyEditor = React.forwardRef((props, ref) => {
  const {
    component: MUIEditor,
    onChange,
    canEdit,
    isInput,
    size,
    ...other
  } = props;
  let textEditorInput = props.textEditorInput;
  const { classes } = useStyles();

  const editorConfig = {
    editor: {
      style: {
        ...(size === 'small'
          ? { padding: '5px', margin: '0px' }
          : { padding: '15px 5px', margin: '5px' })
      }
    },
    ...(canEdit
      ? {
          toolbar: {
            style: {
              background: '#f4f6fb'
            },
            position: 'bottom',
            controls: [
              toolbarControlTypes.bold,
              toolbarControlTypes.italic,
              toolbarControlTypes.underline,
              toolbarControlTypes.unorderedList,
              toolbarControlTypes.orderedList,
              toolbarControlTypes.divider,
              toolbarControlTypes.undo,
              toolbarControlTypes.redo,
              toolbarControlTypes.linkAdd,
              toolbarControlTypes.linkRemove
            ]
          }
        }
      : {
          toolbar: {
            visible: false,
            controls: [toolbarControlTypes.bold]
          },
          draftEditor: { readOnly: true }
        })
  };

  textEditorInput &&
    (textEditorInput = convertFromRaw(JSON.parse(textEditorInput)));
  const [editorState, setEditorState] = useState(
    textEditorInput
      ? MUIEditorState.createWithContent(editorConfig, textEditorInput)
      : MUIEditorState.createEmpty(editorConfig)
  );
  const onChangeMUIEditor = newState => {
    setEditorState(newState);
    const rawContent = convertToRaw(editorState.getCurrentContent());
    onChange({
      target: {
        value: newState,
        content: rawContent
      }
    });
  };

  return (
    <Box className={size === 'small' ? classes.boxSmall : classes.box}>
      <MUIEditor
        {...other}
        // ref={ref}
        editorState={editorState}
        onChange={onChangeMUIEditor}
        config={editorConfig}
      />
    </Box>
  );
});

const TextEditor = ({
  textEditorInput,
  textEditorOutput,
  isInput,
  canEdit,
  size,
  editorLabel
}) => {
  const [values, setValues] = useState({
    description: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      description: event.target.value
    });

    if (canEdit && textEditorOutput) {
      textEditorOutput(JSON.stringify(event.target.content));
    }
  };

  return (
    <>
      {isInput ? (
        <TextField
          required
          fullWidth
          label={editorLabel}
          value={values.description}
          name="description"
          id="description"
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            inputComponent: MyEditor,
            inputProps: {
              component: MUIEditor,
              textEditorInput,
              canEdit: canEdit,
              isInput: { isInput },
              size
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      ) : (
        <MyEditor
          component={MUIEditor}
          textEditorInput={textEditorInput}
          canEdit={canEdit}
          isInput={isInput}
          size={size}
          onChange={handleChange} // Add this
        />
      )}
    </>
  );
};

TextEditor.defaultProps = {
  canEdit: true,
  isInput: true,
  editorLabel: 'Job description',
  size: 'normal'
};

export default TextEditor;
