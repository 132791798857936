import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Hidden,
  Typography,
  IconButton,
  Toolbar,
  Tooltip
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import Logo from 'src/components/Logo';
import AuthContext from 'src/components/AuthContext';
import HeaderSubscriptionButton from 'src/components/googleComponents/HeaderSubscriptionButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const useStyles = makeStyles()(theme => {
  return {
    btn: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    btn1: {
      marginRight: '12px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    avatar: {
      width: 40,
      height: 40
    }
  };
});

const user = {
  avatar: '',
  jobTitle: 'Senior Developer',
  name: 'Doriana Ciccarelli'
};
const CurrentSubscriptionName = () => {
  const [subscriptionName, setSubscriptionName] = useState('dashboard');
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get('/getSubscriptions')
      .then(response => {
        const activeSubscription = response.data.find(sub => sub.active);
        setSubscriptionName(activeSubscription?.company_name || '');
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <Typography color="textSecondary" variant="body2" sx={{ mx: 1 }}>
      {subscriptionName}:
    </Typography>
  );
};

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const { classes } = useStyles();
  const [notifications] = useState([]);
  const { userData, logoutUser } = useContext(AuthContext);
  const [userAvatar, setUserAvatar] = useState(user.avatar);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    // Try to get name from userData first (GraphQL)
    if (userData.first_name) {
      setDisplayName(userData.first_name);
    } else {
      // Fallback to Google user data
      const googleUser = JSON.parse(localStorage.getItem('googleUser'));
      if (googleUser?.firstName) {
        setDisplayName(googleUser.firstName);
      }
    }

    // Handle avatar
    if (!userData.thumbnail) {
      try {
        const thumbnail = JSON.parse(userData.thumbnail);
        setUserAvatar(thumbnail.src);
      } catch (error) {
        // Fallback to Google thumbnail
        const googleUser = JSON.parse(localStorage.getItem('googleUser'));
        if (googleUser?.thumbnail) {
          setUserAvatar(googleUser.thumbnail);
        }
      }
    }
  }, [userData]);

  return (
    <AppBar style={{ background: '#f4f6fb' }} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgDown>
          <CurrentSubscriptionName />
          <Typography color="textSecondary" variant="body2">
            Hi, {displayName}
          </Typography>
          <IconButton color="inherit" size="large">
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              src={userAvatar}
              to="/account"
            />
          </IconButton>
          <Tooltip title="Logout">
            <IconButton
              color="inherit"
              className={classes.btn1}
              onClick={logoutUser}
              size="large"
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
          <HeaderSubscriptionButton>
            <Tooltip title="Change Subscription">
              <IconButton color="inherit" className={classes.btn1} size="large">
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          </HeaderSubscriptionButton>
          <IconButton color="inherit" className={classes.btn} size="large">
            <Badge
              overlap="rectangular"
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
