import React, { useState } from 'react';
import { Container, Grid, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import TimeSheets from './TimeSheets/TimeSheets';
import CurrentJobs from './TimeSheets/CurrentJobs';
import TasksProgress from './TimeSheets/TasksProgress';
import Calendar from './Calendar';
import Tasks from './Tasks';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: 0
    }
  };
});

const Dashboard = () => {
  const { classes } = useStyles();
  const [week, setWeek] = useState('');
  const [hours, setHours] = useState(0);
  const [timesheetkey, setTimesheetkey] = useState(0);

  const updateTimesheet = () => {
    setTimesheetkey(timesheetkey + 1);
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={8} xl={9}>
            {/* <Box style={{ marginBottom: '20px' }}>
              <TimeSheets
                week={week}
                updateHours={setHours}
                timesheetkey={timesheetkey}
                postoperationfnc={updateTimesheet}
              />
            </Box> */}
            <Box style={{ marginBottom: '20px' }}>
              <CurrentJobs postoperationfnc={updateTimesheet} />
            </Box>
            <Box style={{ marginBottom: '20px' }}>
              <Tasks />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            {/* <Box style={{ marginBottom: '20px' }}>
              <TasksProgress progress={hours} weekChange={setWeek} />
            </Box> */}
            <Box>
              <Calendar />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
