import React from 'react';
import { Tooltip } from '@mui/material';

const TruncatedText = ({ text, maxLength = 100 }) => {
  const shouldTruncate = text.length > maxLength;
  const displayText = shouldTruncate ? `${text.slice(0, maxLength)}...` : text;

  return shouldTruncate ? (
    <Tooltip title={text} arrow placement="top">
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',
          maxWidth: `${maxLength * 2.5}px`
        }}
      >
        {displayText}
      </span>
    </Tooltip>
  ) : (
    <span>{displayText}</span>
  );
};

export default TruncatedText;
