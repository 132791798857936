import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {},
    somethiselse: {
      marginRight: theme.spacing(1),
      marginLeft: 'auto'
    },
    exportButton: {
      marginRight: theme.spacing(1)
    }
  };
});

const Toolbar = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        // justifyContent="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Job Requests
        </Typography>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
