import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Box, Container, Typography, Alert } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import Results from './Results';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    alert: {
      marginBottom: theme.spacing(3)
    }
  };
});

const CustomerListView = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const stage = location.pathname.split('/').pop();
  const jobNotFound = searchParams.get('jobNotFound');

  return (
    <Page className={classes.root} title="Jobs">
      <Container maxWidth={false}>
        {jobNotFound && (
          <Alert severity="error" className={classes.alert}>
            Job [{jobNotFound}] does not exist
          </Alert>
        )}
        <Box display="flex">
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
            sx={{ textTransform: 'capitalize' }}
          >
            {stage} Jobs
          </Typography>
        </Box>
        <Box>
          <div className={classes.root}>
            <Results stagevalue={stage} />
          </div>
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
