import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';

const QuerySingleJobRequest = gql`
  query($requestNumber: String!) {
    SingleJobRequest(request_number: $requestNumber) {
      _id
      title
      request_number
      amount
      status
      created_for
      created_by {
        _id
        company_name
      }
      client {
        _id
        name
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  };
});

const Account = () => {
  const { classes } = useStyles();
  const elementId = useParams();
  const location = useLocation();

  const [stage, setStage] = useState('live');
  const [dataJR, setDataJR] = useState(null);

  const { data: dataJobRequest, loading: loadingJobRequest } = useQuery(
    QuerySingleJobRequest,
    {
      variables: { requestNumber: elementId.id },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    const onCompleted = dataJobRequest => {
      setDataJR(dataJobRequest.SingleJobRequest);
    };
    if (onCompleted) {
      if (onCompleted && !loadingJobRequest) {
        onCompleted(dataJobRequest);
      }
    }
  }, [dataJobRequest, loadingJobRequest]);

  return (
    <Page className={classes.root} title="New Job">
      {!loadingJobRequest && dataJobRequest && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ProfileDetails
                stage={stage}
                dataJobRequest={dataJobRequest.SingleJobRequest}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default Account;
