import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormControl,
  FormGroup,
  Switch
} from '@mui/material';

import { Mutation } from '@apollo/client/react/components';
import { updateUser } from 'src/queries/query';

const ProfileDetails = ({ element, ...rest }) => {
  const [state, setState] = useState({
    status: element.status === 'Active' ? true : false
  });

  const handleChangeSwitch = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <>
      <Mutation mutation={updateUser}>
        {(updateUser, { loading, error }) => (
          <form
            autoComplete="off"
            noValidate
            {...rest}
            onSubmit={e => {
              e.preventDefault();
              updateUser({
                variables: {
                  ref: '',
                  data: {
                    email: e.target.email.value,
                    first_name: e.target.first_name.value,
                    last_name: e.target.last_name.value,
                    status: state.status ? 'Active' : 'Inactive'
                  }
                }
              });
            }}
          >
            <Card>
              <CardHeader
                // subheader="The information can be edited"
                title="Profile"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText="Please specify the name"
                      label="First Name"
                      name="first_name"
                      required
                      variant="outlined"
                      defaultValue={element.first_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText="Please specify the name"
                      label="Last Name"
                      name="last_name"
                      required
                      variant="outlined"
                      defaultValue={element.last_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      required
                      variant="outlined"
                      defaultValue={element.email}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* <FormControlLabel
                    control={<Checkbox name="active" />}
                    label="Active"
                  /> */}
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.status}
                              onChange={handleChangeSwitch}
                              name="status"
                            />
                          }
                          label="Active"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              {/* <Box display="flex" justifyContent="flex-end" p={2}>
                <Button color="primary" variant="contained" type="submit">
                  Save details
                </Button>
              </Box> */}
            </Card>
          </form>
        )}
      </Mutation>

      <Mutation mutation={updateUser}>
        {(updateUser, { loading, error }) => (
          <form
            autoComplete="off"
            noValidate
            {...rest}
            onSubmit={e => {
              e.preventDefault();
              updateUser({
                variables: {
                  ref: '',
                  data: {
                    email: e.target.email.value,
                    first_name: e.target.first_name.value,
                    last_name: e.target.last_name.value,
                    designation: e.target.designation.value,
                    rate: parseFloat(e.target.rate.value),
                    address: e.target.address.value,
                    status: state.status ? 'Active' : 'Inactive'
                  }
                }
              });
            }}
          >
            {/* <Card>
              <CardHeader title="User Rights" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText=""
                      label="Read"
                      name="read"
                      required
                      variant="outlined"
                      defaultValue={element.user_level.read}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText=""
                      label="Create"
                      name="create"
                      required
                      variant="outlined"
                      defaultValue={element.user_level.create}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText=""
                      label="Delete"
                      name="delete"
                      required
                      variant="outlined"
                      defaultValue={element.user_level.delete}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText=""
                      label="Execute"
                      name="execute"
                      required
                      variant="outlined"
                      defaultValue={element.user_level.execute}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      helperText=""
                      label="Update"
                      name="update"
                      required
                      variant="outlined"
                      defaultValue={element.user_level.update}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button color="primary" variant="contained" type="submit">
                  Save Rights
                </Button>
              </Box>
            </Card> */}
          </form>
        )}
      </Mutation>
    </>
  );
};

export default ProfileDetails;
