// import { useEffect } from 'react';
import axios from 'axios';

const SendMail = (props, uploadCallback) => {
  // useEffect(() => {
  const formData = new FormData();
  //  eslint-disable-next-line
  for (const key in props) {
    formData.append(key, props[key]);
  }

  axios
    .post('/sendMail', formData)
    .then(res => {
      if (uploadCallback) uploadCallback(res.data);
    })
    .catch(error => {
      console.error('Error sending mail:', error);
    });
  // }, [props, uploadCallback]);
};

export default SendMail;

/*
function sendMail(props) {
  const [state, setState] = useState({ variant: 'contained' });
  const [loading, setLoading] = useState(false);

  var buttonName = props.buttonName ? props.buttonName : 'Send Mail';

  useEffect(() => {
    setState({
      ...state,
      variant: props.variant ? props.variant : 'contained',
      parent: props.parent ? props.parent : '',
      folderName: props.folderName ? props.folderName : '',
      folderID: props.folderID ? props.folderID : ''
    });
  }, [props]);

  const onChangeHandler = event => {
  };

    const sendMail = (){
        console.log('asdfasdfasf');
    }
  return (
    <Button
      color="primary"
      variant={state.variant}
      component="label"
      fullWidth={state.variant === 'text' ? 'true' : ''}
      disabled={loading}
      onClick={sendMail}
    >
      {loading ?  'Loading...' : buttonName}
    </Button>
  );
}
export default sendMail;
*/
