import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider
} from '@mui/material';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import currencyformatter from 'src/utils/currencyformatter';
import MaterialReactTable from 'material-react-table';
import Page from 'src/components/Page';
import TruncatedText from 'src/components/TruncatedText';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import RatecardsDetails from './RatecardsDetails';
import JobInvoices from './JobInvoices';
import ClientPOs from './ClientPOs';
import CloudPortal from './CloudPortal';
import Toolbar from './Toolbar';

const Jobs = gql`
  query Jobs($container_job_number: String) {
    Jobs(container_job_number: $container_job_number) {
      _id
      title
      url
      job_number
      stage
      is_pass_through
      job_type
      client {
        client_name
      }

      end_date
      allotted_budget
      container_ref
      container_title
      department
    }
  }
`;

const JobsContainers = gql`
  query JobsContainers($job_number: String) {
    JobsContainers(job_number: $job_number) {
      _id
      subscription_key
      title
      job_number
      description
      cloud_portal
      start_date
      end_date
      total_budget
      stage
      pre_to_live_date
      complete_date
      parent_ref
      owner_reference
      owner_name
      attachments
      tags
      ratecard
      ratecard_approved
      client_po
      client {
        client_ref
        client_name
      }
      units {
        _id
        allocated_budget
        jobunit_ref {
          title
          job_type
        }
      }
      invoices {
        _id
      }
      remaining
      invoiced
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    red: {
      color: '#f65858'
    },
    orange: {
      color: 'orange'
    }
  };
});

const currencyFormat = {
  Cell: ({ cell }) => (
    <>
      {cell.getValue()?.toLocaleString?.('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}
    </>
  )
};

const SingleJobView = () => {
  const { classes } = useStyles();
  const elementId = useParams();
  const navigate = useNavigate();

  const [jobstage, setJobstage] = useState('');
  const [newactiveratecard, setNewactiveratecard] = useState('');

  const storedExpand = JSON.parse(localStorage.getItem('expand')) || {};
  const [expanded, setExpanded] = useState(
    storedExpand.SingleJobView === false ? false : true
  );

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const { data: dataJobContainer, loading: loadingJobContainer } = useQuery(
    JobsContainers,
    {
      variables: { job_number: elementId.id },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (!loadingJobContainer && dataJobContainer) {
      if (
        !dataJobContainer.JobsContainers ||
        dataJobContainer.JobsContainers.length === 0
      ) {
        // Navigate to jobs list with query parameter
        navigate(`/masterjobs/live?jobNotFound=${elementId.id}`);
      } else {
        const onCompleted = dataJobContainer => {
          setJobstage(dataJobContainer.JobsContainers[0].stage);
          setNewactiveratecard(dataJobContainer.JobsContainers[0].ratecard);
        };
        if (onCompleted) {
          if (onCompleted && !loadingJobContainer) {
            onCompleted(dataJobContainer);
          }
        }
      }
    }
  }, [dataJobContainer, loadingJobContainer, navigate, elementId.id]);

  const { data: dataJobUnit, loading: loadingJobUnit } = useQuery(Jobs, {
    // variables: { container_ref: elementId.id },
    variables: { container_job_number: elementId.id },
    fetchPolicy: 'no-cache'
  });

  const [dialogRatecard, setDialogRatecard] = useState(false);

  const sendChildToParent = (stage, dialogRatecard) => {
    console.log('from child', stage);
    setJobstage(stage);
    //setDialogRatecard(dialogRatecard);
  };

  const fromRatecardsDetails = (open, activeratecard) => {
    setDialogRatecard(open);
    setNewactiveratecard(activeratecard);
    console.log('from child', activeratecard);
  };

  const fromProfileDetails = activeratecard => {
    setNewactiveratecard(activeratecard);
    console.log('from child', activeratecard);
  };

  const handleExpandClick = () => {
    const newexpand = !expanded;
    const newStoredExpand = JSON.parse(localStorage.getItem('expand')) || {};
    newStoredExpand.SingleJobView = newexpand;
    localStorage.setItem('expand', JSON.stringify(newStoredExpand));
    setExpanded(newexpand);
  };

  if (
    loadingJobContainer ||
    !dataJobContainer ||
    !dataJobContainer.JobsContainers ||
    dataJobContainer.JobsContainers.length === 0
  ) {
    return null; // or a loading spinner
  }

  return (
    <Page className={classes.root} title="Job" elevation={0}>
      {!loadingJobContainer && dataJobContainer && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Profile element={dataJobContainer.JobsContainers} />
            </Grid>
            <Grid item xs={4}>
              <Toolbar
                id={dataJobContainer.JobsContainers[0]._id}
                stage={dataJobContainer.JobsContainers[0].stage}
                sendChildToParent={sendChildToParent}
              />
            </Grid>
            <Grid item xs={12}>
              <ProfileDetails
                element={dataJobContainer.JobsContainers}
                dialogRatecard={dialogRatecard}
                activeratecard={newactiveratecard}
                fromProfileDetails={fromProfileDetails}
                stage={jobstage}
              />
            </Grid>

            {/* {jobstage !== 'newbiz' && (
              <Grid item xs={12}>
                <RatecardsDetails
                  jobref={dataJobContainer.JobsContainers[0]._id}
                  activeratecard={newactiveratecard}
                  clientref={
                    dataJobContainer.JobsContainers[0].client.client_ref
                  }
                  fromRatecardsDetails={fromRatecardsDetails}
                />
              </Grid>
            )} */}

            <Grid item xs={12}>
              <Card elevation={0}>
                <CardHeader
                  action={
                    <>
                      {jobstage === 'live' &&
                      dataJobContainer.JobsContainers[0].ratecard_approved ===
                        true ? (
                        <Button
                          color="primary"
                          variant="contained"
                          component={RouterLink}
                          to={'/masterjob/' + elementId.id + '/add-subjob'}
                          className={classes.addButton}
                        >
                          Add new Sub Job
                        </Button>
                      ) : null}
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </>
                  }
                  title="Sub Jobs"
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Divider />
                  <CardContent>
                    {!loadingJobUnit &&
                      (dataJobUnit.Jobs.length > 0 ? (
                        <MaterialReactTable
                          columns={[
                            {
                              header: 'Title', // title of column
                              accessorKey: 'title', // field name in query
                              Cell: ({ cell }) => (
                                <TruncatedText
                                  text={cell.getValue()}
                                  maxLength={150}
                                />
                              ),
                              size: 150
                            },
                            {
                              header: 'Job #',
                              accessorKey: 'job_number',
                              size: 50
                            },
                            {
                              header: 'TX Date',
                              accessorKey: 'end_date',
                              size: 50,
                              sortingFn: 'datetime', // sort based on data type -- https://www.material-react-table.com/docs/guides/sorting#sorting-feature-guide
                              Cell: ({ cell }) => (
                                <>
                                  {cell.getValue()
                                    ? moment
                                        .unix(cell.getValue() / 1000)
                                        .format('DD/MM/YYYY')
                                    : '-'}
                                </>
                              )
                            },

                            {
                              header: 'Is Pass Through',
                              accessorKey: 'is_pass_through',
                              size: 50,
                              Cell: ({ cell }) => (
                                <>{cell.getValue() ? 'Yes' : 'No'}</>
                              )
                            },
                            {
                              header: 'Stage',
                              accessorKey: 'stage',
                              size: 50,
                              //custom conditional format and styling
                              Cell: ({ cell }) => (
                                <Box
                                  sx={theme => ({
                                    backgroundColor:
                                      jobstage === 'live' &&
                                      cell.getValue() === 'newbiz'
                                        ? theme.palette.stage.complete
                                        : cell.getValue() === 'newbiz'
                                        ? theme.palette.stage.newbiz
                                        : cell.getValue() === 'live'
                                        ? theme.palette.stage.live
                                        : cell.getValue() === 'complete'
                                        ? theme.palette.stage.complete
                                        : cell.getValue() === 'cancelled'
                                        ? theme.palette.stage.cancelled
                                        : theme.palette.stage.removed,
                                    borderRadius: '0.3rem',
                                    color: '#fff',
                                    width: 'fit-content',
                                    p: '0.2rem 0.8rem',
                                    textTransform: 'capitalize'
                                  })}
                                >
                                  {/* <Link href="job">{cell.getValue()}</Link> */}
                                  {jobstage === 'live' &&
                                  cell.getValue() === 'newbiz'
                                    ? 'Complete'
                                    : cell.getValue()}
                                </Box>
                              )
                            },
                            {
                              header: 'Allocated Budget',
                              accessorKey: 'allotted_budget',
                              ...currencyFormat,
                              size: 50,
                              muiTableBodyCellProps: {
                                align: 'right'
                              },
                              muiTableHeadCellProps: {
                                align: 'right'
                              },
                              Footer: () => (
                                <Box
                                  style={{
                                    textAlign: 'right',
                                    marginLeft: '-100%', // This will make it appear to span both columns
                                    width: '200%' // This ensures the content spans both columns
                                  }}
                                >
                                  Remaining Budget:{'  '}
                                  {currencyformatter.format(
                                    dataJobContainer.JobsContainers[0].remaining
                                  )}
                                </Box>
                              )
                            }
                          ]}
                          // data={dataJobUnit.Jobs}
                          data={dataJobUnit.Jobs.filter(
                            job => job.job_type !== 'Internal-Pitch'
                          )}
                          // optional filters
                          enableColumnActions={false}
                          enableColumnFilters={false}
                          enablePagination={false}
                          enableBottomToolbar={false}
                          enableFullScreenToggle={false}
                          enableHiding={false}
                          enableDensityToggle={false}
                          // --- optional filters
                          initialState={{
                            density: 'compact', // values: 'comfortable' | 'compact' | 'spacious'
                            sorting: [
                              { id: 'end_date', desc: false },
                              { id: 'stage', desc: false } // optional for multiple sorting
                            ]
                          }}
                          muiTableBodyRowProps={({ row }) => ({
                            onClick: event => {
                              window.location.href = `/subjob/${row.original.job_number}`;
                            },
                            sx: {
                              cursor: 'pointer'
                            }
                          })}
                        />
                      ) : jobstage === 'live' ? (
                        <Button
                          color="primary"
                          variant="contained"
                          component={RouterLink}
                          to={'/masterjob/' + elementId.id + '/add-subjob'}
                        >
                          Add new Sub Job
                        </Button>
                      ) : null)}
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>

            {jobstage !== 'newbiz' && (
              <Grid item xs={12}>
                <JobInvoices
                  job={dataJobContainer.JobsContainers[0]}
                  // jobref={dataJobContainer.JobsContainers[0]._id}
                  // invoices={dataJobContainer.JobsContainers[0].invoices}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ClientPOs
                job={dataJobContainer.JobsContainers[0]}
                // jobref={dataJobContainer.JobsContainers[0]._id}
                // invoices={dataJobContainer.JobsContainers[0].invoices}
              />
            </Grid>

            <Grid item xs={12}>
              <CloudPortal job={dataJobContainer.JobsContainers[0]} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default SingleJobView;
