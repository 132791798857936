import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const useStyles = makeStyles()(() => {
  return {
    root: {
      backgroundColor: 'transparent',
      paddingBottom: 0
    },
    rootCard: {
      paddingBottom: '10px!important'
    }
  };
});

const Profile = ({ className, element, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <CardContent className={cx(classes.rootCard, className)}>
        <Box alignItems="start" display="flex" flexDirection="column">
          <Typography color="textPrimary" gutterBottom variant="h3">
            {element[0].name}
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/clients">
              Clients
            </Link>
            <Typography aria-current="page" color="textPrimary">
              {element[0].name}
            </Typography>
          </Breadcrumbs>
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
