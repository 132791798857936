import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import PropTypes from 'prop-types';

import { Box, Card, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const JobRequests = gql`
  query JobRequests {
    JobRequests {
      _id
      request_number
      title
      amount
      status
      created_by {
        _id
        company_name
      }
      job_request_from {
        client_name
        spo_number
        spo_amount
        sub_job_number
        sub_job_title
      }
    }
  }
`;

const useStyles = makeStyles()(theme => {
  return {
    appbar: {
      borderBottom: 'solid 1px #e9e9e9',
      padding: '0 15px'
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      marginRight: theme.spacing(2)
    }
  };
});

const Results = ({ className, ...rest }) => {
  const [datatable, setDatatable] = useState([]);
  const { classes, cx } = useStyles();
  // get Suppliers

  // const { data: dataJobRequests, loading, error } = useQuery(JobRequests);
  const {
    loading: loadingqry,
    data: dataqry,
    error: errorqry,
    refetch
  } = useQuery(JobRequests);
  // callback for jobs query
  useEffect(() => {
    const onCompleted = dataqry => {
      setDatatable(dataqry.JobRequests);
      console.log(dataqry);
    };
    const onError = errorqry => {
      console.log('query error', errorqry);
    };

    if (onCompleted || onError) {
      if (onCompleted && !loadingqry && !errorqry) {
        onCompleted(dataqry);
      } else if (onError && !loadingqry && errorqry) {
        onError(errorqry);
      }
    }
  }, [loadingqry, dataqry, errorqry]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Title'
      },
      {
        accessorKey: 'request_number',
        header: 'Ref'
      },
      {
        accessorKey: 'amount',
        header: 'Amount'
      },
      {
        accessorKey: 'created_by.company_name',
        header: 'Created By'
      },
      {
        accessorKey: 'job_request_from.spo_number',
        header: 'SPo'
      },
      {
        accessorKey: 'job_request_from.spo_amount',
        header: 'SPo Amount'
      },
      {
        accessorKey: 'job_request_from.client_name',
        header: 'Ultimate Client'
      },
      {
        accessorKey: 'job_request_from.sub_job_number',
        header: 'Ultimate Job no'
      },
      {
        accessorKey: 'job_request_from.sub_job_title',
        header: 'Ultimate Job title'
      },
      {
        accessorKey: null,
        header: 'Operation',
        Cell: ({ cell, row }) => (
          <Box alignItems="center" display="flex">
            <Typography color="textPrimary" variant="body1">
              <Link to={`/jobrequest/add/${row.original.request_number}`}>
                Convert to Job
              </Link>
            </Typography>
          </Box>
        )
      }
    ],
    []
  );

  return (
    <Card className={cx(classes.root, className)} {...rest}>
      <MaterialReactTable
        columns={columns}
        data={datatable}
        enableColumnFilters
        enableColumnActions={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding
        initialState={{
          density: 'compact',
          pagination: { pageSize: 25 },
          columnVisibility: {
            'job_request_from.spo_number': false,
            'job_request_from.spo_amount': false,
            'job_request_from.client_name': false,
            'job_request_from.sub_job_number': false,
            'job_request_from.sub_job_title': false
          }
        }}
        enablePagination
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
