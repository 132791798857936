import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';

import { useMutation } from '@apollo/client';
import { addRole, Department } from 'src/queries/query';

const useStyles = makeStyles()(() => {
  return {
    cardAction: {
      alignSelf: 'center'
    },
    date: {
      width: '100%'
    }
  };
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddRole = ({ className, id, onRoleAdded, ...rest }) => {
  const { classes } = useStyles();

  const [completed, setCompleted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [askareyousure, setAskareyousure] = useState(false);
  const timer = React.useRef();
  const timer2 = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [
    add_Role,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(addRole, {
    onCompleted(data) {
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setCompleted(false);
        setAskareyousure(false);

        // Call the callback if provided
        if (onRoleAdded) {
          onRoleAdded();
        }
      }, 2500);
    },
    onError: err => {
      setError(err);
      console.log(err);
      setOpen(true);
      setIsloading(true);
      timer.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(true);
      }, 1000);

      timer2.current = window.setTimeout(() => {
        setIsloading(false);
        setIserror(false);
        setAskareyousure(false);
      }, 2500);
    },
    // Update the cache to immediately include the new role
    update(cache, { data: { addRole } }) {
      try {
        // Read the current query from the cache
        const existingData = cache.readQuery({
          query: Department,
          variables: { id }
        });

        if (existingData && existingData.Department) {
          // Create a new department object with the added role
          const updatedDepartment = {
            ...existingData.Department,
            roles: [
              ...existingData.Department.roles,
              {
                _id: addRole._id || `temp-id-${Date.now()}`, // Use returned ID or create temporary one
                title:
                  addRole.title ||
                  document.querySelector('input[name="title"]').value,
                __typename: 'Role'
              }
            ]
          };

          // Write back the updated data to the cache
          cache.writeQuery({
            query: Department,
            variables: { id },
            data: {
              Department: updatedDepartment
            }
          });
        }
      } catch (error) {
        console.error('Error updating cache:', error);
      }
    },
    // Fallback refetch
    refetchQueries: [
      {
        query: Department,
        variables: { id }
      }
    ]
  });

  const ask = e => {
    e.preventDefault();
    setAskareyousure(true);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {mutationError ? mutationError.toString() : null}
        </Alert>
      </Snackbar>

      <form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          setIsloading(true);
          add_Role({
            variables: {
              ref: id,
              data: {
                title: e.target.title.value
              }
            }
          });
          // Clear the form field after submission
          e.target.reset();
        }}
      >
        <Card>
          <CardHeader title="Add New Role" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  required
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {askareyousure ? (
              <button
                type="submit"
                id="button"
                className={`btn-animate ${isloading ? 'onclic' : null} ${
                  !isloading && completed ? 'validate' : null
                } ${iserror ? 'novalidate' : null}`}
              ></button>
            ) : (
              <button
                onClick={ask}
                id="ask"
                className={`btn-animate ask`}
              ></button>
            )}
          </Box>
        </Card>
      </form>
    </>
  );
};

export default AddRole;
